import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { BasicCard } from "../Cards/BasicCard";
import { PasswordResetSuccessLanguageData } from "../../constants/Language/Language";
import { routeCodes } from "../../constants/routes";
import { useHistory } from "react-router";
import "firebase/auth";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  card: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "300",
    },
    [theme.breakpoints.up("md")]: {
      width: "500px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "500px",
    },
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(0.5),
  },
  textField: {
    margin: "10px 0",
    "& .MuiFormLabel-root.Mui-Focused": { color: "black" },
  },
  box: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const PasswordResetSuccess = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const text = PasswordResetSuccessLanguageData();

  return (
    <BasicCard title={text.title} subText={text.subtitle}>
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        className={classes.card}
      >
        <Grid item xs={12}>
          <Box className={classes.box}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(routeCodes.LOGIN.route);
              }}
            >
              {text.buttonLogin}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </BasicCard>
  );
};
