export const licensePageTitle = "License";
export const certificateIDValidationPageTitle = "CertificateID Validation";
export const loginPageTitle = "Login";
export const loginRegistrationPageTitle = "Login/Registration";
export const registrationPageTitle = "Registration";
export const certificatePageTitle = "Ihr Zertifikat";
export const certificateDisplayPageTitle = "Ihr Zertifikat";
export const forgotPasswordTitle = "Forgot Password";
export const registrationPendingTitle = "Registration Pending";
export const testPrefaceTitle = "Test Preface";
export const myAccountViewTitle = "My Account";
export const videoTutorialTitle = "Video Tutorial";
export const topicSelectionTitle = "Topic Selection";
export const emailVerificationTitle = "Email Verification";
export const passwordResetTitle = "PasswordReset";
export const customEmailHandler = "Custom Email Handler";
