import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { FooterButton } from "./FooterButtons";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.secondary.main,
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2),
    justifyContent: "space-between",
  },
  left: { display: "flex", flexDirection: "column" },
  center: {
    display: "flex",
    flexDirection: "row",
    height: "56px",
  },
  right: {
    display: "flex",
    flexDirection: "row",
    margin: "0 15px",
  },
  footerText: {
    position: "static",
    color: theme.palette.common.white,
    margin: "10px 0px 0px 5px",
  },
}));

export const Footer = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <Typography className={classes.footerText} variant="body2">
          &#9400; Copyright 2019 - {new Date().getFullYear()} by schutz.digital
          GmbH. Alle Rechte vorbehalten.
        </Typography>
      </div>
      <div className={classes.right}>
        <FooterButton />
      </div>
    </div>
  );
};
