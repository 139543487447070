import { getDate } from "./general";
import { db } from "../firebase/firebase";

type EmailTemplateType = {
  to: string;
  message: {
    subject: string;
    html: string;
  };
};

const generateDeleteMyAccountEmail = (uuid: string): EmailTemplateType => ({
  to: "service@schutz.digital",
  message: {
    subject: `[DSGVO] account deletion requested by ${uuid}`,
    html: `<html>
                <head></head>
                <body>Hallo Admin,
                    <br><br>The user ${uuid} has sent an authenticated request for account deletion at ${getDate()}.
                    <br><br>
                    Dein schutz.digital Team
                    <br><br>
                    schutz.digital gmbh<br>AG Siegburg HRB 14991 UID DE281653760<br>Reichenberger Str. 12, 53604 Bad Honnef, Deutschland<br>info@schutz.digital
                </body>
              </html>`,
  },
});

export const sendDeleteMyAccountEmail = async (
  userId: string
): Promise<void> => {
  const emailObject = generateDeleteMyAccountEmail(userId);

  return await db.collection("triggered_emails").doc(userId).set(emailObject);
};
