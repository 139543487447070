import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { ConsentBannerLanguageData } from "../../constants/Language/Language";
import CookieConsent from "react-cookie-consent";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { consentBannerToggle } from "../../recoil/appState";
import { useRecoilState } from "recoil";
import { DialogBanner } from "./DialogBanner";
import { Markup } from "interweave";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    margin: 10,
    marginTop: "1em",
  },
  consentBanner: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    position: "relative",
    left: 0,
    bottom: "0%",
    width: "100%",
    zIndex: 10,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "8em",
    },
    [theme.breakpoints.up("md")]: {
      height: "8em",
    },
    [theme.breakpoints.up("lg")]: {
      height: "8em",
    },
  },
  consentBannerContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  consentBannerText: {
    margin: 10,
    marginTop: "1em",
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
}));

export interface ConsentBannerProps {
  link: boolean;
}

export const ConsentBanner = ({ link }: ConsentBannerProps): JSX.Element => {
  const classes = useStyles();
  const textConsent = ConsentBannerLanguageData();
  const [TOSToggle, setTOSToggle] = useRecoilState(consentBannerToggle);

  return (
    <CookieConsent
      disableStyles={true}
      containerClasses={classes.consentBanner}
      buttonText={textConsent.agree}
      buttonId={"cookieButton"}
      buttonStyle={{ display: "none" }}
    >
      <DialogBanner
        title={textConsent.consentDialogTitle}
        bodyText={textConsent.consentDialogBody}
        open={TOSToggle}
      />
      <Box className={classes.consentBannerContent}>
        <Typography className={classes.consentBannerText} variant="body1">
          <Markup content={textConsent.bannerText} />
          {link ? (
            <Link
              href={textConsent.httpURL}
              target="_blank"
              rel="noreferrer"
              underline="hover"
              color="inherit"
            >
              {textConsent.urlDisplayText}
            </Link>
          ) : null}
        </Typography>
        <Box>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={() => {
              setTOSToggle(true);
            }}
          >
            {textConsent.consentDialogButton}
          </Button>

          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={() => {
              const cookieButton = document.getElementById("cookieButton");
              if (cookieButton !== null) {
                cookieButton.click();
              }
            }}
          >
            {textConsent.agree}
          </Button>
        </Box>
      </Box>
    </CookieConsent>
  );
};
