import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { theme } from "../../constants/theme";
import { BasicCard } from "../Cards/BasicCard";
import { TopicSelector } from "./TopicSelector";
import background from "../../assets/topicBackground.jpg";
import { isEmptyObject } from "../../utils/general";
import { auth, firebase } from "../../firebase/firebase";
import { firebaseProducts, firebaseUsers } from "../../recoil/dataHooks";
import {
  fetchProductInformation,
  fetchUserInformation,
} from "../../utils/fetchData";
import { offlineDownloadPopup } from "../../recoil/appState";
import { TopicLanguageData } from "../../constants/Language/Language";
import { OfflineSettingsDialog } from "../VideoTutorial/OfflineSettings/OfflineSettingsDialog";

const useStyles = makeStyles({
  card: {
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1),

    [theme.breakpoints.down("sm")]: {
      width: "300",
    },
    [theme.breakpoints.up("md")]: {
      width: "800px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "800px",
    },
  },
  courseContainer: {
    color: theme.palette.common.black,

    "& > *": {
      fontWeight: 600,
    },
  },
  heading: {
    fontSize: 30,
    margin: 10,
  },
  downloadContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    fontWeight: 600,
    paddingTop: "2rem",

    "& .MuiTypography-root": {
      fontSize: 24,
      fontWeight: 600,
    },
  },
  spotlightContainer: {
    backgroundImage: `url(${background})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "100%",
    marginTop: 24,

    [theme.breakpoints.up("md")]: {
      height: 380,
    },
    [theme.breakpoints.up("lg")]: {
      height: 520,
    },
  },
});

export const TopicSelectionCore = (): JSX.Element => {
  const classes = useStyles();
  const text = TopicLanguageData();

  const userIn = auth.currentUser;
  const [user, setUser] = useState<firebase.User>();
  const [userData, setUserData] = useRecoilState(firebaseUsers);
  const [product, setProduct] = useRecoilState(firebaseProducts);
  const [dialogState, setDialogState] = useRecoilState(offlineDownloadPopup);

  const fetchProduct = async (productId: string) => {
    const product = await fetchProductInformation(productId);

    if (isEmptyObject(product)) {
      console.warn("Could not load product");
    } else {
      setProduct(product);
    }
  };

  const fetchUser = async (userId: string) => {
    const userInfo = await fetchUserInformation(userId);

    if (isEmptyObject(userInfo)) {
      console.warn("Could not load user info");
    } else {
      setUserData(userInfo);
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        fetchUser(user.uid);
      }
    });

    return () => unsubscribe();
  }, [userIn]);

  useEffect(() => {
    if (user && userData) {
      fetchProduct(
        userData.currentLicense?.productId ?? userData.currentCourse
      );
    }
  }, [userData]);

  const handleDialogClose = () => {
    setDialogState(false);
  };

  return (
    <BasicCard center>
      <OfflineSettingsDialog
        open={dialogState}
        handleClose={handleDialogClose}
      />
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        className={classes.card}
      >
        <div className={classes.courseContainer}>
          <Typography className={classes.heading} variant="h4">
            {product.title}
          </Typography>
          <Typography variant="h5">{product.description}</Typography>

          <Box className={classes.downloadContainer}>
            <Typography variant="h6">{text.header}</Typography>
            <Button
              color="primary"
              variant="contained"
              endIcon={<GetAppRoundedIcon />}
              onClick={() => setDialogState(true)}
              disabled={!product?.topics?.length}
            >
              {text.download}
            </Button>
          </Box>
        </div>

        <div className={classes.spotlightContainer}>
          <TopicSelector />
        </div>
      </Grid>
    </BasicCard>
  );
};
