import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import SDLogo from "../../assets/sd_LogoType1.svg";
import SDLogoCropped from "../../assets/sd_LogoType1_cropped.png";
import ADBWLogo from "../../assets/logo-adbw.svg";
import BOOMERSLogo from "../../assets/boomers_logo.png";
import ADBWLogoBig from "../../assets/adbw_new5.png";
import { routeCodes } from "../../constants/routes";
import { ControlSelector } from "./Controls/ControlSelector";
import { usePathLevel0 } from "../../utils/pathnameUtils";
import { auth } from "../../firebase/firebase";
import { videoPlayingState } from "../../recoil/appState";
import { hasExpiredLicense, isEmptyObject } from "../../utils/general";
import { fetchUserInformation } from "../../utils/fetchData";
import { BRAND_ADBW, BRAND_BOOMERS } from "../../constants/appConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: theme.palette.common.white,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    "&:hover": {
      background: "none",
    },
  },
  title: {
    color: theme.palette.common.black,
    flexGrow: 1,
  },
  logo: {
    height: "50px",
  },
  logoFullheight: {
    height: "auto",
  },
  button: {
    textTransform: "none",
  },
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    padding: theme.spacing(1),
    justifyContent: "space-between",
  },
  left: { display: "flex", flexDirection: "column" },
  center: {
    display: "flex",
    flexDirection: "row",
    height: "56px",
  },
  right: {
    display: "flex",
    flexDirection: "row",
  },
}));

export interface TopBarProps {
  title?: string;
}

const getWindowDimensions = () => {
  const { innerWidth: width } = window;
  return {
    width,
  };
};

export const TopBar = ({ title }: TopBarProps): JSX.Element => {
  const classes = useStyles();
  const [logo, setLogo] = useState(SDLogo);
  const [croppedLogo, setCroppedLogo] = useState(SDLogoCropped);
  const [logoClass, setLogoClass] = useState(classes.logo);
  let brandLogo = SDLogo;
  let brandLogoCropped = SDLogoCropped;
  const history = useHistory();
  const setVideoPlaying = useSetRecoilState(videoPlayingState);
  const p0 = usePathLevel0();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  // Recommend using cookies | WernerWildenboer 27/10/2021
  //https://stackoverflow.com/questions/39243578/how-to-show-different-page-if-user-is-logged-in-via-firebase
  const handleLogoClick = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userData = await fetchUserInformation(user.uid);

        if (isEmptyObject(userData)) {
          console.warn("Could not load user");
        } else {
          if (!isEmptyObject(userData)) {
            const expriredLicense = await hasExpiredLicense(
              userData.currentLicense?.code,
              userData.currentLicense?.productId ?? userData.currentCourse
            );

            //set brand logo
            const licenseBrand = userData.currentLicense.brand;
            if (licenseBrand && licenseBrand === BRAND_ADBW) {
              brandLogo = ADBWLogoBig;
              brandLogoCropped = ADBWLogo;
              setLogoClass(classes.logoFullheight);
            } else if (licenseBrand && licenseBrand === BRAND_BOOMERS) {
              brandLogo = BOOMERSLogo;
              brandLogoCropped = BOOMERSLogo;
              //setLogoClass(classes.logoFullheight);
            } else {
              brandLogo = SDLogo;
              brandLogoCropped = SDLogoCropped;
              setLogoClass(classes.logo);
            }
            setLogo(brandLogo);
            setCroppedLogo(brandLogoCropped);

            if (expriredLicense) {
              history.push(routeCodes.MYACCOUNT.route);
            } else {
              setVideoPlaying(false);
              history.push(routeCodes.VIDEOTUTORIAL.route);
            }
          } else {
            history.push(routeCodes.LICENSE.route);
          }
        }
      } else {
        if (
          history.location &&
          history.location.pathname &&
          history.location.pathname === "/customemailhandler"
        ) {
          // eslint-disable-next-line no-console
          console.log("/customemailhandler");
        } else {
          history.push(routeCodes.LOGINREGISTRATION.route);
        }
      }
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    handleLogoClick();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            aria-label="logo"
            disableRipple
            onClick={() => handleLogoClick()}
          >
            <img
              src={windowDimensions["width"] > 550 ? logo : croppedLogo}
              alt="sd logo"
              className={
                windowDimensions["width"] > 550 ? logoClass : classes.logo
              }
            />
          </IconButton>
          <div className={classes.container}>
            <div className={classes.left}>
              {title ? (
                <Typography variant="h6" className={classes.title}>
                  {title}
                </Typography>
              ) : null}
            </div>
            <div className={classes.right}>{ControlSelector(p0)}</div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};
