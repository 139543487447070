import React from "react";
import { PageNameWrapper } from "../../components/Cards/CenteredPage/PageNameWrapper";
import { RegistrationCore } from "../../components/Registration/RegistrationCore";
import { registrationPageTitle } from "../commonViewConstants";

export const RegistrationView = (): JSX.Element => {
  return (
    <PageNameWrapper title={registrationPageTitle}>
      <RegistrationCore />
    </PageNameWrapper>
  );
};
