import React from "react";
import { PageNameWrapper } from "../../components/Cards/CenteredPage/PageNameWrapper";
import { CourseTest } from "../../components/Test/CourseTest/CourseTest";

export const TestView = (): JSX.Element => {
  return (
    <PageNameWrapper title={"Test Complete"}>
      <CourseTest />
    </PageNameWrapper>
  );
};
