import { StringDecoder } from "string_decoder";

interface buildErrorProps {
  code: StringDecoder;
  message: number;
}

export const buildError = ({ code, message }: buildErrorProps): string => {
  const errorMessage = code + " : " + message;
  return errorMessage;
};
