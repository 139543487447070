import React, { KeyboardEvent } from "react";
import { BasicCard } from "../Cards/BasicCard";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router";
import { routeCodes } from "../../constants/routes";
import { RegistrationLanguageData } from "../../constants/Language/Language";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useRecoilValue } from "recoil";
import { auth } from "../../firebase/firebase";
import { userEmail } from "../../recoil/appState";
import { SendVerificationEmail } from "../../firebase/User/userCore";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import FormControl from "@mui/material/FormControl";
import format from "string-template";

const useStyles = makeStyles(() => ({
  button: {
    textTransform: "none",
  },
  textLine: {
    display: "flex",
  },
  buttonCss: {
    paddingTop: 20,
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const RegistrationPending = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const text = RegistrationLanguageData();
  const email = useRecoilValue(userEmail);
  const user = auth.currentUser;
  const [open, setOpen] = React.useState(false);
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleKeyboardEvent = (event: KeyboardEvent<HTMLImageElement>) => {
    if (event.key === "Enter") {
      history.push(routeCodes.LOGIN.route);
    }
  };

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {text.emailSent}
        </Alert>
      </Snackbar>

      <BasicCard title={text.registrationPendingTitle}>
        <FormControl onKeyPress={handleKeyboardEvent}>
          <div className={classes.textLine}>
            <Typography variant={"body1"}>
              {format(text.activateEmail, {
                email: user !== null ? user.email : email,
              })}
            </Typography>
          </div>
          <Typography variant={"body1"}>{text.postActivateEmail}</Typography>
          <Typography variant={"body1"}>
            {text.emailNotSent}
            <Link
              component="button"
              variant="body1"
              onClick={() => {
                SendVerificationEmail();
                setOpen(true);
              }}
            >
              {text.newEmail}
            </Link>
          </Typography>
          <div className={classes.buttonCss}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(routeCodes.LOGIN.route);
              }}
            >
              {text.buttonText}
            </Button>
          </div>
        </FormControl>
      </BasicCard>
    </React.Fragment>
  );
};
