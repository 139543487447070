import React from "react";
import { PageNameWrapper } from "../../components/Cards/CenteredPage/PageNameWrapper";
import { RegistrationPending } from "../../components/Registration/RegistrationPending";
import { registrationPendingTitle } from "../commonViewConstants";

export const RegistrationPendingView = (): JSX.Element => {
  return (
    <PageNameWrapper title={registrationPendingTitle}>
      <RegistrationPending />
    </PageNameWrapper>
  );
};
