import React, { Fragment } from "react";
import { LessonListVideoElements } from "./LessonListVideoElements";

export const LessonListCore = (): JSX.Element => {
  return (
    <Fragment>
      <LessonListVideoElements />
    </Fragment>
  );
};
