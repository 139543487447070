import React, { KeyboardEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import FormControl from "@mui/material/FormControl";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  registerContractTermsChecked,
  registerPasswordAssigned,
  registerPasswordRepeat,
  registerPrivacyPolicyChecked,
} from "../../../recoil/dataHooks";
import { auth } from "../../../firebase/firebase";
import { useHistory } from "react-router";
import { routeCodes } from "../../../constants/routes";
import {
  registrationDynamicError,
  registrationErrorMessage,
  registrationErrorToggle,
  userEmail,
} from "../../../recoil/appState";
import { SendVerificationEmail } from "../../../firebase/User/userCore";
import { RegistrationButtonInputsLanguageData } from "../../../constants/Language/Language";
import { buildError } from "../../../utils/error";

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    display: "flex",
    flexDirection: "row",
    paddingTop: theme.spacing(1),
  },
  button: {
    textTransform: "none",
    marginRight: theme.spacing(0.5),
  },
  form: {
    display: "flex",
    flexDirection: "row",
  },
}));

export const RegistrationButtonInputs = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const setErrorMessage = useSetRecoilState(registrationErrorMessage);
  const [errorToggle, setErrorToggle] = useRecoilState(registrationErrorToggle);
  const setDynamicError = useSetRecoilState(registrationDynamicError);
  const email = useRecoilValue(userEmail);
  const [passwordAssigned, setPasswordAssigned] = useRecoilState(
    registerPasswordAssigned
  );
  const [passwordRepeat, setPasswordRepeat] = useRecoilState(
    registerPasswordRepeat
  );
  const privacyPolicyChecked = useRecoilValue(registerPrivacyPolicyChecked);
  const contractTermsChecked = useRecoilValue(registerContractTermsChecked);
  const text = RegistrationButtonInputsLanguageData();

  const signUpWithEmailAndPassword = (
    email: string,
    passwordAssigned: string,
    passwordRepeat: string
  ): void => {
    setErrorToggle(false);

    if (passwordAssigned !== passwordRepeat) {
      setErrorMessage("passwordMismatch");
      setErrorToggle(true);
      return;
    }

    if (passwordAssigned.length < 8) {
      setErrorMessage("passwordTooShort");
      setErrorToggle(true);
      return;
    }

    if (!passwordAssigned.match(/[a-z]/)) {
      setErrorMessage("passwordNoLower");
      setErrorToggle(true);
      return;
    }

    if (!passwordAssigned.match(/[A-Z]/)) {
      setErrorMessage("passwordNoUpper");
      setErrorToggle(true);
      return;
    }

    if (!/\d/.test(passwordAssigned)) {
      setErrorMessage("passwordNoNumber");
      setErrorToggle(true);
      return;
    }

    const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if (!format.test(passwordAssigned)) {
      setErrorMessage("passwordNoSpecial");
      setErrorToggle(true);
      return;
    }

    if (typeof email !== "undefined") {
      const pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

      if (!pattern.test(email)) {
        setErrorMessage("nonValidEmail");
        setErrorToggle(true);
        return;
      }
    }
    if (privacyPolicyChecked === false) {
      setErrorMessage("policyUnchecked");
      setErrorToggle(true);
      return;
    }
    if (contractTermsChecked === false) {
      setErrorMessage("termsUnchecked");
      setErrorToggle(true);
      return;
    }
    if (!errorToggle) {
      auth
        .createUserWithEmailAndPassword(email, passwordAssigned)
        .then((result) => {
          //TODO : Set up pop up | WernerWildenboer 02/09/2021

          if (result["user"]) {
            SendVerificationEmail();
            setPasswordAssigned("");
            setPasswordRepeat("");
            auth.signOut();
            setTimeout(
              () => history.push(routeCodes.REGISTRATIONPENDING.route),
              350
            );
          }
        })
        .catch((error) => {
          if (error.code.includes("auth/weak-password")) {
            setErrorMessage("nonValidPassword");
            setErrorToggle(true);
          } else if (error.code.includes("auth/email-already-in-use")) {
            setErrorMessage("emailExists");
            setErrorToggle(true);
          } else {
            const errorCode = error.code;
            const errorMessage = error.message;
            setDynamicError(
              buildError({ code: errorCode, message: errorMessage })
            );
            setErrorMessage("dynamicError");
            setErrorToggle(true);
          }
        });
    }
  };

  const handleKeyboardEvent = (e: KeyboardEvent<HTMLImageElement>) => {
    if (e.key === "Enter") {
      signUpWithEmailAndPassword(email, passwordAssigned, passwordRepeat);
    }
  };

  return (
    <Box className={classes.buttonRow}>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => {
          history.push(routeCodes.LOGINREGISTRATION.route);
        }}
      >
        {text.backToLogin}
      </Button>
      <FormControl onKeyPress={handleKeyboardEvent} className={classes.form}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => {
            signUpWithEmailAndPassword(email, passwordAssigned, passwordRepeat);
          }}
        >
          {text.activationEmail}
        </Button>
      </FormControl>
    </Box>
  );
};
