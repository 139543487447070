import React from "react";
import { PageNameWrapper } from "../../components/Cards/CenteredPage/PageNameWrapper";
import { PasswordResetCard } from "../../components/PasswordReset/PasswordResetCard";
import { passwordResetTitle } from "../commonViewConstants";

export const PasswordResetView = (): JSX.Element => {
  return (
    <PageNameWrapper title={passwordResetTitle}>
      <PasswordResetCard />
    </PageNameWrapper>
  );
};
