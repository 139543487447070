import { db, firebase } from "../firebase/firebase";
import {
  certificationIDs,
  LicensedTraining,
  Products,
  TrainingLicenses,
  Users,
} from "../firebase/types";
import { isEmptyObject } from "./general";

export const fetchTrainingLicenses = async (
  licenseCode: string
): Promise<TrainingLicenses> => {
  const trainingLicenseRef = db.collection("trainingLicenses").doc(licenseCode);
  const trainingLicenses = await trainingLicenseRef.get();

  if (trainingLicenses.exists) {
    if (trainingLicenses.data()) {
      return trainingLicenses.data() as TrainingLicenses;
    }
  }

  return {} as TrainingLicenses;
};

export const fetchUserLicenses = async (
  userId: string
): Promise<TrainingLicenses[]> => {
  const trainingLicenseRef = db.collection("trainingLicenses");
  const query = trainingLicenseRef.where("assignedUser", "==", userId);
  const bulkQuery = trainingLicenseRef.where(
    "assignedUsers",
    "array-contains",
    userId
  );

  const trainingLicenses = await Promise.all([query.get(), bulkQuery.get()]);

  const trainingSnapshot: any = [];
  trainingLicenses.forEach((trainingLicense: any) => {
    if (!trainingLicense.empty) {
      trainingSnapshot.push(...trainingLicense.docs);
    }
  });

  const licenses: TrainingLicenses[] = [];
  trainingSnapshot.forEach((license: any) => {
    licenses.push({ licenseId: license.id, ...license.data() });
  });

  if (isEmptyObject(licenses)) {
    return [] as TrainingLicenses[];
  }

  return licenses as TrainingLicenses[];
};

export const isSameLicenseCode = async (
  userId: string,
  userData: Users,
  licenseCode: string
): Promise<boolean> => {
  if (userData.currentLicense?.code === licenseCode) {
    return true;
  }

  const userLicense = await fetchUserLicenses(userId);

  if (isEmptyObject(userLicense)) {
    return false;
  }

  return userLicense.some(
    (license: TrainingLicenses) => license.licenseId === licenseCode
  );
};

export const fetchProductInformation = async (
  productId: string
): Promise<Products> => {
  const productRef = db.collection("products").doc(productId);
  const product = await productRef.get();

  if (product.exists) {
    if (product.data()) {
      return product.data() as Products;
    }
  }

  return {} as Products;
};

export const fetchProductsRef = async (): Promise<
  firebase.firestore.QuerySnapshot<Products>
> => {
  return db
    .collection("products")
    .get() as unknown as firebase.firestore.QuerySnapshot<Products>;
};

export const fetchUserInformation = async (userId: string): Promise<Users> => {
  const userRef = db.collection("users").doc(userId);
  const users = await userRef.get();

  if (users.exists) {
    if (users.data()) {
      return users.data() as Users;
    }
  }

  return {} as Users;
};

export const fetchUserLicensedTraining = async (
  userId: string
): Promise<firebase.firestore.QuerySnapshot<LicensedTraining>> => {
  return db
    .collection("users")
    .doc(userId)
    .collection("licensedTraining")
    .get() as unknown as firebase.firestore.QuerySnapshot<LicensedTraining>;
};

// SD0-258 - once everything has been migrated, courseId will be licenseCode.
export const fetchUserTrainingInformation = async (
  courseId: string,
  userId: string
): Promise<LicensedTraining> => {
  const userRef = db
    .collection("users")
    .doc(userId)
    .collection("licensedTraining")
    .doc(courseId);
  const userLicensedTraining = await userRef.get();

  if (userLicensedTraining.exists) {
    if (userLicensedTraining.data()) {
      return userLicensedTraining.data() as LicensedTraining;
    }
  }

  return {} as LicensedTraining;
};

export const fetchUserCertificate = async (
  userId: string
): Promise<certificationIDs[]> => {
  const certificationIDsRef = db.collection("certificationIDs");
  const query = certificationIDsRef.where("userID", "==", userId);

  const certificationIDs = await query.get();

  const certificates: certificationIDs[] = [];
  certificationIDs.forEach((cert: any) => {
    certificates.push({ certificationID: cert.id, ...cert.data() });
  });

  if (isEmptyObject(certificates)) {
    return [] as certificationIDs[];
  }

  return certificates as certificationIDs[];
};
