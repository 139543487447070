import React from "react";
import { Routes } from "./Routes";

export const App = (): JSX.Element => {
  return (
    <React.Fragment>
      <Routes user={true} init={true} />
    </React.Fragment>
  );
};
