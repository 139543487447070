import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { BasicCard } from "../../Cards/BasicCard";
import { useHistory } from "react-router";
import { routeCodes } from "../../../constants/routes";
import { testState } from "../../../recoil/appState";
import { useRecoilState, useSetRecoilState } from "recoil";
import { TestCompletionLanguageData } from "../../../constants/Language/Language";
import { CertificationStatus } from "../../../constants/appConstants";
import { auth } from "../../../firebase/firebase";
import {
  firebaseProducts,
  firebaseUsers,
  firebaseUserTrainingData,
} from "../../../recoil/dataHooks";
import { setFireStoreCollectionInCollection } from "../../../firebase/Data/dataReadWrite";
import format from "string-template";
import {
  fetchProductInformation,
  fetchUserInformation,
  fetchUserTrainingInformation,
} from "../../../utils/fetchData";
import { isEmptyObject } from "../../../utils/general";

const useStyles = makeStyles({
  text: {
    margin: "10px",
  },
  heading: {
    fontSize: "30px",
    margin: "10px",
    fontWeight: "bold",
  },
  pass: {
    color: "green",
    margin: "10px",
  },
  fail: {
    color: "red",
    margin: "10px",
  },
  margin: {
    margin: "10px",
  },
  button: {
    textTransform: "none",
    margin: "3px",
    marginTop: "30px",
  },
});

export const TestCompletion = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const user = auth.currentUser;

  const setTestState = useSetRecoilState(testState);
  const text = TestCompletionLanguageData();

  if (user === null) {
    return <p>No User</p>;
  }

  const [product, setProduct] = useRecoilState(firebaseProducts);
  const [userData, setUserData] = useRecoilState(firebaseUsers);
  const [userDataTrainingData, setUserDataTrainingData] = useRecoilState(
    firebaseUserTrainingData
  );

  const fetchUserTrainingData = async (courseID: string, userID: string) => {
    const userLicensedTraining = await fetchUserTrainingInformation(
      courseID,
      userID
    );

    if (isEmptyObject(userLicensedTraining)) {
      console.warn("Could not load licensedTraining");
      console.warn(`With the following course ID : ${courseID}`);
    } else {
      setUserDataTrainingData(userLicensedTraining);
    }
  };

  useEffect(() => {
    if (isEmptyObject(userData)) {
      fetchUser();
    }
  }, []);

  useEffect(() => {
    if (!isEmptyObject(user) && !isEmptyObject(userData)) {
      const productId = userData.currentLicense
        ? userData.currentLicense?.productId
        : userData.currentCourse;

      if (productId) {
        fetchProducts(productId);
        fetchUserTrainingData(
          userData.currentLicense?.code ?? productId,
          user.uid
        );
      }
    }
  }, [userData]);

  const fetchUser = async () => {
    const users = await fetchUserInformation(user.uid);

    if (isEmptyObject(users)) {
      console.warn("Could not load users");
    } else {
      setUserData(users);
    }
  };

  const fetchProducts = async (courseId: string) => {
    const products = await fetchProductInformation(courseId);

    if (isEmptyObject(products)) {
      console.warn("Could not load product");
    } else {
      setProduct(products);
    }
  };

  const handleBackToTraining = () => {
    setTestState(CertificationStatus.NOT_IN_PROGRESS);
    setExamState(CertificationStatus.NOT_IN_PROGRESS, false);
    setTimeout(() => history.push(routeCodes.VIDEOTUTORIAL.route), 550);
  };

  const handleTestRestart = () => {
    setTestState(CertificationStatus.NOT_IN_PROGRESS);
    setExamState(CertificationStatus.NOT_IN_PROGRESS, false);
    setTimeout(() => history.push(routeCodes.TESTPREFACE.route), 100);
  };

  const handleCertificate = () => {
    setTestState(CertificationStatus.COMPLETE);
    setExamState(CertificationStatus.COMPLETE, true);
    setTimeout(() => history.push(routeCodes.CERTIFICATEINPUT.route), 100);
  };

  const setExamState = (state: string, toggleTries: boolean) => {
    if (user !== null) {
      const updateObject: any = {
        examInProgress: {
          currentExamQuestions: [],
          lastAnsweredQuestionIndex: 0,
          numCorrectAnswers: 0,
        },
        examState: state,
      };

      if (toggleTries) {
        updateObject["examAttemptsRemaining"] = 0;
      }

      setFireStoreCollectionInCollection({
        collection1: "users",
        collection2: "licensedTraining",
        documentID1: user.uid,
        documentID2: (
          userData.currentLicense?.code ?? userData.currentCourse
        ).toString(),
        keyPairObjectToUpdate: updateObject,
        mergeFlag: true,
      });
    }
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <BasicCard title={text.title} subText={text.subtitle} center={true}>
        {parseInt(userDataTrainingData.score, 10) >= product.passRequirement ? (
          <React.Fragment>
            <Typography className={classes.pass}>
              {format(text.testCompletionPassTextBody, {
                score: userDataTrainingData.score,
              })}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                handleCertificate();
              }}
            >
              {text.toCertificate}
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography className={classes.fail}>
              {format(text.testCompletionFailTextBody, {
                score: userDataTrainingData.score,
              })}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                handleBackToTraining();
              }}
            >
              {text.backToTraining}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                handleTestRestart();
              }}
            >
              {text.restartTestText}
            </Button>
          </React.Fragment>
        )}
      </BasicCard>
    </Grid>
  );
};
