import React from "react";
import { Switch } from "react-router";
import { Loading } from "./components/Loading/Loading";
import { CoreView } from "./Views/CoreView/CoreView";
import { PublicRoute } from "./Views/Routes/PublicRoute";
import { routeCodes } from "./constants/routes";
import { ProtectedRoute } from "./Views/Routes/ProtectedRoute";
import { LoginView } from "./Views/LoginView/LoginView";
import { LicenseView } from "./Views/LicenseView/LicenseView";
import { LoginRegistrationView } from "./Views/LoginRegistrationView/LoginRegistrationView";
import { RegistrationView } from "./Views/RegistrationView/RegistrationView";
import { CertificateInputView } from "./Views/CertificateInputView/CertificateInputView";
import { ForgotPasswordView } from "./Views/ForgotPasswordView/ForgotPasswordView";
import { RegistrationPendingView } from "./Views/RegistrationPendingView/RegistrationPendingView";
import { TestView } from "./Views/TestView/TestView";
import { MyAccountView } from "./Views/MyAccountView/MyAccountView";
import { CertificateDisplayView } from "./Views/CertificateDisplayView/CertificateDisplayView";
import { TopicSelectionView } from "./Views/TopicSelectionView/TopicSelectionView";
import { VideoTutorialView } from "./Views/VideoTutorialView/VideoTutorialView";
import { EmailVerificationView } from "./Views/EmailVerificationView/EmailVerificationView";
import { PasswordResetView } from "./Views/PasswordResetView/PasswordResetView";
import { CustomEmailHandlerView } from "./Views/CustomEmailHandlerView/CustomEmailHandlerView";
import { CertificateIDValidationView } from "./Views/CertificateIDValidationView/CertificateIDValidationView";

interface Props {
  user: boolean;
  init: boolean;
}

export const Routes = ({ init }: Props): JSX.Element => {
  if (init) {
    return (
      <Switch>
        <CoreView>
          <PublicRoute path={routeCodes.LOGINREGISTRATION.route} exact>
            <LoginRegistrationView />
          </PublicRoute>
          <PublicRoute path={"/"} exact>
            <LoginRegistrationView />
          </PublicRoute>
          <PublicRoute path={routeCodes.REGISTRATION.route} exact>
            <RegistrationView />
          </PublicRoute>
          <PublicRoute path={routeCodes.LOGIN.route} exact>
            <LoginView />
          </PublicRoute>
          <PublicRoute path={routeCodes.REGISTRATIONPENDING.route} exact>
            <RegistrationPendingView />
          </PublicRoute>
          <PublicRoute path={routeCodes.FORGOTPASSWORD.route} exact>
            <ForgotPasswordView />
          </PublicRoute>

          <PublicRoute path={routeCodes.CERTIFICATEIDVALIDATION.route} exact>
            <CertificateIDValidationView />
          </PublicRoute>

          <PublicRoute path={routeCodes.EMAILVERIFICATION.route} exact>
            <EmailVerificationView />
          </PublicRoute>
          <PublicRoute path={routeCodes.CUSTOMEMAILHANDLER.route} exact>
            <CustomEmailHandlerView />
          </PublicRoute>
          <PublicRoute path={routeCodes.PASSWORDRESET.route} exact>
            <PasswordResetView />
          </PublicRoute>
          <Switch>
            <ProtectedRoute path={routeCodes.TOPICSELECTION.route} exact>
              <TopicSelectionView />
            </ProtectedRoute>
            <ProtectedRoute path={routeCodes.VIDEOTUTORIAL.route} exact>
              <VideoTutorialView />
            </ProtectedRoute>
            <ProtectedRoute path={routeCodes.TESTPREFACE.route} exact>
              <TestView />
            </ProtectedRoute>
            <ProtectedRoute path={routeCodes.CERTIFICATEINPUT.route} exact>
              <CertificateInputView />
            </ProtectedRoute>
            <ProtectedRoute path={routeCodes.CERTIFICATEDISPLAY.route} exact>
              <CertificateDisplayView />
            </ProtectedRoute>
            <ProtectedRoute path={routeCodes.LICENSE.route} exact>
              <LicenseView />
            </ProtectedRoute>

            <ProtectedRoute path={routeCodes.TESTPREFACE.route} exact>
              <TestView />
            </ProtectedRoute>
            <ProtectedRoute path={routeCodes.MYACCOUNT.route} exact>
              <MyAccountView />
            </ProtectedRoute>
          </Switch>
        </CoreView>
      </Switch>
    );
  }
  return <Loading />;
};
