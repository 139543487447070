import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { BasicCard } from "../Cards/BasicCard";
import { EmailVerificationLanguageData } from "../../constants/Language/Language";
import { routeCodes } from "../../constants/routes";
import { useHistory } from "react-router";
import "firebase/auth";

const useStyles = makeStyles((theme) => ({
  card: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "300",
    },
    [theme.breakpoints.up("md")]: {
      width: "400px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "400px",
    },
  },
  button: {
    textTransform: "none",
  },
}));

export const EmailVerificationCore = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const text = EmailVerificationLanguageData();

  return (
    <BasicCard title={text.title} subText={text.subtitle}>
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        className={classes.card}
      >
        <Grid item xs={12}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(routeCodes.LOGINREGISTRATION.route);
            }}
          >
            {text.buttonLogin}
          </Button>
        </Grid>
      </Grid>
    </BasicCard>
  );
};
