import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Markup } from "interweave";
import { auth } from "../../firebase/firebase";
import { useRecoilValue } from "recoil";
import { videoIndexState } from "../../recoil/appState";
import { firebaseProducts } from "../../recoil/dataHooks";
import { Loading } from "../Loading/Loading";
import Typography from "@mui/material/Typography";
import { isEmptyObject } from "../../utils/general";

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: "10px 0",
    "& .MuiFormLabel-root.Mui-Focused": { color: "black" },
  },
  box: {
    display: "flex",
    flexDirection: "column",
  },
  htmlDiv: {
    height: 68,
    overflowY: "auto",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
  },
  button: {
    textTransform: "none",
    marginRight: theme.spacing(0.5),
  },
  text: {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
}));

export const VideoTutorialTextArea = (): JSX.Element => {
  const classes = useStyles();
  const user = auth.currentUser;
  const videoIndex = useRecoilValue(videoIndexState);
  const product = useRecoilValue(firebaseProducts);

  if (user === null) {
    return <p>No User</p>;
  }

  return (
    <Box className={classes.box}>
      {(isEmptyObject(product) || typeof product.lessons[videoIndex]) ===
        "undefined" || typeof videoIndex === "undefined" ? (
        <Loading />
      ) : (
        <div className={classes.htmlDiv}>
          <Typography className={classes.text} variant="body1">
            <Markup content={product.lessons[videoIndex].additionalInfo} />
          </Typography>
        </div>
      )}
    </Box>
  );
};
