import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Link } from "@material-ui/core";
import { useHistory } from "react-router";
import { TopBarMenu } from "../TopBarMenu";
import { routeCodes } from "../../../constants/routes";
import { videoPlayingState } from "../../../recoil/appState";
import { hasExpiredLicense, isEmptyObject } from "../../../utils/general";
import { ControlSelectorLanguageData } from "../../../constants/Language/Language";
import { auth } from "../../../firebase/firebase";
import { fetchUserInformation } from "../../../utils/fetchData";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.common.black,
    fontWeight: 600,
    margin: "0 5px",
    textTransform: "none",
    transition: "opacity 200ms",

    "&:hover": {
      opacity: 0.4,
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

export const ControlSelector = (
  type: string | undefined
): JSX.Element | null => {
  const classes = useStyles();
  const history = useHistory();
  const [hasValidLicense, setHasValidLicense] = useState(false);
  const setVideoPlaying = useSetRecoilState(videoPlayingState);
  const text = ControlSelectorLanguageData();

  useEffect(() => {
    const checkIfUserIsEnrolled = () => {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const userData = await fetchUserInformation(user.uid);
          const productId =
            userData?.currentLicense?.productId ?? userData?.currentCourse;

          if (!isEmptyObject(userData) && productId) {
            const expriredLicense = await hasExpiredLicense(
              userData.currentLicense?.code,
              productId
            );

            setHasValidLicense(!expriredLicense);
          }
        }
      });
    };

    checkIfUserIsEnrolled();
  }, []);

  const handleNavigationClick = (route: string) => {
    setVideoPlaying(false);
    history.push(route);
  };

  switch (type) {
    case "":
      return null;
    case "login":
      return null;
    case "registration":
      return null;
    case "registrationpending":
      return null;
    case "loginregistration":
      return null;
    case "forgotpassword":
      return null;
    case "emailverification":
      return null;
    case "certificateidvalidation":
      return null;
    case "myaccount":
    case "videotutorial":
    case "topicselection":
      return (
        <Box className={classes.row}>
          {hasValidLicense ? (
            <>
              <Link
                component="button"
                variant="subtitle1"
                underline="none"
                className={classes.button}
                onClick={() =>
                  handleNavigationClick(routeCodes.TOPICSELECTION.route)
                }
              >
                {text.topic}
              </Link>
              <Link
                component="button"
                variant="subtitle1"
                underline="none"
                className={classes.button}
                onClick={() =>
                  handleNavigationClick(routeCodes.TESTPREFACE.route)
                }
              >
                {text.test}
              </Link>
              <Link
                component="button"
                variant="subtitle1"
                underline="none"
                className={classes.button}
                onClick={() => handleNavigationClick(routeCodes.LICENSE.route)}
              >
                {text.newCourse}
              </Link>
            </>
          ) : null}
          <TopBarMenu />
        </Box>
      );
    default:
      return <TopBarMenu />;
  }
};
