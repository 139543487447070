import React from "react";
import { PageNameWrapper } from "../../components/Cards/CenteredPage/PageNameWrapper";
import { Login } from "../../components/Login/Login";
import { loginPageTitle } from "../commonViewConstants";

export const LoginView = (): JSX.Element => {
  return (
    <PageNameWrapper title={loginPageTitle}>
      <Login />
    </PageNameWrapper>
  );
};
