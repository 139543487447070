import React, { useState, KeyboardEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { BasicCard } from "../Cards/BasicCard";
import { auth } from "../../firebase/firebase";
import { LoginRegistrationLanguageData } from "../../constants/Language/Language";
import { ShopLink } from "../ShopLink/ShopLink";
import { ErrorText } from "../ErrorText/ErrorTextCore";
import { ErrorTypes } from "../ErrorText/constants/constants";
import { routeCodes } from "../../constants/routes";
import { useHistory } from "react-router";
import { useSetRecoilState } from "recoil";
import { userEmail } from "../../recoil/appState";
import FormControl from "@mui/material/FormControl";

const useStyles = makeStyles((theme) => ({
  card: {
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1),
    paddingLeft: 0,
    [theme.breakpoints.down("sm")]: {
      width: "300",
    },
    [theme.breakpoints.up("md")]: {
      width: "850px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "1050px",
    },
  },
  button: {
    textTransform: "none",
  },
  textField: {
    "& .MuiFormLabel-root.Mui-Focused": { color: "black" },
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  cardLeft: {
    color: theme.palette.text.secondary,
  },
  cardRight: {
    color: theme.palette.text.secondary,
  },
  error: {
    textAlign: "center",
    color: "red",
  },
  blackText: {
    color: "black",
  },
  content: {
    paddingTop: theme.spacing(1),
  },
}));

export const LoginRegistrationCore = (): JSX.Element => {
  const classes = useStyles();
  const text = LoginRegistrationLanguageData();

  const history = useHistory();
  const [email, setEmail] = useState("");
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [errorType, setErrorType] = useState<ErrorTypes>("activated");
  const setUserEmail = useSetRecoilState(userEmail);

  const handleEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleButtonPress = (email: string) => {
    callFirebaseCheckEmail(email);
  };
  const callFirebaseCheckEmail = (email: string) => {
    setErrorDisplay(false);
    auth.fetchSignInMethodsForEmail(email).then(
      (result) => {
        if (result[0] === "password") {
          setUserEmail(email);
          history.push(routeCodes.LOGIN.route);
          // }
        } else {
          setUserEmail(email);
          history.push(routeCodes.REGISTRATION.route);
        }
      },
      () => {
        setErrorType("nonValidEmail");
        setErrorDisplay(true);
      }
    );
  };

  const handleKeyboardEvent = (e: KeyboardEvent<HTMLImageElement>) => {
    if (e.key === "Enter") {
      handleButtonPress(email);
    }
  };

  return (
    <BasicCard title={text.title} subText={text.subtitle}>
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        className={classes.card}
      >
        <Grid item xs={12} sm={12} lg={6} className={classes.cardLeft}>
          <Typography variant="body1" className={classes.blackText}>
            {text.subtext}
          </Typography>
          <FormControl
            onKeyPress={handleKeyboardEvent}
            className={classes.form}
          >
            <Box className={classes.content}>
              <TextField
                className={classes.textField}
                id="email-address"
                label={text.email}
                placeholder="user@example.com"
                value={email}
                type="email"
                autoComplete="on"
                onChange={handleEmailInput}
                variant="outlined"
              />
            </Box>
            <Box className={classes.content}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleButtonPress(email);
                }}
              >
                {text.further}
              </Button>
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} lg={6} className={classes.cardRight}>
          <ShopLink />
        </Grid>
        <ErrorText display={errorDisplay} errorMessage={errorType} />
      </Grid>
    </BasicCard>
  );
};
