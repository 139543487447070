import React from "react";
import { alpha } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      marginTop: "5rem",
    },

    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "baseline",
      backgroundColor: alpha(theme.palette.primary.main, 0.01),
      height: "100%",
      minHeight: "100%",
    },
    content: {
      padding: "2rem",
      height: "100%",
      width: "100%",
    },
  })
);

export interface CenteredPageProps {
  children?: React.ReactNode;
  title?: string;
}

export const CenteredPage = ({
  title,
  children,
}: CenteredPageProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {title ? (
        <Typography variant="h4" color="primary" className={classes.title}>
          {title}
        </Typography>
      ) : null}
      <div className={classes.content}>{children}</div>
    </div>
  );
};
