import React from "react";
import { PageNameWrapper } from "../../components/Cards/CenteredPage/PageNameWrapper";
import { VideoTutorialCore } from "../../components/VideoTutorial/VideoTutorialCore";
import { videoTutorialTitle } from "../commonViewConstants";

export const VideoTutorialView = (): JSX.Element => {
  return (
    <PageNameWrapper title={videoTutorialTitle}>
      <VideoTutorialCore />
    </PageNameWrapper>
  );
};
