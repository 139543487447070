import React, { Fragment, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@mui/system";
import { v4 as uuid } from "uuid";
import AutoSizer from "react-virtualized-auto-sizer";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
import {
  topicIndexState,
  videoIDState,
  videoIndexState,
} from "../../recoil/appState";
import { Loading } from "../Loading/Loading";
import { auth } from "../../firebase/firebase";
import { Lessons } from "../../firebase/types";
import { isEmptyObject } from "../../utils/general";
import { fetchUserTrainingInformation } from "../../utils/fetchData";
import { firebaseProducts, firebaseUsers } from "../../recoil/dataHooks";
import { LessonListLanguageData } from "../../constants/Language/Language";
import { setFireStoreCollectionInCollection } from "../../firebase/Data/dataReadWrite";
import firebase from "firebase/app";
import "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    width: "100%",

    [theme.breakpoints.down("xs")]: {
      height: 156,
    },
    [theme.breakpoints.up("sm")]: {
      height: 300,
    },
    [theme.breakpoints.up("md")]: {
      height: 400,
    },
    [theme.breakpoints.up("lg")]: {
      height: 540,
    },
    [theme.breakpoints.up("xl")]: {
      height: 580,
    },
  },
  certificate: {},
  table: {},
  listText: {},
  button: {
    textTransform: "none",
  },
  selected: {
    color: theme.palette.common.black,
    backgroundColor: alpha(theme.palette.grey[400], 0.7),
  },
}));

const getWindowDimensions = () => {
  const { innerWidth: width } = window;
  return {
    width,
  };
};

export const LessonListVideoElements = (): JSX.Element => {
  const classes = useStyles();
  const text = LessonListLanguageData();
  const product = useRecoilValue(firebaseProducts);
  const userData = useRecoilValue(firebaseUsers);
  const setVideoID = useSetRecoilState(videoIDState);
  const topicIndex = useRecoilValue(topicIndexState);
  const [watchVideoIndex, setWatchVideoIndex] = useRecoilState(videoIndexState);
  // SD0-258
  const licenseCode = userData.currentLicense?.code ?? userData.currentCourse;
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const watchVideoIndexArray = Array.from(Array(watchVideoIndex + 1).keys());
  const user = auth.currentUser;

  if (user === null) {
    return <p>No User</p>;
  }

  let lessons = product.lessons;

  if (product.topics) {
    const { firstLessonIndex, lastLessonIndex } = product.topics[topicIndex];

    lessons = product.lessons.slice(
      parseInt(firstLessonIndex),
      parseInt(lastLessonIndex) + 1
    );
  }

  const handleListItemClick = (videoUrl: string, videoIndex: number) => {
    if (user !== null && videoIndex >= 0) {
      setFireStoreCollectionInCollection({
        collection1: "users",
        collection2: "licensedTraining",
        documentID1: user.uid,
        documentID2: licenseCode.toString(),
        keyPairObjectToUpdate: {
          currentVideoIndex: videoIndex,
          clickedVideosList: firebase.firestore.FieldValue.arrayUnion(videoIndex)
        },
        mergeFlag: true,
      });
    }
    setVideoID(videoUrl);
    setWatchVideoIndex(videoIndex);
  };

  const getCurrentVideoIndex = async () => {
    const userLicensedTraining = await fetchUserTrainingInformation(
      licenseCode,
      user.uid
    );

    if (isEmptyObject(userLicensedTraining)) {
      console.warn("Could not load licensedTraining");
      console.warn(`With the following license Code : ${licenseCode}`);
    } else {
      setWatchVideoIndex(userLicensedTraining.currentVideoIndex);
    }
  };

  useEffect(() => {
    if (
      user &&
      (!isEmptyObject(userData.currentLicense) ||
        !isEmptyObject(userData.currentCourse))
    ) {
      getCurrentVideoIndex();
    }
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const LessonListVideo = (
    props: ListChildComponentProps
  ): JSX.Element | null => {
    const { style } = props;

    return (
      <div style={style}>
        {!isEmptyObject(product) ? (
          lessons.map((lesson: Lessons) => {
            const indexVideo = product.lessons.findIndex(
              (item) => item.title === lesson.title
            );

            return (
              <Fragment key={uuid()}>
                <div
                  className={
                    watchVideoIndex === indexVideo ? classes.selected : ""
                  }
                >
                  <ListItem
                    button
                    key={indexVideo}
                    onClick={() =>
                      handleListItemClick(lesson.videoUrl, indexVideo)
                    }
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          className={classes.listText}
                          variant="body1"
                        >
                          {lesson.title} | {lesson.description}
                        </Typography>
                      }
                    />
                    {windowDimensions["width"] < 420 ? null : (
                      <Checkbox
                        checked={watchVideoIndexArray.includes(indexVideo)}
                        disabled={true}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        icon={<PlayArrowOutlinedIcon />}
                        checkedIcon={<PlayArrowRoundedIcon color="secondary" />}
                      />
                    )}
                  </ListItem>
                </div>
                <Divider />
              </Fragment>
            );
          })
        ) : (
          <Typography variant={"body1"}>{text.loadingError}</Typography>
        )}
      </div>
    );
  };
  return (
    <Box className={classes.root}>
      {isEmptyObject(product) ? (
        <Loading />
      ) : (
        <AutoSizer>
          {({ height, width }: any) => (
            <FixedSizeList
              height={height}
              width={width}
              itemSize={40}
              itemCount={1}
            >
              {LessonListVideo}
            </FixedSizeList>
          )}
        </AutoSizer>
      )}
    </Box>
  );
};
