import { atom, useResetRecoilState } from "recoil";
import { firebase } from "../firebase/firebase";
import {
  certificationIDs,
  LicensedTraining,
  Products,
  TrainingLicenses,
  Users,
} from "../firebase/types";
import {
  loginPassword,
  testState,
  videoIDState,
  videoIndexState,
  videoPlayingState,
} from "./appState";

// Registration page
export const registerPasswordAssigned = atom({
  key: "registerPasswordAssigned",
  default: "",
});

export const registerPasswordRepeat = atom({
  key: "registerPasswordRepeat",
  default: "",
});

export const registerPrivacyPolicyChecked = atom({
  key: "registerPrivacyPolicyChecked",
  default: false,
});

export const registerContractTermsChecked = atom({
  key: "registerContractTermsChecked",
  default: false,
});

// My account page
export const myAccountPassword = atom({
  key: "myAccountPassword",
  default: "",
});

export const myAccountNewPassword = atom({
  key: "myAccountNewPassword",
  default: "",
});

export const myAccountNewPasswordConfirm = atom({
  key: "myAccountNewPasswordConfirm",
  default: "",
});

// Test Preface page
export const numberOfTestTries = atom({
  key: "numberOfTestTries",
  default: 3,
});

/** Firebase Data */

// Products
export const firebaseProducts = atom({
  key: "firebaseProducts",
  default: {} as Products,
});

// Training Licenses
export const firebaseTrainingLicenses = atom({
  key: "firebaseTrainingLicenses",
  default: [] as TrainingLicenses[],
});

// certification IDs
export const firebaseCertificationIDs = atom({
  key: "firebaseCertificationIDs",
  default: {} as certificationIDs,
});

// Users
export const firebaseUsers = atom({
  key: "firebaseUsers",
  default: {} as Users,
});

export const firebaseUserTrainingData = atom({
  key: "firebaseUsersTrainingData",
  default: {} as LicensedTraining,
});

export const currentUser = atom({
  key: "user",
  default: {} as firebase.User,
});

/** Reset recoil state to prevent caching */
export const useAppResetState: () => () => void = () => {
  const users = useResetRecoilState(firebaseUsers);
  const loggedInUser = useResetRecoilState(currentUser);
  const products = useResetRecoilState(firebaseProducts);
  const trainingLicenses = useResetRecoilState(firebaseTrainingLicenses);
  const certificationIDs = useResetRecoilState(firebaseCertificationIDs);
  const userTrainingData = useResetRecoilState(firebaseUserTrainingData);
  const test = useResetRecoilState(testState);
  const login = useResetRecoilState(loginPassword);
  const videoID = useResetRecoilState(videoIDState);
  const videoIndex = useResetRecoilState(videoIndexState);
  const videoPlaying = useResetRecoilState(videoPlayingState);

  return () => {
    test();
    login();
    users();
    videoID();
    products();
    videoIndex();
    videoPlaying();
    loggedInUser();
    trainingLicenses();
    certificationIDs();
    userTrainingData();
  };
};
