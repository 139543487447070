import React from "react";
import { PageNameWrapper } from "../../components/Cards/CenteredPage/PageNameWrapper";
import { LicenseCore } from "../../components/License/LicenseCore";
import { licensePageTitle } from "../commonViewConstants";

export const LicenseView = (): JSX.Element => {
  return (
    <PageNameWrapper title={licensePageTitle}>
      <LicenseCore />
    </PageNameWrapper>
  );
};
