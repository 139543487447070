import axios, { AxiosRequestConfig } from "axios";
import fileDownload from "js-file-download";
import { storage } from "../firebase/firebase";

interface startDownloadProps {
  urlIn: string;
  fileName: string;
  extension: string;
  onDownloadProgress: (event: ProgressEvent) => void;
}
//https://stackoverflow.com/questions/38924798/chrome-dev-tools-fails-to-show-response-even-the-content-returned-has-header-con/38925237#38925237
export const downloadFileFromGCPBucket = ({
  urlIn,
  fileName,
  extension,
  onDownloadProgress,
}: startDownloadProps): void => {
  const httpsReference = storage.refFromURL(urlIn);
  httpsReference
    .getDownloadURL()
    .then(async (url: any) => {
      try {
        const reqConfig = {
          method: "GET",
          url: url,
          responseType: "blob",
          onDownloadProgress: onDownloadProgress,
        } as AxiosRequestConfig;

        await axios(reqConfig)
          .then((response) => {
            fileDownload(response.data, `${fileName}${extension}`);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error(`Error downloading : ${fileName}`);
        console.error(error);
      }
    })
    .catch((error) => {
      console.warn(error);
      switch (error.code) {
        case "storage/object-not-found":
          // File doesn't exist
          console.error("File doesn't exist");
          break;
        case "storage/unauthorized":
          // User doesn't have permission to access the object
          console.error("User doesn't have permission to access the object");
          break;
        case "storage/canceled":
          // User canceled the upload
          console.error("User canceled the upload");
          break;
        case "storage/unknown":
          // Unknown error occurred, inspect the server response
          console.error("Unknown error occurred, inspect the server response");
          break;
      }
    });
};
