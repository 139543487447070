const SOCIAL_MEDIA_URL = {
  facebook: "https://www.facebook.com/sharer/sharer.php?src=sp&u=",
  twitter: "http://twitter.com/intent/tweet?url=",
};

const openLink = (link: string) => {
  window.open(link, "_blank");
};

export const shareTo = (platform: string, text: string, url: string): void => {
  switch (platform) {
    case "facebook":
      openLink(`${SOCIAL_MEDIA_URL.facebook}${url}&quote=${text}`);
      break;
    case "twitter":
      openLink(
        `${SOCIAL_MEDIA_URL.twitter}${encodeURIComponent(
          url
        )}&text=${encodeURIComponent(text)}`
      );
      break;
  }
};

export const shareViaEmail = (subjectText: string, bodyText: string): void =>
  openLink(`mailto:?subject=${subjectText}&body=${bodyText}`);
