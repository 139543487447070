import React from "react";
import { PageNameWrapper } from "../../components/Cards/CenteredPage/PageNameWrapper";
import { LoginRegistrationCore } from "../../components/LoginRegistration/LoginRegistrationCore";
import { loginRegistrationPageTitle } from "../commonViewConstants";

export const LoginRegistrationView = (): JSX.Element => {
  return (
    <PageNameWrapper title={loginRegistrationPageTitle}>
      <LoginRegistrationCore />
    </PageNameWrapper>
  );
};
