import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  typography: {
    fontFamily: "Roboto",
  },
  palette: {
    primary: {
      main: "#728987",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ced2d1",
      main: "#455251",
    },
    success: {
      main: "#4BB543",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: "#fff",
        borderRadius: 24,
        backgroundColor: "#728987",
        textTransform: "none",

        "&:disabled": {
          backgroundColor: "#C7D0CF",
        },

        "&:hover": {
          backgroundColor: "#ccc",
        },

        "& .MuiButton-endIcon": {
          marginLeft: 14,
        },

        "@media (hover: none)": {
          "&:hover": {
            backgroundColor: "#728987",

            "&.Mui-disabled": {
              backgroundColor: "#C7D0CF",
            },
          },
        },
      },
    },
  },
});
