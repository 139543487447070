import React, { Fragment } from "react";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Grid from "@mui/material/Grid";
import { v4 as uuid } from "uuid";
import {
  privacyPolicyLanguageData,
  FooterLanguageData,
  imprintLanguageData,
  termsOfServiceLanguageData,
} from "../../constants/Language/Language";
import { footerDialogTitle, footerTOSToggle } from "../../recoil/appState";
import { DialogFooter } from "./TermsOfService/DialogFooter";
import { routeCodes } from "../../constants/routes";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.common.white,
    fontSize: "0.875rem",
    fontWeight: 600,
    minWidth: 100,
    padding: " 6px 16px",
    textTransform: "uppercase",
    transition: "opacity 200ms",
    width: "100%",

    "&:hover": {
      opacity: 0.4,
    },
  },
}));

export const FooterButton = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const [footerDialogTitleState, setFooterDialogTitleState] =
    useRecoilState(footerDialogTitle);
  const [TOSToggle, setTOSToggle] = useRecoilState(footerTOSToggle);
  const text = FooterLanguageData();
  const dataProtectionText = privacyPolicyLanguageData();
  const imprintText = imprintLanguageData();
  const tosText = termsOfServiceLanguageData();

  interface footerButton {
    label: string;
    setFooterDialogTitle?: string;
  }
  const footerButtons: footerButton[] = [
    {
      label: text.imprint,
      setFooterDialogTitle: imprintText.imprintTitle,
    },
    {
      label: text.dataProtection,
      setFooterDialogTitle: dataProtectionText.privacyPolicyTitle,
    },
    {
      label: text.terms,
      setFooterDialogTitle: tosText.termsOfServiceTitle,
    },
    {
      label: text.certificateIDCheck,
    },
  ];

  const TOSContentFooter = (footerDialogTitle: string): string => {
    switch (footerDialogTitle) {
      case dataProtectionText.privacyPolicyTitle:
        return dataProtectionText.privacyPolicyText;
      case imprintText.imprintTitle:
        return imprintText.imprintText;
      case tosText.termsOfServiceTitle:
        return tosText.termsOfServiceText;
      default:
        return text.unknownDeclaration;
    }
  };

  return (
    <Fragment>
      <DialogFooter
        title={footerDialogTitleState}
        bodyText={TOSContentFooter(footerDialogTitleState)}
        open={TOSToggle}
        setOpen={setTOSToggle}
      />
      <Grid
        container
        spacing={{ xs: 2, md: 0.5 }}
        columns={{ xs: 2, sm: 2, md: 5 }}
      >
        {footerButtons.map((button, index) => (
          <Grid item xs={1} sm={1} md={index !== 3 ? 1 : 2} key={index}>
            <Link
              key={uuid()}
              component="button"
              variant="subtitle1"
              underline="none"
              className={classes.button}
              onClick={() => {
                if (button.setFooterDialogTitle) {
                  setFooterDialogTitleState(button.setFooterDialogTitle);
                  setTOSToggle(true);
                } else {
                  history.push(routeCodes.CERTIFICATEIDVALIDATION.route);
                }
              }}
            >
              {button.label}
            </Link>
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};
