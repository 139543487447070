import LanguagePacks from "./LanguagePacks.json";
import { useRecoilValue } from "recoil";
import { selectedLanguagePack } from "../../recoil/appState";

const languageData = () => {
  const languagePack = useRecoilValue(selectedLanguagePack);
  const result = Object.entries(LanguagePacks).filter(
    (element) => element[0] === languagePack
  )[0][1];
  return result as unknown as Data;
};

export interface Data {
  login: LoginLanguageData;
  emailVerification: EmailVerificationLanguageDataInterface;
  passwordResetCore: PasswordResetCoreLanguageDataInterface;
  passwordResetSuccess: PasswordResetSuccessLanguageDataInterface;
  termsOfService: termsOfServiceLanguageDataInterface;
  imprint: imprintLanguageDataInterface;
  privacyPolicy: privacyPolicyLanguageDataInterface;
  certificateIDValidation: CertificateIDValidationLanguageDataInterface;
  CreatePDFCore: CreateCertificateLanguageData;
  socialSharing: SocialSharingLanguageDataInterface;
  topic: TopicLanguageDataInterface;
  topBar: TopBarLanguageData;
  footer: FooterLanguageData;
  dialog: DialogLanguageData;
  consent: ConsentBannerLanguageData;
  license: LicenseLanguageData;
  shopLink: ShopLinkLanguageData;
  myAccount: MyAccountLanguageData;
  deleteMyAccount: DeleteMyAccountLanguageDataInterface;
  basicCard: BasicCardLanguageData;
  lessonList: LessonListLanguageData;
  testPreface: TestPrefaceLanguageData;
  questionCard: QuestionCardLanguageData;
  registration: RegistrationLanguageData;
  passwordReset: PasswordResetLanguageData;
  forgotPassword: ForgotPasswordLanguageData;
  testCompletion: TestCompletionLanguageData;
  offlineSettings: OfflineSettingsLanguageData;
  controlSelector: ControlSelectorLanguageData;
  loginRegistration: LoginRegistrationLanguageData;
  errorMessages: ErrorMessagesLanguageDataInterface;
  videoTutorialVideo: VideoTutorialVideoLanguageData;
  certificateDisplay: CertificateDisplayLanguageData;
  termsOfServiceDialog: TermsOfServiceDialogLanguageData;
  registrationTextInputs: RegistrationTextInputsLanguageData;
  myAccountLicenses: MyAccountLicensesLanguageData;
  myAccountCertifications: MyAccountCertificationsLanguageData;
  registrationButtonInputs: RegistrationButtonInputsLanguageData;
  registrationCheckBoxInputs: RegistrationCheckBoxInputsLanguageData;
}

export interface LoginLanguageData {
  title: string;
  subtitle: string;
  forgotPassword: string;
  buttonLogin: string;
  visitShop: string;
  invalidPassword: string;
  newEmail: string;
  password: string;
}

export const LoginLanguageData = (): LoginLanguageData => {
  return languageData().login;
};

export interface CertificateIDValidationLanguageDataInterface {
  title: string;
  enterCertificateCode: string;
  checkCertificate: string;
  certificateIsValid: string;
  titleForCertificate: string;
  courseName: string;
  certificateCode: string;
  firstName: string;
  lastName: string;
  certificationEarnedDate: string;
  ValidUntilDate: string;
}
export const CertificateIDValidationLanguageData =
  (): CertificateIDValidationLanguageDataInterface => {
    return languageData().certificateIDValidation;
  };

export interface EmailVerificationLanguageDataInterface {
  title: string;
  subtitle: string;
  buttonLogin: string;
}
export const EmailVerificationLanguageData =
  (): EmailVerificationLanguageDataInterface => {
    return languageData().emailVerification;
  };

export interface PasswordResetCoreLanguageDataInterface {
  title: string;
  subtitle: string;
  newPassword: string;
  repeatNewPassword: string;
  buttonReset: string;
}
export const PasswordResetCoreLanguageData =
  (): PasswordResetCoreLanguageDataInterface => {
    return languageData().passwordResetCore;
  };

export interface PasswordResetSuccessLanguageDataInterface {
  title: string;
  subtitle: string;
  buttonLogin: string;
}

export const PasswordResetSuccessLanguageData =
  (): PasswordResetSuccessLanguageDataInterface => {
    return languageData().passwordResetSuccess;
  };

export interface termsOfServiceLanguageDataInterface {
  termsOfServiceTitle: string;
  termsOfServiceText: string;
}

export const termsOfServiceLanguageData =
  (): termsOfServiceLanguageDataInterface => {
    return languageData().termsOfService;
  };

export interface imprintLanguageDataInterface {
  imprintTitle: string;
  imprintText: string;
}

export const imprintLanguageData = (): imprintLanguageDataInterface => {
  return languageData().imprint;
};
export interface privacyPolicyLanguageDataInterface {
  privacyPolicyTitle: string;
  privacyPolicyText: string;
}

export const privacyPolicyLanguageData =
  (): privacyPolicyLanguageDataInterface => {
    return languageData().privacyPolicy;
  };

export interface TopBarLanguageData {
  myAccount: string;
}

export const TopBarLanguageData = (): TopBarLanguageData => {
  return languageData().topBar;
};

export interface LicenseLanguageData {
  title: string;
  subtitle: string;
  enterCode: string;
  noAccess: string;
  goButton: string;
  courseUpdateTitle: string;
  courseUpdateText: string;
  newLicenseText: string;
  newLicenseAccept: string;
  newLicenseDecline: string;
}

export const LicenseLanguageData = (): LicenseLanguageData => {
  return languageData().license;
};

export interface ControlSelectorLanguageData {
  myAccount: string;
  welcome: string;
  newCourse: string;
  logout: string;
  topic: string;
  test: string;
}

export const ControlSelectorLanguageData = (): ControlSelectorLanguageData => {
  return languageData().controlSelector;
};

export interface LoginRegistrationLanguageData {
  title: string;
  subtext: string;
  subtitle: string;
  noAccessCode: string;
  further: string;
  email: string;
}

export const LoginRegistrationLanguageData =
  (): LoginRegistrationLanguageData => {
    return languageData().loginRegistration;
  };

export interface FooterLanguageData {
  dataProtection: string;
  imprint: string;
  terms: string;
  certificateIDCheck: string;
  unknownDeclaration: string;
}

export const FooterLanguageData = (): FooterLanguageData => {
  return languageData().footer;
};

export interface BasicCardLanguageData {
  info: string;
}

export const BasicCardLanguageData = (): BasicCardLanguageData => {
  return languageData().basicCard;
};

export interface CertificateDisplayLanguageData {
  title: string;
  subtitle: string;
  warning: string;
  certificationParagraph: string;
  lastName: string;
  firstName: string;
  createCertificate: string;
  emailCertificate: string;
  emailHeading: string;
  emailBodyText: string;
}

export const CertificateDisplayLanguageData =
  (): CertificateDisplayLanguageData => {
    return languageData().certificateDisplay;
  };

export interface ConsentBannerLanguageData {
  agree: string;
  disagree: string;
  bannerText: string;
  urlDisplayText: string;
  httpURL: string;
  consentDialogTitle: string;
  consentDialogBody: string;
  consentDialogButton: string;
}

export const ConsentBannerLanguageData = (): ConsentBannerLanguageData => {
  return languageData().consent;
};
export interface DialogLanguageData {
  agree: string;
  disagree: string;
  create: string;
  cancel: string;
  emptyName: string;
  emptyDescription: string;
  nameLabel: string;
  descriptionLabel: string;
  consentDialogTitle: string;
  consentDialogBody: string;
  consentDialogButton: string;
}

export const DialogLanguageData = (): DialogLanguageData => {
  return languageData().dialog;
};

export interface ForgotPasswordLanguageData {
  title: string;
  emailSent: string;
  cancel: string;
  resetLink: string;
  emailLabel: string;
}

export const ForgotPasswordLanguageData = (): ForgotPasswordLanguageData => {
  return languageData().forgotPassword;
};

export interface LessonListLanguageData {
  loadingError: string;
}

export const LessonListLanguageData = (): LessonListLanguageData => {
  return languageData().lessonList;
};

export interface MyAccountLicensesLanguageData {
  expiryDate: string;
  licenseID: string;
  myLicense: string;
  noLicense: string;
  purchaseDate: string;
  redeemedDate: string;
}

export const MyAccountLicensesLanguageData =
  (): MyAccountLicensesLanguageData => {
    return languageData().myAccountLicenses;
  };

export interface MyAccountCertificationsLanguageData {
  certificateID: string;
  validUntil: string;
  earnedOn: string;
  score: string;
  action: string;
  newCertificate: string;
  myCertificate: string;
  noCertificate: string;
  noCertificateFor: string;
  courseTitle: string;
}

export const MyAccountCertificationsLanguageData =
  (): MyAccountCertificationsLanguageData => {
    return languageData().myAccountCertifications;
  };

export interface PasswordResetLanguageData {
  success: string;
  changePassword: string;
  updatePassword: string;
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

export const PasswordResetLanguageData = (): PasswordResetLanguageData => {
  return languageData().passwordReset;
};

export interface MyAccountLanguageData {
  myAccount: string;
  welcome: string;
}

export const MyAccountLanguageData = (): MyAccountLanguageData => {
  return languageData().myAccount;
};

export interface DeleteMyAccountLanguageDataInterface {
  title: string;
  buttonText: string;
  successText: string;
  dialogTitle: string;
  dialogAccept: string;
  dialogDecline: string;
}

export const DeleteMyAccountLanguageData =
  (): DeleteMyAccountLanguageDataInterface => {
    return languageData().deleteMyAccount;
  };

export interface QuestionCardLanguageData {
  testAbandon: string;
  dialogTestAbandon: string;
  nextQuestion: string;
  dialogTextPre: string;
  backToExam: string;
  cancelExam: string;
  cancelExamTitle: string;
  dialogTextLogIn: string;
  dialogTextLogInButton: string;
}

export const QuestionCardLanguageData = (): QuestionCardLanguageData => {
  return languageData().questionCard;
};

export interface RegistrationLanguageData {
  registrationTitle: string;
  subtitle: string;
  registrationPendingTitle: string;
  activateEmail: string;
  postActivateEmail: string;
  emailNotSent: string;
  newEmail: string;
  buttonText: string;
  emailSent: string;
}

export const RegistrationLanguageData = (): RegistrationLanguageData => {
  return languageData().registration;
};

export interface RegistrationTextInputsLanguageData {
  email: string;
  assignedPassword: string;
  repeatPassword: string;
}

export const RegistrationTextInputsLanguageData =
  (): RegistrationTextInputsLanguageData => {
    return languageData().registrationTextInputs;
  };

export interface RegistrationCheckBoxInputsLanguageData {
  termsOfService: string;
  readPrivacyPolicy: string;
  readContractTerms: string;
}

export const RegistrationCheckBoxInputsLanguageData =
  (): RegistrationCheckBoxInputsLanguageData => {
    return languageData().registrationCheckBoxInputs;
  };

export interface RegistrationButtonInputsLanguageData {
  backToLogin: string;
  activationEmail: string;
}

export const RegistrationButtonInputsLanguageData =
  (): RegistrationButtonInputsLanguageData => {
    return languageData().registrationButtonInputs;
  };

export interface ShopLinkLanguageData {
  shopLinkText: string;
}

export const ShopLinkLanguageData = (): ShopLinkLanguageData => {
  return languageData().shopLink;
};

export interface TestCompletionLanguageData {
  title: string;
  subtitle: string;
  testCompletionPassTextBody: string;
  testCompletionFailTextBody: string;
  toCertificate: string;
  backToTraining: string;
  restartTestText: string;
}

export const TestCompletionLanguageData = (): TestCompletionLanguageData => {
  return languageData().testCompletion;
};

export interface TestPrefaceLanguageData {
  title: string;
  welcome: string;
  testPrefaceParagraph: string;
  certificateText: string;
  startTestButton: string;
  backToVideoTutorial: string;
  alreadyCompleted: string;
}

export const TestPrefaceLanguageData = (): TestPrefaceLanguageData => {
  return languageData().testPreface;
};

export interface OfflineSettingsLanguageData {
  audio: string;
  disclaimerHeader: string;
  disclaimerBody: string;
  downloadLesson: string;
  downloadCourse: string;
  title: string;
  snackbarTextVideo: string;
  snackbarTextAudio: string;
  video: string;
}

export const OfflineSettingsLanguageData = (): OfflineSettingsLanguageData => {
  return languageData().offlineSettings;
};

export interface VideoTutorialVideoLanguageData {
  previousLecture: string;
  previousTopic: string;
  nextLecture: string;
  nextTopic: string;
}

export const VideoTutorialVideoLanguageData =
  (): VideoTutorialVideoLanguageData => {
    return languageData().videoTutorialVideo;
  };

export interface ErrorMessagesLanguageDataInterface {
  wrongPassword: string;
  passwordReset: string;
  passwordMismatch: string;
  passwordTooShort: string;
  passwordNoLower: string;
  passwordNoUpper: string;
  passwordNoNumber: string;
  passwordNoSpecial: string;
  activated: string;
  emailExists: string;
  nonValidEmail: string;
  nonValidPassword: string;
  license: string;
  policyUnchecked: string;
  termsUnchecked: string;
  empty: string;
  dynamicError: string;
  licenseAlreadyRedeemed: string;
  bulkLicenseAlreadyAllRedeemed: string;
  licenseAlreadyAssignedToUser: string;
  licenseNotFound: string;
  licenseExpired: string;
  general: string;
  certificationIDNotValid: string;
  certificationIDNotFound: string;
  default: string;
}

export const ErrorMessagesLanguageData =
  (): ErrorMessagesLanguageDataInterface => {
    return languageData().errorMessages;
  };

export interface TermsOfServiceDialogLanguageData {
  accept: string;
  decline: string;
  couldntLoad: string;
}

export const TermsOfServiceDialogLanguageData =
  (): TermsOfServiceDialogLanguageData => {
    return languageData().termsOfServiceDialog;
  };

export interface CreateCertificateLanguageData {
  title: string;
  awardedTo: string;
  firstAndLastName: string;
  completedText: string;
  awardedOn: string;
  courseId: string;
  downloadCertificate: string;
  expiresOn: string;
  courseUniqueID: string;
}

export const CreateCertificateLanguageData =
  (): CreateCertificateLanguageData => {
    return languageData().CreatePDFCore;
  };

export interface SocialSharingLanguageDataInterface {
  emailSubject: string;
  emailBody: string;
  title: string;
  urlLink: string;
  urlText: string;
}

export const SocialSharingLanguageData =
  (): SocialSharingLanguageDataInterface => {
    return languageData().socialSharing;
  };

export interface TopicLanguageDataInterface {
  noTopic: string;
  header: string;
  download: string;
  chapter: string;
  minute: string;
}

export const TopicLanguageData = (): TopicLanguageDataInterface => {
  return languageData().topic;
};
