import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TopBar } from "../../components/TopBar/TopBar";
import { Footer } from "../../components/Footer/Footer";
import { ConsentBanner } from "../../components/ConsentBanner/ConsentBanner";

const useStyles = makeStyles({
  container: {
    display: "flex",
    height: "100vh",
  },
  sidebar: {
    display: "flex",
    flexGrow: 0,
    flexShrink: 0,
  },
  content: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    width: "100%",
  },
  coreContent: {
    flexGrow: 1,
    width: "100%",
  },
});

interface Props {
  children: React.ReactNode;
}

export const CoreView = ({ children }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.sidebar}></div>
      <div className={classes.content}>
        <TopBar title="" />

        <div className={classes.coreContent}>
          <React.Fragment>{children}</React.Fragment>
        </div>
        <div>
          <ConsentBanner link={false} />
          <Footer />
        </div>
      </div>
    </div>
  );
};
