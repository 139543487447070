import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useRecoilState } from "recoil";
import {
  myAccountNewPassword,
  myAccountPassword,
  myAccountNewPasswordConfirm,
} from "../../recoil/dataHooks";
import { ErrorText } from "../ErrorText/ErrorTextCore";
import { auth, firebase } from "../../firebase/firebase";
import { ErrorTypes } from "../ErrorText/constants/constants";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { PasswordResetLanguageData } from "../../constants/Language/Language";
import { buildError } from "../../utils/error";

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: "10px 0",
    "& .MuiFormLabel-root.Mui-Focused": { color: "black" },
    width: "100%",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  button: {
    textTransform: "none",
    marginRight: theme.spacing(0.5),
  },
}));

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const MyAccountPasswordReset = (): JSX.Element => {
  const classes = useStyles();
  const text = PasswordResetLanguageData();
  const [open, setOpen] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [errorType, setErrorType] = useState<ErrorTypes>("passwordMismatch");
  const [dynamicError, setDynamicError] = useState("");
  const [passwordOG, setPasswordOG] = useRecoilState(myAccountPassword);
  const [newPassword, setNewPassword] = useRecoilState(myAccountNewPassword);
  const [newPasswordConfirmed, setNewPasswordConfirmed] = useRecoilState(
    myAccountNewPasswordConfirm
  );

  const handlePasswordOGInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPasswordOG(event.target.value);
  };

  const handleNewPasswordInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPassword(event.target.value);
  };

  const handleNewPasswordConfirmedInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPasswordConfirmed(event.target.value);
  };

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  interface hprbpProps {
    passwordOG: string;
    newPassword: string;
    newPasswordConfirmed: string;
  }

  const handlePasswordResetButtonPress = ({
    passwordOG,
    newPassword,
    newPasswordConfirmed,
  }: hprbpProps) => {
    const user = auth.currentUser;
    setErrorDisplay(false);

    if (newPassword !== newPasswordConfirmed) {
      setErrorDisplay(true);
      setErrorType("passwordMismatch");
      return null;
    }
    if (newPassword.length < 8) {
      setErrorType("passwordTooShort");
      setErrorDisplay(true);
      return;
    }

    if (!newPassword.match(/[a-z]/)) {
      setErrorType("passwordNoLower");
      setErrorDisplay(true);
      return;
    }

    if (!newPassword.match(/[A-Z]/)) {
      setErrorType("passwordNoUpper");
      setErrorDisplay(true);
      return;
    }

    if (!/\d/.test(newPassword)) {
      setErrorType("passwordNoNumber");
      setErrorDisplay(true);
      return;
    }

    const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if (!format.test(newPassword)) {
      setErrorType("passwordNoSpecial");
      setErrorDisplay(true);
      return;
    }

    if (!user) {
      return null;
    }

    if (user.email === null) {
      return null;
    }

    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      passwordOG
    );

    user
      .reauthenticateWithCredential(credential)
      .then(function () {
        user
          .updatePassword(newPassword)
          .then(() => {
            setOpen(true);
            setPasswordOG("");
            setNewPassword("");
            setNewPasswordConfirmed("");
            setErrorDisplay(false);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(errorCode + errorMessage);
            setErrorType("wrongPassword");
            setErrorDisplay(true);
          });
      })
      .catch(function (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        setDynamicError(buildError({ code: errorCode, message: errorMessage }));
        setErrorType("dynamicError");
        setErrorDisplay(true);
      });
  };

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {text.success}
        </Alert>
      </Snackbar>
      <div>
        <Box className={classes.box}>
          <Typography variant={"h6"}>{text.changePassword}</Typography>
          <TextField
            className={classes.textField}
            id="passwordOG"
            label={text.currentPassword}
            value={passwordOG}
            onChange={handlePasswordOGInput}
            type="password"
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            id="new-password"
            label={text.newPassword}
            value={newPassword}
            onChange={handleNewPasswordInput}
            type="password"
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            id="new-password-confirmed"
            label={text.repeatNewPassword}
            value={newPasswordConfirmed}
            onChange={handleNewPasswordConfirmedInput}
            type="password"
            variant="outlined"
          />
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              handlePasswordResetButtonPress({
                passwordOG,
                newPassword,
                newPasswordConfirmed,
              });
            }}
          >
            {text.updatePassword}
          </Button>
          <ErrorText
            display={errorDisplay}
            errorMessage={errorType}
            dynamicError={dynamicError}
          />
        </Box>
      </div>
    </React.Fragment>
  );
};
