import React from "react";
import { PageNameWrapper } from "../../components/Cards/CenteredPage/PageNameWrapper";
import { EmailVerificationCore } from "../../components/EmailVerification/EmailVerificationCore";
import { emailVerificationTitle } from "../commonViewConstants";

export const EmailVerificationView = (): JSX.Element => {
  return (
    <PageNameWrapper title={emailVerificationTitle}>
      <EmailVerificationCore />
    </PageNameWrapper>
  );
};
