import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { DeleteMyAccountLanguageData } from "../../constants/Language/Language";

const useStyles = makeStyles(() =>
  createStyles({
    confirm: {
      color: "green",
    },
    close: {
      color: "red",
    },
  })
);

interface Props {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const DeleteMyAccountDialog = ({
  open,
  handleClose,
  handleConfirm,
}: Props): JSX.Element => {
  const text = DeleteMyAccountLanguageData();
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <DialogTitle>{text.dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogActions>
          <Button className={classes.close} onClick={handleClose}>
            <Typography variant="button">{text.dialogDecline}</Typography>
          </Button>
          <Button className={classes.confirm} onClick={handleConfirm}>
            {text.dialogAccept}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
