import React from "react";
import { PageNameWrapper } from "../../components/Cards/CenteredPage/PageNameWrapper";
import { CertificateDisplayCore } from "../../components/CertificateDisplay/CertificateDisplayCore";
import { certificateDisplayPageTitle } from "../commonViewConstants";

export const CertificateDisplayView = (): JSX.Element => {
  return (
    <PageNameWrapper title={certificateDisplayPageTitle}>
      <CertificateDisplayCore />
    </PageNameWrapper>
  );
};
