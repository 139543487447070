import React, { Fragment, SyntheticEvent, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  createStyles,
  makeStyles,
  Grid,
  IconButton,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { theme } from "../../../constants/theme";
import { firebaseProducts } from "../../../recoil/dataHooks";
import { topicIndexState, videoIndexState } from "../../../recoil/appState";
import { OfflineSettingsLanguageData } from "../../../constants/Language/Language";
import { downloadFileFromGCPBucket } from "../../../utils/download";
import { Lessons, Topics } from "../../../firebase/types";
import { isEmptyObject } from "../../../utils/general";

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      margin: theme.spacing(0.5),
      height: "3em",
      width: "15em",
    },
    center: {
      textAlign: "center",
    },
    textBold: {
      fontWeight: 600,
    },
    loadingCircleContainer: {
      [theme.breakpoints.up("xs")]: {
        display: "inline-flex",
      },
    },
    loadingCircleAndText: {
      [theme.breakpoints.up("xs")]: {
        position: "relative",
        display: "inline-flex",
      },
    },
    loadingCircle: {
      [theme.breakpoints.up("xs")]: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    snackBarText: {
      padding: "10px 0px 0px 8px",
    },
  })
);

type downloadTypes = "video" | "audio";

interface Props {
  open: boolean;
  handleClose: () => void;
}

export const OfflineSettingsDialog = ({
  open: dialogOpen,
  handleClose: handleDialogClose,
}: Props): JSX.Element => {
  const classes = useStyles();
  const text = OfflineSettingsLanguageData();

  const topicIndex = useRecoilValue(topicIndexState);
  const videoIndex = useRecoilValue(videoIndexState);
  const product = useRecoilValue(firebaseProducts);
  const currentVideo = product?.lessons?.[videoIndex] ?? ({} as Lessons);
  const currentTopic = product?.topics?.[topicIndex] ?? ({} as Topics);
  const [open, setOpen] = useState(false);
  const [downloadType, setDownloadType] = useState<downloadTypes>("video");
  const [downloadProgress, setDownloadProgress] = useState(0);

  const currentLesson = !isEmptyObject(currentTopic)
    ? currentTopic
    : currentVideo;

  const onDownloadProgress = (event: ProgressEvent) => {
    const progress = (event.loaded / event.total) * 100;
    setDownloadProgress(progress);
    if (progress === 100) {
      setOpen(false);
      setDownloadProgress(0);
    }
  };

  const handleDownloadVideoCurrent = () => {
    setDownloadType("video");
    setOpen(true);
    downloadFileFromGCPBucket({
      urlIn:
        currentTopic["videoTopicFullDownload"] ??
        currentVideo["videoOfflineDLUrl"],
      fileName: currentLesson["title"],
      extension: ".mp4",
      onDownloadProgress,
    });
  };

  const handleDownloadAllVideos = () => {
    setDownloadType("video");
    setOpen(true);
    downloadFileFromGCPBucket({
      urlIn: product["vidFullDownload"],
      fileName: product["title"],
      extension: ".mp4",
      onDownloadProgress,
    });
  };

  const handleDownloadAudioCurrent = () => {
    setDownloadType("audio");
    setOpen(true);
    downloadFileFromGCPBucket({
      urlIn:
        currentTopic["audioTopicFullDownload"] ??
        currentVideo["videoOfflineDLUrl"],
      fileName: currentLesson["title"],
      extension: ".mp3",
      onDownloadProgress,
    });
  };

  const handleDownloadAllAudio = () => {
    setDownloadType("audio");
    setOpen(true);
    downloadFileFromGCPBucket({
      urlIn: product["audioFullDownload"],
      fileName: product["title"],
      extension: ".mp3",
      onDownloadProgress,
    });
  };

  const handleClose = (
    _event: Event | SyntheticEvent<unknown, Event>,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <Fragment>
      <Snackbar
        open={open}
        onClose={handleClose}
        message={
          <Fragment>
            <Box className={classes.loadingCircleContainer}>
              <Box className={classes.loadingCircleAndText}>
                <CircularProgress
                  variant="determinate"
                  value={downloadProgress}
                />
                <Box className={classes.loadingCircle}>
                  <Typography variant="caption" component="div">{`${Math.round(
                    downloadProgress
                  )}%`}</Typography>
                </Box>
              </Box>
              {downloadType === "video" ? (
                <Typography className={classes.snackBarText} variant={"body1"}>
                  {text.snackbarTextVideo}
                </Typography>
              ) : (
                <Typography className={classes.snackBarText} variant={"body1"}>
                  {text.snackbarTextAudio}
                </Typography>
              )}
            </Box>
          </Fragment>
        }
        action={action}
      />

      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="xs">
        <DialogContent>
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                spacing={1}
                justifyContent="center"
              >
                <Grid item>
                  <Typography variant="h4" className={classes.textBold}>
                    {text.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5" className={classes.textBold}>
                    {text.downloadLesson}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                direction="column"
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    endIcon={<GetAppRoundedIcon />}
                    className={classes.button}
                    onClick={handleDownloadVideoCurrent}
                  >
                    {text.video}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    endIcon={<GetAppRoundedIcon />}
                    className={classes.button}
                    onClick={handleDownloadAudioCurrent}
                  >
                    {text.audio}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                direction="column"
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h5" className={classes.textBold}>
                    {text.downloadCourse}
                  </Typography>
                </Grid>

                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    endIcon={<GetAppRoundedIcon />}
                    className={classes.button}
                    onClick={handleDownloadAllVideos}
                  >
                    {text.video}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    endIcon={<GetAppRoundedIcon />}
                    className={classes.button}
                    onClick={handleDownloadAllAudio}
                  >
                    {text.audio}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs className={classes.center}>
              <Typography variant="subtitle1" className={classes.textBold}>
                {text.disclaimerHeader}
              </Typography>
              <Typography
                variant="body1"
                className={classes.textBold}
                paragraph
              >
                {text.disclaimerBody}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
