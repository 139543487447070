import React from "react";
import { useRecoilValue } from "recoil";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { v4 as uuid } from "uuid";
import { CertificateIDValidationLanguageData } from "../../constants/Language/Language";
import { firebaseCertificationIDs } from "../../recoil/dataHooks";
import { dateConverter, isEmptyObject } from "../../utils/general";
import { certificationIDRecoil } from "../../recoil/appState";
import { Loading } from "../Loading/Loading";

const useStyles = makeStyles(() => ({
  button: {
    textTransform: "none",
  },
  textLine: {
    display: "flex",
  },
  buttonCss: {
    paddingTop: 20,
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
  text: {
    margin: "0 5px 0 5px",
  },
  textField: { "& .MuiFormLabel-root.Mui-Focused": { color: "black" } },
  table: {},
  title: { paddingTop: 20 },
}));

export interface CertificateIDDisplayProps {
  display: boolean;
}

export const CertificateIDDisplay = ({
  display,
}: CertificateIDDisplayProps): JSX.Element => {
  const classes = useStyles();
  const text = CertificateIDValidationLanguageData();
  const certificationIDsData = useRecoilValue(firebaseCertificationIDs);
  const certificationIDRecoilValue = useRecoilValue(certificationIDRecoil);

  return (
    <React.Fragment>
      {display ? (
        !isEmptyObject(certificationIDsData) ? (
          <React.Fragment>
            <Typography className={classes.title} variant="h5">
              {text.titleForCertificate}
            </Typography>
            <TableContainer>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow key={uuid()}>
                    <TableCell>{text.courseName}</TableCell>
                    <TableCell>{certificationIDsData.courseName}</TableCell>
                  </TableRow>
                  <TableRow key={uuid()}>
                    <TableCell>{text.certificateCode}</TableCell>
                    <TableCell>{certificationIDRecoilValue}</TableCell>
                  </TableRow>
                  <TableRow key={uuid()}>
                    <TableCell>{text.firstName}</TableCell>
                    <TableCell>{certificationIDsData.firstname}</TableCell>
                  </TableRow>
                  <TableRow key={uuid()}>
                    <TableCell>{text.lastName}</TableCell>
                    <TableCell>{certificationIDsData.lastname}</TableCell>
                  </TableRow>
                  <TableRow key={uuid()}>
                    <TableCell>{text.certificationEarnedDate}</TableCell>
                    <TableCell>
                      {typeof certificationIDsData.certificationDate !==
                        "undefined" &&
                      certificationIDsData.certificationDate !== ""
                        ? dateConverter(certificationIDsData.certificationDate)
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow key={uuid()}>
                    <TableCell>{text.ValidUntilDate}</TableCell>
                    <TableCell>
                      {typeof certificationIDsData.certificationDate !==
                      "undefined"
                        ? dateConverter(
                            certificationIDsData.certificationExpiryDate
                          )
                        : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        ) : (
          <Loading />
        )
      ) : null}
    </React.Fragment>
  );
};
