import React from "react";
import { PageNameWrapper } from "../../components/Cards/CenteredPage/PageNameWrapper";
import { ForgotPasswordCore } from "../../components/ForgotPassword/ForgotPasswordCore";
import { forgotPasswordTitle } from "../commonViewConstants";

export const ForgotPasswordView = (): JSX.Element => {
  return (
    <PageNameWrapper title={forgotPasswordTitle}>
      <ForgotPasswordCore />
    </PageNameWrapper>
  );
};
