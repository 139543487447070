import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useRecoilState } from "recoil";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  registerPrivacyPolicyChecked,
  registerContractTermsChecked,
} from "../../../recoil/dataHooks";
import { registrationTOSToggle } from "../../../recoil/appState";
import { TermsOfServiceDialog } from "../TermsOfService/TermsOfServiceDialog";
import {
  privacyPolicyLanguageData,
  RegistrationCheckBoxInputsLanguageData,
  termsOfServiceLanguageData,
} from "../../../constants/Language/Language";

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(1),
  },
  button: {
    textTransform: "none",
    width: "35em",
    marginRight: theme.spacing(0.5),
  },
}));
export const RegistrationCheckboxInputs = (): JSX.Element => {
  const classes = useStyles();
  const privacyPolicyText = privacyPolicyLanguageData();
  const tosText = termsOfServiceLanguageData();
  const [dialogTitle, setDialogTitle] = useState(
    privacyPolicyText.privacyPolicyTitle
  );
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useRecoilState(
    registerPrivacyPolicyChecked
  );
  const [contractTermsChecked, setContractTermsChecked] = useRecoilState(
    registerContractTermsChecked
  );
  const [TOSToggle, setTOSToggle] = useRecoilState(registrationTOSToggle);
  const handlePrivacyPolicy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrivacyPolicyChecked(event.target.checked);
  };
  const handleContractTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContractTermsChecked(event.target.checked);
  };

  const handlePrivacyPolicyButtonPress = () => {
    setDialogTitle(privacyPolicyText.privacyPolicyTitle);
    setTOSToggle(true);
  };
  const handleContractTermsButtonPress = () => {
    setDialogTitle(tosText.termsOfServiceTitle);
    setTOSToggle(true);
  };
  const text = RegistrationCheckBoxInputsLanguageData();

  return (
    <React.Fragment>
      <TermsOfServiceDialog
        title={dialogTitle}
        bodyText={text.termsOfService}
        open={TOSToggle}
        setOpen={setTOSToggle}
      />
      <Box className={classes.buttonRow}>
        <FormControlLabel
          control={
            <Checkbox
              checked={privacyPolicyChecked}
              onChange={handlePrivacyPolicy}
              name="privacyPolicy"
              color="primary"
            />
          }
          label={
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => handlePrivacyPolicyButtonPress()}
            >
              {text.readPrivacyPolicy}
            </Button>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={contractTermsChecked}
              onChange={handleContractTerms}
              name="contractTerms"
              color="primary"
            />
          }
          label={
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => handleContractTermsButtonPress()}
            >
              {text.readContractTerms}
            </Button>
          }
        />
      </Box>
    </React.Fragment>
  );
};
