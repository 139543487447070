import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { routeCodes } from "../../constants/routes";
import { auth } from "../../firebase/firebase";

export const PublicRoute = ({ children, ...rest }: RouteProps): JSX.Element => {
  const user = auth.currentUser;

  return (
    <Route
      {...rest}
      render={({ location }: any) => {
        if (!user) {
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: routeCodes.LOGINREGISTRATION.route,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};
