import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Typography from "@material-ui/core/Typography";
import { useSetRecoilState } from "recoil";
import {
  registerContractTermsChecked,
  registerPrivacyPolicyChecked,
} from "../../../recoil/dataHooks";
import {
  privacyPolicyLanguageData,
  TermsOfServiceDialogLanguageData,
  termsOfServiceLanguageData,
} from "../../../constants/Language/Language";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { registrationTOSToggle } from "../../../recoil/appState";
import { Markup } from "interweave";

interface Props {
  title: string;
  bodyText: string;
  open: boolean;
  setOpen: (val: boolean) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    actionButtonContent: {
      marginBottom: theme.spacing(1),
    },
    button: {
      textTransform: "none",
      margin: theme.spacing(0.5),
      float: "right",
    },
    consentBannerText: {
      margin: 10,
      marginTop: "1em",
      fontFamily: `${theme.typography.fontFamily} !important`,
    },
  })
);

export const TermsOfServiceDialog = ({
  title,
  bodyText,
  open,
  setOpen,
}: Props): JSX.Element => {
  const classes = useStyles();
  const privacyPolicyText = privacyPolicyLanguageData();
  const tosText = termsOfServiceLanguageData();
  const setTOSToggle = useSetRecoilState(registrationTOSToggle);
  const TOSContent = {
    [privacyPolicyText.privacyPolicyTitle]: privacyPolicyText.privacyPolicyText,
    [tosText.termsOfServiceTitle]: tosText.termsOfServiceText,
  };
  const setPrivacyPolicyChecked = useSetRecoilState(
    registerPrivacyPolicyChecked
  );
  const setContractTermsChecked = useSetRecoilState(
    registerContractTermsChecked
  );
  const handleClose = () => {
    title === privacyPolicyText.privacyPolicyTitle
      ? setPrivacyPolicyChecked(false)
      : setContractTermsChecked(false);
    setOpen(false);
  };
  const handleConfirm = () => {
    title === privacyPolicyText.privacyPolicyTitle
      ? setPrivacyPolicyChecked(true)
      : setContractTermsChecked(true);
    setOpen(false);
  };
  const text = TermsOfServiceDialogLanguageData();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {title}

        <IconButton
          color="primary"
          aria-label="close"
          component="span"
          className={classes.button}
          onClick={() => {
            setTOSToggle(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">{bodyText}</Typography>
        </DialogContentText>

        <Typography className={classes.consentBannerText} variant="body1">
          <Markup
            content={TOSContent[title] ? TOSContent[title] : text.couldntLoad}
          />
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actionButtonContent}>
        <Button
          className={classes.button}
          onClick={handleClose}
          color="secondary"
        >
          <Typography variant="body1">{text.decline}</Typography>
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <Typography variant="body1">{text.accept}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
