import React, { useState, KeyboardEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BasicCard } from "../Cards/BasicCard";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { LicenseLanguageData } from "../../constants/Language/Language";
import { ErrorTypes } from "../ErrorText/constants/constants";
import { ErrorText } from "../ErrorText/ErrorTextCore";
import Grid from "@material-ui/core/Grid";
import { ShopLink } from "../ShopLink/ShopLink";
import { 
  licenseDialogToggle,
} from "../../recoil/appState";
import { useRecoilState, useRecoilValue } from "recoil";
import { LicenseDialog } from "./LicenseDialog";
import { NewLicenseDialog } from "./NewLicenseDialog";
import { auth, db } from "../../firebase/firebase";
import { routeCodes } from "../../constants/routes";
import { useHistory } from "react-router";
import { setFireStoreValue } from "../../firebase/Data/dataReadWrite";
import "firebase/firestore";
import FormControl from "@mui/material/FormControl";
import { FieldValue, TrainingLicenses } from "../../firebase/types";
import { firebaseUsers } from "../../recoil/dataHooks";
import {
  getRemainingDays,
  hasExpiredLicense,
  isEmptyObject,
  isExpiredTrainingLicenseValidityEnd,
  isExpiredValidityEnd,
} from "../../utils/general";
import {
  fetchProductInformation,
  fetchTrainingLicenses,
  isSameLicenseCode,
} from "../../utils/fetchData";
import { 
  CertificationStatus,
  BULK_LICENSES_THRESHOLD, 
  BRAND_SD,
} from "../../constants/appConstants";

/**
 * We allow 10% (rounded down) more licenses than ordered to be redeemed on any bulk license
 */
const BULK_LICENSE_TOLERANCE_PERCT = 10;

const useStyles = makeStyles((theme) => ({
  card: {
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1),
    paddingLeft: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100",
    },
    [theme.breakpoints.up("md")]: {
      width: "800px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "800px",
    },
  },
  button: {
    textTransform: "none",
    marginTop: "0px",
  },
  textField: { "& .MuiFormLabel-root.Mui-Focused": { color: "black" } },
  cardLeft: {
    width: "100%",
    color: theme.palette.text.secondary,
  },
  cardRight: {
    color: theme.palette.text.secondary,
  },
}));

export const LicenseCore = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const [licensesCode, setLicensesCode] = useState("");
  const [validityEndInDays, setValidityEndInDays] = useState(0);
  const [licensesRedeemed, setLicensesRedeemed] = useState(false);
  const [dialogToggle, setDialogToggle] = useRecoilState(licenseDialogToggle);
  const [newLicenseDialogToggle, setNewLicenseDialogToggle] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [errorType, setErrorType] = useState<ErrorTypes>("license");
  const userData = useRecoilValue(firebaseUsers);
  const user = auth.currentUser;

  if (user === null) {
    return <p>No user</p>;
  }

  const updateUserLicense = async (productId: string, licenseCode: string) => {
    if (licenseCode) {
      // SD0-258: remove once validityEnd is on trainingLicenses.
      const productFromFB = await fetchProductInformation(productId);
      const trainingLicenseData = await fetchTrainingLicenses(licenseCode);
      const examAttemptsRemainingValue = Infinity;
      const validityEnd =
        trainingLicenseData.validityEnd ?? productFromFB.validityEnd;
      const licenseBrand =
        trainingLicenseData.brand ?? BRAND_SD;
      const licenseTrainingObject = {
        examAttemptsRemaining: examAttemptsRemainingValue,
        firstname: "",
        lastname: "",
        currentVideoIndex: 0,
        score: "102",
        productId,
        validityEnd,
        examState: CertificationStatus.NOT_IN_PROGRESS,
        redeemedDate: FieldValue.serverTimestamp(),
        examInProgress: {
          currentExamQuestions: [],
          lastAnsweredQuestionIndex: 0,
          numCorrectAnswers: 0,
        },
      };

      if (user.uid && validityEnd) {
        db.collection("users")
          .doc(user.uid.toString())
          .collection("licensedTraining")
          .doc(licenseCode)
          .set(licenseTrainingObject, { merge: true })
          .then(() => {
            setFireStoreValue({
              collection: "users",
              documentID: user.uid,
              keyPairObjectToUpdate: {
                currentCourse: FieldValue.delete(),
                currentLicense: { code: licenseCode, productId, brand: licenseBrand },
              },
              mergeFlag: true,
            });
            setTimeout(() => history.push(routeCodes.TOPICSELECTION.route), 350);
          })
          .catch((error: any) => {
            console.error(error);
          });
      }
    }
  };

  const setTrainingLicensesFireStore = (
    licenseCode: string,
    isBulkLicenses = false,
    usedLicenses = 0
  ) => {
    const objectToStore = isBulkLicenses
      ? {
          usedLicenses,
          assignedUsers: FieldValue.arrayUnion(user.uid),
        }
      : { assignedUser: user.uid };

    setFireStoreValue({
      collection: "trainingLicenses",
      documentID: licenseCode,
      keyPairObjectToUpdate: objectToStore,
      mergeFlag: true,
    });
  };

  const assignLicenseToUser = async (
    licenseCode: string,
    trainingLicensesData?: TrainingLicenses
  ) => {
    if (!trainingLicensesData) {
      trainingLicensesData = await fetchTrainingLicenses(licenseCode);
    }

    const numOfLicenses: number = trainingLicensesData.numberOfLicenses;

    if (numOfLicenses > BULK_LICENSES_THRESHOLD) {
      let usedLicenses: number = trainingLicensesData.usedLicenses ?? 0;

      /**
       * apply calculation for bulk license tolerance
       */
      const toleranceFactor = 1 + BULK_LICENSE_TOLERANCE_PERCT / 100;
      const numOfLicensesWithTolerance = Math.floor(
        numOfLicenses * toleranceFactor
      );

      const availableLicenses: number =
        numOfLicensesWithTolerance - usedLicenses;

      if (availableLicenses > 0) {
        usedLicenses++;
        setTrainingLicensesFireStore(licenseCode, true, usedLicenses);

        if (trainingLicensesData?.productId) {
          updateUserLicense(trainingLicensesData.productId, licenseCode);
        }

        setLicensesRedeemed(true);
        return;
      } else {
        setErrorType("bulkLicenseAlreadyAllRedeemed");
        setErrorDisplay(true);
        return;
      }
    } else if (!trainingLicensesData?.assignedUser) {
      setTrainingLicensesFireStore(licenseCode);

      if (trainingLicensesData?.productId) {
        updateUserLicense(trainingLicensesData.productId, licenseCode);
      }

      setLicensesRedeemed(true);
      return;
    } else {
      setErrorType("licenseAlreadyRedeemed");
      setErrorDisplay(true);
    }
  };

  const checkLicense = async () => {
    setErrorDisplay(false);

    if (licensesCode === "") {
      setErrorType("empty");
      setErrorDisplay(true);
      return;
    }

    const licenseCodeSpacesRemoved = licensesCode.replace(/ /g, "");
    const trainingLicensesData = await fetchTrainingLicenses(
      licenseCodeSpacesRemoved
    );

    if (isEmptyObject(trainingLicensesData)) {
      setErrorType("licenseNotFound");
      setErrorDisplay(true);
      return;
    } else {
      // SD0-258 - once everything has been migrated, we need to use `trainingLicensesData.validityEnd` check only.
      const expriredLicense = trainingLicensesData.validityEnd
        ? await isExpiredTrainingLicenseValidityEnd(
            trainingLicensesData.validityEnd
          )
        : await isExpiredValidityEnd(trainingLicensesData.productId);

      if (expriredLicense) {
        setErrorType("licenseExpired");
        setErrorDisplay(true);
        return;
      }

      if (
        await isSameLicenseCode(user.uid, userData, licenseCodeSpacesRemoved)
      ) {
        setErrorType("licenseAlreadyAssignedToUser");
        setErrorDisplay(true);
        return;
      }

      // SD0-258: remove '?' and `??` once everything has been migrated.
      const currentLicenseExpired = await hasExpiredLicense(
        userData.currentLicense?.code,
        userData.currentLicense?.productId ?? userData.currentCourse
      );

      // Check if user has a valid license assigned
      if (
        (userData.currentCourse || !isEmptyObject(userData.currentLicense)) &&
        !currentLicenseExpired
      ) {
        // SD0-258: remove default `trainingLicensesData` once currentLicense is on every user.
        const currentTrainingLicense = userData.currentLicense
          ? await fetchTrainingLicenses(userData.currentLicense.code)
          : trainingLicensesData;

        // SD0-258: remove once validityEnd is on trainingLicenses.
        const productFromFB = await fetchProductInformation(
          currentTrainingLicense.productId
        );

        // SD0-258: userData.currentCourse = old user, so use productFromFB.validityEnd
        const validityEnd = userData.currentCourse
          ? productFromFB.validityEnd
          : currentTrainingLicense.validityEnd ?? productFromFB.validityEnd;

        const validityEndInDays = getRemainingDays(validityEnd.toDate());

        setValidityEndInDays(validityEndInDays);
        setNewLicenseDialogToggle(true);
        return;
      }

      await assignLicenseToUser(licenseCodeSpacesRemoved, trainingLicensesData);
    }

    if (licensesRedeemed) {
      setTrainingLicensesFireStore(licenseCodeSpacesRemoved);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLicensesCode(event.target.value);
  };

  const handleButtonPress = () => {
    checkLicense();
  };

  const handleKeyboardEvent = (event: KeyboardEvent<HTMLImageElement>) => {
    if (event.key === "Enter") {
      handleButtonPress();
    }
  };

  const handleDialogClose = () => {
    setNewLicenseDialogToggle(false);
    setLicensesCode("");
  };

  const handleDialogConfirm = async () => {
    setNewLicenseDialogToggle(false);
    const licenseCodeSpacesRemoved = licensesCode.replace(/ /g, "");
    await assignLicenseToUser(licenseCodeSpacesRemoved);
  };

  const text = LicenseLanguageData();

  return (
    <BasicCard title={text.title} subText={text.subtitle}>
      <LicenseDialog open={dialogToggle} setOpen={setDialogToggle} />
      <NewLicenseDialog
        open={newLicenseDialogToggle}
        totalDays={validityEndInDays}
        handleConfirm={handleDialogConfirm}
        handleClose={handleDialogClose}
      />
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        className={classes.card}
      >
        <Grid item xs={12} sm={12} lg={6} className={classes.cardLeft}>
          <FormControl onKeyPress={handleKeyboardEvent}>
            <TextField
              className={classes.textField}
              id="outlined-basic"
              label={text.enterCode}
              placeholder="xxxxxxxx"
              value={licensesCode}
              onChange={handleChange}
              variant="outlined"
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} lg={6} className={classes.cardRight}>
          <ShopLink />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              handleButtonPress();
            }}
          >
            {text.goButton}
          </Button>
        </Grid>
        <ErrorText display={errorDisplay} errorMessage={errorType} />
      </Grid>
    </BasicCard>
  );
};
