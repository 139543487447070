import React, { Fragment, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Link from "@material-ui/core/Link";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import format from "string-template";
import { CertificationStatus } from "../../constants/appConstants";
import { routeCodes } from "../../constants/routes";
import { auth, firebase } from "../../firebase/firebase";
import { testState, videoPlayingState } from "../../recoil/appState";
import {
  firebaseUsers,
  firebaseUserTrainingData,
  useAppResetState,
} from "../../recoil/dataHooks";
import {
  fetchUserInformation,
  fetchUserTrainingInformation,
} from "../../utils/fetchData";
import { isEmptyObject } from "../../utils/general";
import { ControlSelectorLanguageData } from "../../constants/Language/Language";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 5px",
  },
  button: {
    color: theme.palette.common.black,
    fontWeight: 600,
    margin: "0 5px",
    textTransform: "none",
    transition: "opacity 200ms",

    "&:hover": {
      opacity: 0.4,
    },
  },
}));

export const TopBarMenu = (): JSX.Element | null => {
  const classes = useStyles();
  const history = useHistory();
  const text = ControlSelectorLanguageData();
  const resetRecoilState = useAppResetState();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const setVideoPlaying = useSetRecoilState(videoPlayingState);
  const certificationState = useRecoilValue(testState);

  const userIn = auth.currentUser;
  const [user, setUser] = useState<firebase.User>();
  const [initializing, setInitializing] = useState(true);
  const [userData, setUserData] = useRecoilState(firebaseUsers);
  const [userDataTrainingData, setUserDataTrainingData] = useRecoilState(
    firebaseUserTrainingData
  );

  const fetchUserTrainingData = async (courseID: string, userID: string) => {
    const userLicensedTraining = await fetchUserTrainingInformation(
      courseID,
      userID
    );

    if (isEmptyObject(userLicensedTraining)) {
      console.warn("Could not load licensedTraining");
      console.warn(`With the following course ID : ${courseID}`);
    } else {
      setUserDataTrainingData(userLicensedTraining);
    }
  };

  const fetchUser = async (userID: string) => {
    const users = await fetchUserInformation(userID);

    if (isEmptyObject(users)) {
      console.warn("Could not load users");
    } else {
      setUserData(users);
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        fetchUser(user.uid);
        if (initializing) setInitializing(false);
      }
    });
    return () => unsubscribe();
  }, [userIn]);

  useEffect(() => {
    if (user && !isEmptyObject(userData)) {
      fetchUserTrainingData(
        userData.currentLicense?.code ?? userData.currentCourse,
        user.uid
      );
    }
  }, [userData]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        resetRecoilState();
        history.push(routeCodes.LOGINREGISTRATION.route);
      })
      .catch((error) => {
        console.error(error);
      });
      const currLoc = window.location.href;
      window.location.href = currLoc;
  };

  if (initializing) {
    return null;
  }

  return (
    <div className={classes.root}>
      {isEmptyObject(userData) ? null : (
        <Fragment>
          <Link
            aria-controls="simple-menu"
            aria-haspopup="true"
            component="button"
            variant="subtitle1"
            underline="none"
            className={classes.button}
            onClick={(e) => handleClick(e)}
          >
            {text.myAccount}
          </Link>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem disabled>
              {format(text.welcome, {
                name: isEmptyObject(userDataTrainingData)
                  ? userIn?.email
                  : userDataTrainingData.firstname !== ""
                  ? userDataTrainingData.firstname
                  : userIn?.email,
              })}
            </MenuItem>
            <MenuItem
              disabled={certificationState === CertificationStatus.COMPLETE}
              onClick={() => {
                setVideoPlaying(false);
                history.push(routeCodes.MYACCOUNT.route);
              }}
            >
              {text.myAccount}
            </MenuItem>
            <MenuItem onClick={() => handleLogout()}>{text.logout}</MenuItem>
          </Menu>
        </Fragment>
      )}
    </div>
  );
};
