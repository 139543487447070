import { routeCodes } from "../../../constants/routes";
import { ErrorMessagesLanguageDataInterface } from "../../../constants/Language/Language";

export type ErrorTypes =
  | "wrongPassword"
  | "passwordReset"
  | "activated"
  | "license"
  | "licenseExpired"
  | "licenseNotFound"
  | "licenseAlreadyRedeemed"
  | "bulkLicenseAlreadyAllRedeemed"
  | "licenseAlreadyAssignedToUser"
  | "passwordMismatch"
  | "passwordTooShort"
  | "passwordNoLower"
  | "passwordNoUpper"
  | "passwordNoNumber"
  | "passwordNoSpecial"
  | "emailExists"
  | "nonValidEmail"
  | "nonValidPassword"
  | "policyUnchecked"
  | "termsUnchecked"
  | "empty"
  | "dynamicError"
  | "certificationIDNotValid"
  | "certificationIDNotFound"
  | "general";

export interface errorMessage {
  text: string;
  link?: string;
  linkWord?: string;
}

export interface getErrorInterface {
  errorType: ErrorTypes;
  dynamicError?: string;
  errorTexts: ErrorMessagesLanguageDataInterface;
}

export const getError = ({
  errorTexts,
  errorType,
  dynamicError,
}: getErrorInterface): errorMessage => {
  switch (errorType) {
    case "wrongPassword":
      return {
        text: errorTexts.wrongPassword,
      };
    case "passwordReset":
      return {
        text: errorTexts.passwordReset,
        link: routeCodes.FORGOTPASSWORD.route,
        linkWord: "Reset Password",
      };
    case "activated":
      return {
        text: errorTexts.activated,
        link: routeCodes.REGISTRATIONPENDING.route,
        linkWord: "Keine Email erhalten?",
      };
    case "passwordMismatch":
      return {
        text: errorTexts.passwordMismatch,
      };
    case "passwordTooShort":
      return {
        text: errorTexts.passwordTooShort,
      };
    case "passwordNoLower":
      return {
        text: errorTexts.passwordNoLower,
      };
    case "passwordNoUpper":
      return {
        text: errorTexts.passwordNoUpper,
      };
    case "passwordNoNumber":
      return {
        text: errorTexts.passwordNoNumber,
      };
    case "passwordNoSpecial":
      return {
        text: errorTexts.passwordNoSpecial,
      };
    case "emailExists":
      return {
        text: errorTexts.emailExists,
      };
    case "nonValidEmail":
      return {
        text: errorTexts.nonValidEmail,
      };
    case "nonValidPassword":
      return {
        text: errorTexts.nonValidPassword,
      };
    case "license":
      return {
        text: errorTexts.license,
      };
    case "policyUnchecked":
      return {
        text: errorTexts.policyUnchecked,
      };
    case "termsUnchecked":
      return {
        text: errorTexts.termsUnchecked,
      };
    case "empty":
      return {
        text: errorTexts.empty,
      };
    case "dynamicError":
      if (!dynamicError) {
        return {
          text: errorTexts.dynamicError,
        };
      }
      return {
        text: dynamicError,
      };
    case "licenseAlreadyRedeemed":
      return {
        text: errorTexts.licenseAlreadyRedeemed,
      };
    case "bulkLicenseAlreadyAllRedeemed":
      return {
        text: errorTexts.bulkLicenseAlreadyAllRedeemed,
      };
    case "licenseAlreadyAssignedToUser":
      return {
        text: errorTexts.licenseAlreadyAssignedToUser,
      };
    case "licenseNotFound":
      return {
        text: errorTexts.licenseNotFound,
      };
    case "licenseExpired":
      return {
        text: errorTexts.licenseExpired,
      };
    case "certificationIDNotValid":
      return {
        text: errorTexts.certificationIDNotValid,
      };
    case "certificationIDNotFound":
      return {
        text: errorTexts.certificationIDNotFound,
      };
    case "general":
      return {
        text: errorTexts.general,
      };
    default:
      return {
        text: errorTexts.default,
        link: routeCodes.LOGIN.route,
        linkWord: "Link",
      };
  }
};
