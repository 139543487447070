import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { ShopLinkLanguageData } from "../../constants/Language/Language";

const useStyles = makeStyles({
  blackText: {
    color: "black",
  },
});

export const ShopLink = (): JSX.Element => {
  const classes = useStyles();
  const text = ShopLinkLanguageData();

  return (
    <React.Fragment>
      <Typography variant="h5" className={classes.blackText}>
        {text.shopLinkText}
      </Typography>
      <Typography>
        <Link
          href="https://shop.schutz.digital"
          target="_blank"
          rel="noreferrer"
          underline="hover"
        >
          https://shop.schutz.digital
        </Link>
      </Typography>
    </React.Fragment>
  );
};
