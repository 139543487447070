import React, { Fragment, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { Fab, Grid, Typography, useMediaQuery } from "@material-ui/core";
import {
  ArrowBackRounded as ArrowBackRoundedIcon,
  ArrowForwardRounded as ArrowForwardRoundedIcon,
  PlayArrowRounded as PlayArrowRoundedIcon,
} from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import { Topics } from "../../firebase/types";
import { theme } from "../../constants/theme";
import { routeCodes } from "../../constants/routes";
import { 
  topicIndexState,
  isFromTopicSelectorState
 } from "../../recoil/appState";
import { firebaseProducts } from "../../recoil/dataHooks";
import { convertDurationToMinutes, isEmptyObject } from "../../utils/general";
import { TopicLanguageData } from "../../constants/Language/Language";

const useStyles = makeStyles({
  topicContainer: {
    position: "relative",
    left: "1.75rem",
    height: 235,
    width: "60%",
    color: theme.palette.common.white,

    [theme.breakpoints.down("sm")]: {
      top: "2rem",
      left: "0.75rem",
      alignContent: "flex-start",
      height: 200,
      width: "12rem",
    },
    [theme.breakpoints.up("md")]: {
      top: "3.5rem",
      width: "25rem",
    },
    [theme.breakpoints.up("lg")]: {
      top: "4rem",
      width: "30rem",
    },

    "@media (orientation: landscape) and (hover: none)": {
      top: "2rem",
      left: "0.5rem",
      width: "13.5rem",
    },
  },
  topicTitle: {
    fontSize: 32,
    fontWeight: 600,
    height: 44,
    lineHeight: 1,
    width: "85%",
    margin: "0 auto",

    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      height: 36,
    },
  },
  topicText: {
    fontWeight: 600,

    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  button: {
    "&:disabled": {
      backgroundColor: "#C7D0CF",
    },

    "&:hover": {
      backgroundColor: "#ccc",
    },

    "@media (hover: none)": {
      "&:hover": {
        backgroundColor: "#728987",

        "&.Mui-disabled": {
          backgroundColor: "#C7D0CF",
        },
      },
    },

    [theme.breakpoints.down("sm")]: {
      width: 28,
      height: 28,
      minHeight: 20,
    },
  },
  skeleton: {
    width: "100%",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      height: 100,
    },
  },
});

export const TopicSelector = (): JSX.Element => {
  const setIsFromTopicSelector = useSetRecoilState(isFromTopicSelectorState);
  const classes = useStyles();
  const history = useHistory();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const product = useRecoilValue(firebaseProducts);
  const setTopicIndex = useSetRecoilState(topicIndexState);

  const [data, setData] = useState({
    currentTopic: {} as Topics,
    currentTopicIndex: 0,
  });

  useEffect(() => {
    if (product.topics) {
      setTopicIndex(0);
      setData({ ...data, currentTopic: product.topics[0] });
    }
  }, [product]);

  const updateData = (index: number) => {
    setTopicIndex(index);
    setData({
      currentTopic: product?.topics?.[index] ?? ({} as Topics),
      currentTopicIndex: index,
    });
  };

  const handlePreviousTopic = () => {
    const index = Math.max(0, data.currentTopicIndex - 1);

    if (product?.topics) {
      updateData(index);
    }
  };

  const handleNextTopic = () => {
    if (product?.topics) {
      const index = Math.min(
        product.topics.length - 1,
        data.currentTopicIndex + 1
      );

      updateData(index);
    }
  };

  let numOfChapters = 1;
  let topicDuration = "00:00";
  const { currentTopic, currentTopicIndex } = data;
  const text = TopicLanguageData();

  if (!isEmptyObject(currentTopic)) {
    topicDuration = convertDurationToMinutes(currentTopic.topicDurationSec);
    numOfChapters =
      parseInt(currentTopic.lastLessonIndex) -
      parseInt(currentTopic.firstLessonIndex) +
      1;
  }

  return (
    <Grid
      item
      container
      md={8}
      spacing={isSmallDevice ? 0 : 3}
      className={classes.topicContainer}
    >
      {isEmptyObject(product) ? (
        <Skeleton variant="rect" className={classes.skeleton} />
      ) : isEmptyObject(currentTopic) ? (
        <Grid item xs md={12}>
          <Typography variant="h4" className={classes.topicText}>
            {text.noTopic}
          </Typography>
        </Grid>
      ) : (
        <Fragment>
          <Grid item xs={12} md={12}>
            <Typography variant="h4" className={classes.topicTitle}>
              {currentTopic?.title}
            </Typography>
          </Grid>
          <Grid item xs={2} md={1}>
            <Fab
              color="primary"
              variant="circular"
              aria-label="previous topic"
              className={classes.button}
              onClick={() => handlePreviousTopic()}
              disabled={currentTopicIndex === 0}
            >
              <ArrowBackRoundedIcon />
            </Fab>
          </Grid>
          <Grid item xs={10} md container>
            <Grid
              item
              xs={10}
              md={11}
              container
              direction="column"
              spacing={isSmallDevice ? 0 : 2}
            >
              <Grid item md>
                <Typography variant="h6" className={classes.topicText}>
                  {numOfChapters} {text.chapter}
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.topicText}
                  gutterBottom
                >
                  {topicDuration} {text.minute}
                </Typography>
              </Grid>
              <Grid item md>
                <Fab
                  color="primary"
                  variant="circular"
                  aria-label="view topic"
                  className={classes.button}
                  onClick={
                    () => { 
                      setIsFromTopicSelector(true);
                      history.push(routeCodes.VIDEOTUTORIAL.route);
                    }
                  }
                >
                  <PlayArrowRoundedIcon />
                </Fab>
              </Grid>
            </Grid>
            <Grid item xs={1} md={1}>
              <Fab
                color="primary"
                variant="circular"
                aria-label="next topic"
                className={classes.button}
                onClick={() => handleNextTopic()}
                disabled={currentTopicIndex + 1 === product?.topics?.length}
              >
                <ArrowForwardRoundedIcon />
              </Fab>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};
