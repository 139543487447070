import React from "react";
import {
  EmailOutlined as EmailOutlineIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
} from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton, Typography } from "@material-ui/core";
import { SocialSharingLanguageData } from "../../constants/Language/Language";
import { shareTo, shareViaEmail } from "../../utils/share";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  email: {
    color: theme.palette.common.black,
    "&:hover": { color: "#343434" },
  },
  facebook: {
    color: "#4267B2",
    "&:hover": { color: "#3b5998" },
  },
  twitter: {
    color: "#1da1f2",
    "&:hover": { color: "#00aced" },
  },
}));

export const SocialSharing = (): JSX.Element => {
  const classes = useStyles();
  const social = SocialSharingLanguageData();

  return (
    <Box className={classes.container}>
      <Typography variant="body1">{social.title}</Typography>

      <IconButton
        className={classes.facebook}
        onClick={() => shareTo("facebook", social.urlText, social.urlLink)}
      >
        <FacebookIcon />
      </IconButton>
      <IconButton
        className={classes.twitter}
        onClick={() => shareTo("twitter", social.urlText, social.urlLink)}
      >
        <TwitterIcon />
      </IconButton>
      <IconButton
        className={classes.email}
        onClick={() => shareViaEmail(social.emailSubject, social.emailBody)}
      >
        <EmailOutlineIcon />
      </IconButton>
    </Box>
  );
};
