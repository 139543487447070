import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router";
import { errorMessage, ErrorTypes, getError } from "./constants/constants";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { ErrorMessagesLanguageData } from "../../constants/Language/Language";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    flexDirection: "column",
  },
  errorText: {
    margin: theme.spacing(1),
    textAlign: "center",
    color: "red",
  },
  link: {
    textAlign: "center",
  },
}));

export interface ErrorTextProps {
  display: boolean;
  errorMessage: ErrorTypes;
  dynamicError?: string;
}

export const ErrorText = ({
  display,
  errorMessage,
  dynamicError,
}: ErrorTextProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const errorTexts = ErrorMessagesLanguageData();

  const createErrorMessage = (error: errorMessage) => {
    return (
      <Box className={classes.box}>
        <Typography className={classes.errorText} variant="body1">
          {error.text}
        </Typography>

        {error.link !== undefined ? (
          <Link
            variant="body1"
            className={classes.link}
            onClick={() => {
              history.push(error.link !== undefined ? error.link : "");
            }}
          >
            {error.linkWord}
          </Link>
        ) : null}
      </Box>
    );
  };
  return (
    <React.Fragment>
      {display ? (
        <React.Fragment>
          {dynamicError
            ? createErrorMessage(
                getError({
                  errorTexts: errorTexts,
                  errorType: errorMessage,
                  dynamicError: dynamicError,
                })
              )
            : createErrorMessage(
                getError({ errorTexts: errorTexts, errorType: errorMessage })
              )}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};
