import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { BasicCard } from "../Cards/BasicCard";
import { CertificateDisplayLanguageData } from "../../constants/Language/Language";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router";
import { routeCodes } from "../../constants/routes";
import { ErrorText } from "../ErrorText/ErrorTextCore";
import { ErrorTypes } from "../ErrorText/constants/constants";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  certificateInputName,
  certificateInputSurname,
  checkedValuesState,
  correctAnswerAmount,
  questionIndexState,
  trainingCourseName,
} from "../../recoil/appState";
import {
  setFireStoreCollectionInCollection,
  setFireStoreValue,
} from "../../firebase/Data/dataReadWrite";
import {
  currentUser,
  firebaseProducts,
  firebaseUsers,
  firebaseUserTrainingData,
} from "../../recoil/dataHooks";
import { Loading } from "../Loading/Loading";
import { auth, db } from "../../firebase/firebase";
import { createID } from "../../utils/generateCertificateID";
import { testState } from "../../recoil/appState";
import { CertificationStatus } from "../../constants/appConstants";
import format from "string-template";
import {
  fetchProductInformation,
  fetchUserInformation,
  fetchUserTrainingInformation,
} from "../../utils/fetchData";
import { isEmptyObject } from "../../utils/general";

const useStyles = makeStyles((theme) => ({
  column: {
    [theme.breakpoints.down("sm")]: {
      width: "100",
    },
    [theme.breakpoints.up("md")]: {
      width: "50em",
    },
    [theme.breakpoints.up("lg")]: {
      width: "50em",
    },
    textAlign: "center",
    alignContent: "center",
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  card: {
    margin: 10,
    padding: 10,
  },
  button: {
    margin: "5%",
    textTransform: "none",
  },
}));

export const CertificateInputCore = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const text = CertificateDisplayLanguageData();
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [errorType, setErrorType] = useState<ErrorTypes>("empty");

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const user = useRecoilValue(currentUser);
  const userIn = auth.currentUser;

  if (userIn === null) {
    return <p>No User</p>;
  }

  const [firstName, setFirstName] = useRecoilState(certificateInputName);
  const [lastName, setLastName] = useRecoilState(certificateInputSurname);
  const SetCorrectAnswers = useSetRecoilState(correctAnswerAmount);
  const SetCheckedValues = useSetRecoilState(checkedValuesState);
  const SetQuestionIndex = useSetRecoilState(questionIndexState);
  const courseName = useRecoilValue(trainingCourseName);

  const [product, setProduct] = useRecoilState(firebaseProducts);
  const [userData, setUserData] = useRecoilState(firebaseUsers);
  const [userDataTrainingData, setUserDataTrainingData] = useRecoilState(
    firebaseUserTrainingData
  );
  const setTestState = useSetRecoilState(testState);

  const fetchUserTrainingData = async (courseID: string, userID: string) => {
    const userLicensedTraining = await fetchUserTrainingInformation(
      courseID,
      userID
    );

    if (isEmptyObject(userLicensedTraining)) {
      console.warn("Could not load licensedTraining");
      console.warn(`With the following course ID : ${courseID}`);
    } else {
      setUserDataTrainingData(userLicensedTraining);
    }
  };

  const setExamState = (state: string) => {
    if (typeof userIn.uid !== "undefined") {
      setFireStoreCollectionInCollection({
        collection1: "users",
        collection2: "licensedTraining",
        documentID1: userIn.uid,
        documentID2: (
          userData.currentLicense?.code ?? userData.currentCourse
        ).toString(),
        keyPairObjectToUpdate: {
          examInProgress: {
            currentExamQuestions: [],
            lastAnsweredQuestionIndex: 0,
            numCorrectAnswers: 0,
          },
          examState: state,
        },
        mergeFlag: true,
      });
    }
  };

  useEffect(() => {
    if (isEmptyObject(userData)) {
      fetchUser();
    }
  }, []);

  useEffect(() => {
    if (courseName === "" || user) {
      fetchProducts(
        userData.currentLicense?.productId ?? userData.currentCourse
      );
      fetchUserTrainingData(
        userData.currentLicense?.code ?? userData.currentCourse,
        user.uid
      );
    }
  }, [userData]);

  const fetchUser = async () => {
    const users = await fetchUserInformation(userIn.uid);

    if (isEmptyObject(users)) {
      console.warn("Could not load users");
    } else {
      setUserData(users);
    }
  };

  const fetchProducts = async (courseId: string) => {
    const products = await fetchProductInformation(courseId);

    if (isEmptyObject(products)) {
      console.warn("Could not load product");
    } else {
      setProduct(products);
    }
  };

  const sendEmail = (name: string, certificationID: string) => {
    db.collection("triggered_emails")
      .doc(certificationID)
      .set({
        to: userIn.email,
        message: {
          subject: text.emailHeading,
          html: format(text.emailBodyText, {
            firstName: name,
            courseTitle: courseName === "" ? product.title : courseName,
            certificationID: certificationID,
          }),
        },
      });
  };

  const resetStates = () => {
    SetCorrectAnswers(0);
    SetCheckedValues([]);
    SetQuestionIndex(0);
  };

  const upDateCertificationTable = (
    certificationID: string,
    certificationDate: Date,
    certificationExpiryDate: Date
  ) => {
    setFireStoreValue({
      collection: "certificationIDs",
      documentID: certificationID,
      keyPairObjectToUpdate: {
        firstname: firstName,
        lastname: lastName,
        score: userDataTrainingData.score.toString(),
        certificationDate: certificationDate,
        certificationExpiryDate: certificationExpiryDate,
        courseName: courseName === "" ? product.title : courseName,
        userID: userIn.uid,
      },
      mergeFlag: true,
    });
  };

  const upDateUserCertification = (
    certificationID: string,
    certificationDate: Date,
    certificationExpiryDate: Date
  ) => {
    setFireStoreCollectionInCollection({
      collection1: "users",
      collection2: "licensedTraining",
      documentID1: userIn.uid,
      documentID2: (
        userData.currentLicense?.code ?? userData.currentCourse
      ).toString(),
      keyPairObjectToUpdate: {
        firstname: firstName,
        lastname: lastName,
        certificationDate: certificationDate,
        certificationID: certificationID,
        certificationExpiryDate: certificationExpiryDate,
      },
      mergeFlag: true,
    });
  };

  const handleButtonPress = () => {
    if (firstName === "" || lastName === "") {
      setErrorDisplay(true);
      setErrorType("empty");
    } else {
      setExamState(CertificationStatus.NOT_IN_PROGRESS);
      setTestState(CertificationStatus.NOT_IN_PROGRESS);
      const certificationID = createID(10);
      const dateAndTime = new Date();
      const certificationExpiryDate = new Date();
      certificationExpiryDate.setFullYear(
        certificationExpiryDate.getFullYear() + 1
      );

      upDateUserCertification(
        certificationID,
        dateAndTime,
        certificationExpiryDate
      );
      upDateCertificationTable(
        certificationID,
        dateAndTime,
        certificationExpiryDate
      );
      sendEmail(firstName, certificationID);
      resetStates();
      history.push(routeCodes.CERTIFICATEDISPLAY.route);
    }
  };

  if (!user) return <Loading />;

  return (
    <BasicCard title={text.title} subText={text.subtitle} center={true}>
      <div className={classes.column}>
        <form className={classes.form} noValidate autoComplete="off">
          <TextField
            id="outlined-basic"
            label={text.firstName}
            onChange={handleFirstNameChange}
            variant="outlined"
            value={firstName}
          />
          <TextField
            id="outlined-basic"
            label={text.lastName}
            onChange={handleLastNameChange}
            variant="outlined"
            value={lastName}
          />
        </form>
        <Box border={1} className={classes.card}>
          <Typography>{text.warning}</Typography>
        </Box>
        <Button
          className={classes.button}
          onClick={() => handleButtonPress()}
          variant="contained"
          color="primary"
        >
          {text.createCertificate}
        </Button>
      </div>
      <ErrorText display={errorDisplay} errorMessage={errorType} />
    </BasicCard>
  );
};
