import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { LicenseLanguageData } from "../../constants/Language/Language";

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
}

export const LicenseDialog = ({ open, setOpen }: Props): JSX.Element => {
  const handleClose = () => {
    setOpen(false);
  };
  const text = LicenseLanguageData();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle> {text.courseUpdateTitle} </DialogTitle>
      <DialogContent>
        <DialogContentText>{text.courseUpdateText}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
