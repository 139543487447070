import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import "./index.css";
import { theme } from "./constants/theme";
import reportWebVitals from "./reportWebVitals";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import { Loading } from "./components/Loading/Loading";

ReactDOM.render(
  <RecoilRoot>
    <BrowserRouter>
      {/*<React.StrictMode>*/}
      <MuiThemeProvider theme={theme}>
        <React.Suspense fallback={<Loading />}>
          <App />
        </React.Suspense>
      </MuiThemeProvider>
      {/* </React.StrictMode>*/}
    </BrowserRouter>
  </RecoilRoot>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
