import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { auth } from "../../firebase/firebase";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { DeleteMyAccountLanguageData } from "../../constants/Language/Language";
import { DeleteMyAccountDialog } from "./DeleteDialog";
import { sendDeleteMyAccountEmail } from "../../utils/emailUtils";

const useStyles = makeStyles((theme) => ({
  typography: {
    marginTop: 16,
    marginBottom: 8,
  },
  box: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  button: {
    textTransform: "none",
    marginRight: theme.spacing(0.5),
  },
}));

const Alert = (props: AlertProps): JSX.Element => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const MyAccountDelete = (): JSX.Element => {
  const classes = useStyles();
  const user = auth.currentUser;
  const text = DeleteMyAccountLanguageData();
  const [isDeleteCompleted, setIsDeleteCompleted] = useState(false);
  const [deleteDialogToggle, setDeleteDialogToggle] = useState(false);

  const handleDialogClose = () => {
    setDeleteDialogToggle(false);
  };

  const handleDialogConfirm = async () => {
    setDeleteDialogToggle(false);

    if (user) {
      await sendDeleteMyAccountEmail(user.uid);
      setIsDeleteCompleted(true);
    }
  };

  return (
    <Box mt={2}>
      <DeleteMyAccountDialog
        open={deleteDialogToggle}
        handleConfirm={handleDialogConfirm}
        handleClose={handleDialogClose}
      />
      <Grid container spacing={2}>
        {isDeleteCompleted ? (
          <Grid item>
            <Alert
              onClose={() => setIsDeleteCompleted(false)}
              severity="success"
              variant="filled"
            >
              {text.successText}
            </Alert>
          </Grid>
        ) : (
          <Grid item xs={10}>
            <Typography variant="subtitle2" className={classes.typography}>
              {text.title}
            </Typography>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              onClick={() => setDeleteDialogToggle(true)}
            >
              {text.buttonText}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
