import { useLocation } from "react-router-dom";

type MaybeString = string | undefined;

export const getPathLevel = (path: string, level: number): MaybeString => {
  const [, ...split] = path.split("/");
  if (level > split.length) {
    return undefined;
  }
  return split[level];
};

export const usePathLevel0 = (): MaybeString => {
  const { pathname } = useLocation();
  return getPathLevel(pathname, 0);
};

export const usePathLevel1 = (): MaybeString => {
  const { pathname } = useLocation();
  return getPathLevel(pathname, 1);
};

export const usePathLevel2 = (): MaybeString => {
  const { pathname } = useLocation();
  return getPathLevel(pathname, 2);
};

export const getURLOrigin = (): string => window.location.origin;

export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};
