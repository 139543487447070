import React, { Fragment, Suspense, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ReactPlayer from "react-player";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  topicIndexState,
  videoIDState,
  videoIndexState,
  videoPlayingState,
  isFromLoginState,
  isFromTopicSelectorState,
} from "../../recoil/appState";
import { Loading } from "../Loading/Loading";
import { SocialSharing } from "../SocialSharing/SocialSharing";
import { VideoTutorialVideoLanguageData } from "../../constants/Language/Language";
import {
  firebaseProducts,
  firebaseUsers,
  firebaseUserTrainingData,
} from "../../recoil/dataHooks";
import { setFireStoreCollectionInCollection } from "../../firebase/Data/dataReadWrite";
import { isEmptyObject } from "../../utils/general";
import { auth } from "../../firebase/firebase";
import { Topics } from "../../firebase/types";
import firebase from "firebase/app";
import "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  buttonRight: {
    textTransform: "none",
    marginLeft: theme.spacing(4),

    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  buttonLeft: {
    textTransform: "none",
  },
  playerWrapper: {
    backgroundColor: "black",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      height: 180,
    },
    [theme.breakpoints.up("md")]: {
      height: 380,
    },
    [theme.breakpoints.up("lg")]: {
      height: 480,
    },
    [theme.breakpoints.up("xl")]: {
      height: 520,
    },
  },
  reactPlayer: {},
  reactPlayer2: { display: "none" },
}));

export const VideoTutorialVideo = (): JSX.Element => {
  const classes = useStyles();
  const videoPlaying = useRecoilValue(videoPlayingState);
  const [videoID, setVideoID] = useRecoilState(videoIDState);
  const [videoIndex, setVideoIndex] = useRecoilState(videoIndexState);
  const [topicIndex, setTopicIndex] = useRecoilState(topicIndexState);
  const [isFirstTopicLesson, setIsFirstTopicLesson] = useState(false);
  const [isLastTopicLesson, setIsLastTopicLesson] = useState(false);
  const userDataTrainingData = useRecoilValue(firebaseUserTrainingData);
  const product = useRecoilValue(firebaseProducts);
  const userData = useRecoilValue(firebaseUsers);
  const userIn = auth.currentUser;
  const [isFromLogin, setIsFromLogin] = useRecoilState(isFromLoginState);
  const [isFromTopicSelector, setIsFromTopicSelector] = useRecoilState(isFromTopicSelectorState);

  if (userIn === null) {
    return <p>No User</p>;
  }

  let currentTopic = product?.topics?.[topicIndex] ?? ({} as Topics);

  useEffect(() => {
    if (!isEmptyObject(userDataTrainingData)) {
      if (!isEmptyObject(product) && product.topics) {
        let index = +currentTopic.firstLessonIndex;
        let lastIndex = +currentTopic.lastLessonIndex;
        const currentIndex = userDataTrainingData.currentVideoIndex;

        //saveguard against video index being incremented out of bounds (see SD11-29)
        if(currentIndex>=product.lessons.length){
          const lastLessonIndex = product.lessons.length-1;
          updateCurrentVideoIndex(lastLessonIndex);
          setVideoIndex(lastLessonIndex);
        }

        if (isFromLogin) {
          setIsFromLogin(false);
          //use currentVideoIndex
          let nextTopicIndex = topicIndex;
          while(lastIndex<currentIndex){
            ++nextTopicIndex;
            setTopicIndex(nextTopicIndex);
            currentTopic = product?.topics?.[nextTopicIndex] ?? ({} as Topics);
            lastIndex = +currentTopic.lastLessonIndex;
          }
          index = userDataTrainingData.currentVideoIndex;
        } else if (isFromTopicSelector) {
          setIsFromTopicSelector(false);
          updateCurrentVideoIndex(index);
        }
        setVideoIndex(index);
        setVideoID(product["lessons"][index]["videoUrl"]);
      } else {
        // No Topics on the product - keep old flow
        setVideoIndex(userDataTrainingData.currentVideoIndex);
        setVideoID(
          product["lessons"][userDataTrainingData.currentVideoIndex]["videoUrl"]
        );
      }
    }
  }, [userDataTrainingData]);

  const updateCurrentVideoIndex = (videoIndex: number) => {
    setFireStoreCollectionInCollection({
      collection1: "users",
      collection2: "licensedTraining",
      documentID1: userIn.uid,
      documentID2: (
        userData.currentLicense?.code ?? userData.currentCourse
      ).toString(),
      keyPairObjectToUpdate: {
        currentVideoIndex: videoIndex,
        clickedVideosList: firebase.firestore.FieldValue.arrayUnion(videoIndex)
      },
      mergeFlag: true,
    });
  };

  const handleNextVideoClick = () => {
    // TODO: Handle out of bounds
    const nextIndex = videoIndex + 1;

    if (currentTopic) {
      const { firstLessonIndex, lastLessonIndex } = currentTopic;

      if (videoIndex === +firstLessonIndex) {
        setIsFirstTopicLesson(false);
      }

      if (nextIndex === +lastLessonIndex) {
        setIsLastTopicLesson(true);
      } else if (nextIndex > +lastLessonIndex) {
        setTopicIndex(topicIndex + 1);
        setIsLastTopicLesson(false);
        setIsFirstTopicLesson(true);
      }

      // TODO: extract this to a function
      setVideoIndex(nextIndex);
      updateCurrentVideoIndex(nextIndex);
      setVideoID(product["lessons"][nextIndex]["videoUrl"]);
    } else if (product["lessons"].length - 1 > videoIndex) {
      // Old flow
      setVideoIndex(nextIndex);
      updateCurrentVideoIndex(nextIndex);
      setVideoID(product["lessons"][nextIndex]["videoUrl"]);
    }
  };

  const handlePreviousVideoClick = () => {
    // TODO: Handle out of bounds
    const previousIndex = videoIndex - 1;

    if (currentTopic) {
      const { firstLessonIndex, lastLessonIndex } = currentTopic;

      if (previousIndex < +lastLessonIndex) {
        setIsLastTopicLesson(false);
      }

      if (previousIndex === +firstLessonIndex && topicIndex !== 0) {
        setIsFirstTopicLesson(true);
      } else if (previousIndex < +firstLessonIndex) {
        setTopicIndex(topicIndex - 1);
        setIsFirstTopicLesson(false);
        setIsLastTopicLesson(true);
      }

      // TODO: extract this to a function
      setVideoIndex(videoIndex - 1);
      updateCurrentVideoIndex(videoIndex - 1);
      setVideoID(product["lessons"][videoIndex - 1]["videoUrl"]);
    } else if (videoIndex < 0) {
      setVideoIndex(videoIndex - 1);
      updateCurrentVideoIndex(videoIndex - 1);
      setVideoID(product["lessons"][videoIndex - 1]["videoUrl"]);
    }
  };

  const handleVideoEnd = () => {
    if (product["lessons"].length > videoIndex && product) {
      setVideoIndex(videoIndex + 1);
      updateCurrentVideoIndex(videoIndex);
      setVideoID(product["lessons"][videoIndex + 1]["videoUrl"]);
    }
  };

  const text = VideoTutorialVideoLanguageData();
  let nextText = isLastTopicLesson ? text.nextTopic : text.nextLecture;
  let previousText = isFirstTopicLesson
    ? text.previousTopic
    : text.previousLecture;

  if (currentTopic) {
    const { firstLessonIndex, lastLessonIndex } = currentTopic;

    if (videoIndex > +firstLessonIndex) {
      previousText = text.previousLecture;
    } else if (videoIndex === +firstLessonIndex) {
      previousText = text.previousTopic;
    }

    if (videoIndex === +lastLessonIndex) {
      nextText = text.nextTopic;
    }

    if (videoIndex === 0) {
      previousText = text.previousLecture;
      nextText = text.nextLecture;
    }
  }

  return (
    <Grid container spacing={1} alignItems="flex-start" justifyContent="center">
      {isEmptyObject(product) ? (
        <Loading />
      ) : (
        <Fragment>
          <Grid item xs={11}>
            {typeof videoID === "undefined" || videoID === "" ? (
              <Loading />
            ) : (
              <Box border={0} className={classes.playerWrapper}>
                <Suspense fallback={<Loading />}>
                  <ReactPlayer
                    key={videoID}
                    url={videoID}
                    controls={true}
                    playing={videoPlaying}
                    width={"100%"}
                    height={"100%"}
                    className={classes.reactPlayer}
                    onEnded={() => {
                      handleVideoEnd();
                    }}
                  />
                </Suspense>
              </Box>
            )}
          </Grid>
          <Grid
            container
            item
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            xs={11}
          >
            <Grid item>
              <Button
                disabled={videoIndex === 0 && topicIndex === 0}
                className={classes.buttonLeft}
                variant="contained"
                color="secondary"
                onClick={() => {
                  handlePreviousVideoClick();
                }}
              >
                {previousText}
              </Button>
            </Grid>
            <Grid item>
              <SocialSharing />
            </Grid>
            <Grid item>
              <Button
                disabled={videoIndex === product["lessons"].length - 1}
                className={classes.buttonRight}
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleNextVideoClick();
                }}
              >
                {nextText}
              </Button>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};
