import dotenv from "dotenv";
dotenv.config();

export const config = {
  apiKey: "AIzaSyC1yehGQrqHgAB--8XAjomOZz56-RURxu8",
  authDomain: "sd-training-web.firebaseapp.com",
  projectId: "sd-training-web",
  storageBucket: "sd-training-web.appspot.com",
  messagingSenderId: "570788303079",
  appId: "1:570788303079:web:14f067ec2a7aab390c9242",
};
