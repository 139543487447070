import React, { useState, KeyboardEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import FormControl from "@mui/material/FormControl";
import { BasicCard } from "../Cards/BasicCard";
import { auth, db } from "../../firebase/firebase";
import { LoginLanguageData } from "../../constants/Language/Language";
import { ShopLink } from "../ShopLink/ShopLink";
import { routeCodes } from "../../constants/routes";
import { useHistory } from "react-router";
import { ErrorText } from "../ErrorText/ErrorTextCore";
import { ErrorTypes } from "../ErrorText/constants/constants";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  questionLogInDialogToggle,
  userAuthenticated,
  userEmail,
} from "../../recoil/appState";
import firebase from "firebase/app";
import "firebase/auth";
import { buildError } from "../../utils/error";
import Box from "@material-ui/core/Box";
import { hasExpiredLicense } from "../../utils/general";

const useStyles = makeStyles((theme) => ({
  card: {
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1),
    paddingLeft: 0,
    [theme.breakpoints.down("sm")]: {
      width: "300",
    },
    [theme.breakpoints.up("md")]: {
      width: "800px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "800px",
    },
  },
  button: {
    textTransform: "none",
  },
  cardLeft: {
    color: theme.palette.text.secondary,
  },
  cardRight: {
    color: theme.palette.text.secondary,
  },
  error: {
    textAlign: "center",
    color: "red",
  },
  blackText: {
    color: "black",
  },
  consentBanner: {
    color: theme.palette.text.primary,
  },
  consentBannerContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export const Login = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const text = LoginLanguageData();
  const [password, setPassword] = useState("");
  const email = useRecoilValue(userEmail);
  const setAuthentication = useSetRecoilState(userAuthenticated);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [dynamicError, setDynamicError] = useState("");
  const [errorType, setErrorType] = useState<ErrorTypes>("passwordReset");
  const setLogInDialogToggle = useSetRecoilState(questionLogInDialogToggle);
  const handlePasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const addUser = (userID: string) => {
    db.collection("users")
      .doc(userID)
      .set({
        currentLicense: {},
        tocAccepted: true,
        privacyAccepted: true,
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setDynamicError(buildError({ code: errorCode, message: errorMessage }));
        setErrorType("dynamicError");
        setErrorDisplay(true);
      });
  };

  const checkIfUserIsEnrolled = (userIn: any): void => {
    const usersRef = db.collection("users").doc(userIn.uid);

    usersRef
      .get()
      .then((docSnapshot) => {
        if (docSnapshot.exists) {
          usersRef.onSnapshot(async (doc) => {
            const data = doc.data();

            // SD0-258 - once everything has been migrated, we need to use `data.currentLicense` check only.
            const userCourse: string = !data
              ? ""
              : data.currentLicense?.productId ?? data.currentCourse;

            const hasCourseAssigned = userCourse ? true : false;

            if (data && hasCourseAssigned) {
              const expriredLicense = await hasExpiredLicense(
                data.currentLicense?.code,
                data.currentLicense?.productId ?? userCourse
              );

              setLogInDialogToggle(true);

              if (expriredLicense) {
                history.push(routeCodes.MYACCOUNT.route);
              } else {
                history.push(routeCodes.VIDEOTUTORIAL.route);
              }
            } else {
              history.push(routeCodes.LICENSE.route);
            }
          });
        } else {
          addUser(userIn.uid);
          history.push(routeCodes.LICENSE.route);
        }
      })
      .catch((error: any) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setDynamicError(buildError({ code: errorCode, message: errorMessage }));
        setErrorType("dynamicError");
        setErrorDisplay(true);
      });
  };

  const handleButtonPress = async (password: string) => {
    setErrorDisplay(false);
    try {
      await auth
        .signInWithEmailAndPassword(email, password)
        .then(async () => {
          await auth
            .setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(async () => {
              const provider = new firebase.auth.EmailAuthProvider();
              firebase.auth().signInWithRedirect(provider);
              setAuthentication(true);
              const user = auth.currentUser;
              checkIfUserIsEnrolled(user);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              setDynamicError(
                buildError({ code: errorCode, message: errorMessage })
              );
              setErrorType("dynamicError");
              setErrorDisplay(true);
            });
        })
        .catch((error) => {
          if (error.code.includes("auth/weak-password")) {
            setErrorType("nonValidPassword");
            setErrorDisplay(true);
          } else if (error.code.includes("auth/email-already-in-use")) {
            setErrorType("emailExists");
            setErrorDisplay(true);
          } else if (error.code.includes("auth/wrong-password")) {
            setErrorType("wrongPassword");
            setErrorDisplay(true);
          } else {
            const errorCode = error.code;
            const errorMessage = error.message;
            setDynamicError(
              buildError({ code: errorCode, message: errorMessage })
            );
            setErrorType("dynamicError");
            setErrorDisplay(true);
          }
        });
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      setDynamicError(buildError({ code: errorCode, message: errorMessage }));
      setErrorType("dynamicError");
      setErrorDisplay(true);
    }
  };

  const handleKeyboardEvent = (e: KeyboardEvent<HTMLImageElement>) => {
    if (e.key === "Enter") {
      handleButtonPress(password);
    }
  };

  return (
    <BasicCard title={text.title} subText={text.subtitle}>
      <FormControl onKeyPress={handleKeyboardEvent}>
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="center"
          className={classes.card}
        >
          <Grid item xs={12} sm={12} lg={6} className={classes.cardLeft}>
            <TextField
              id="outlined-basic"
              label={text.password}
              variant="outlined"
              type="password"
              onChange={handlePasswordInput}
            />

            <Typography variant="body1">
              <Link
                component="button"
                variant="body1"
                onClick={() => {
                  history.push(routeCodes.FORGOTPASSWORD.route);
                }}
              >
                {text.forgotPassword}
              </Link>
            </Typography>

            <Box mt={1}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleButtonPress(password);
                }}
              >
                {text.buttonLogin}
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} lg={6} className={classes.cardRight}>
            <ShopLink />
          </Grid>
          <Grid item xs={12}>
            <ErrorText
              display={errorDisplay}
              errorMessage={errorType}
              dynamicError={dynamicError}
            />
          </Grid>
        </Grid>
      </FormControl>
    </BasicCard>
  );
};
