import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useRecoilState } from "recoil";
import {
  registerPasswordRepeat,
  registerPasswordAssigned,
} from "../../../recoil/dataHooks";
import { RegistrationTextInputsLanguageData } from "../../../constants/Language/Language";
import { userEmail } from "../../../recoil/appState";

const useStyles = makeStyles(() => ({
  textField: {
    margin: "10px 0",
    "& .MuiFormLabel-root.Mui-Focused": { color: "black" },
  },
}));

export const RegistrationTextInputs = (): JSX.Element => {
  const classes = useStyles();
  const [email, setEmail] = useRecoilState(userEmail);
  const [passwordAssigned, setPasswordAssigned] = useRecoilState(
    registerPasswordRepeat
  );
  const [passwordRepeat, setPasswordRepeat] = useRecoilState(
    registerPasswordAssigned
  );
  const handleEmailInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setEmail(event.target.value);
  };
  const text = RegistrationTextInputsLanguageData();
  const handlePasswordAssignedInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordAssigned(event.target.value);
  };
  const handlePasswordRepeat = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordRepeat(event.target.value);
  };
  return (
    <React.Fragment>
      <TextField
        className={classes.textField}
        id="email-address"
        label={text.email}
        placeholder="user@example.com"
        value={email}
        onChange={handleEmailInput}
        autoComplete="on"
        type="email"
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        id="password-assigned"
        label={text.assignedPassword}
        value={passwordAssigned}
        onChange={handlePasswordAssignedInput}
        autoComplete="on"
        type="password"
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        id="password-repeat"
        label={text.repeatPassword}
        value={passwordRepeat}
        onChange={handlePasswordRepeat}
        autoComplete="on"
        type="password"
        variant="outlined"
      />
    </React.Fragment>
  );
};
