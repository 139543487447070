import { atom } from "recoil";
import { ErrorTypes } from "../components/ErrorText/constants/constants";
import { CertificationStatus } from "../constants/appConstants";
import { ExamQuestions, TestState } from "../firebase/types";

export const appLoading = atom({
  key: "appLoading",
  default: true,
});

export const appPageName = atom({
  key: "appPageName",
  default: "Unknown Page",
});

export const selectedLanguagePack = atom({
  key: "selectedLanguagePack",
  default: "de",
});

// User

export const userState = atom({
  key: "userState",
  default: {} as any,
});

export const userAuthenticated = atom({
  key: "userAuthenticated",
  default: true,
});

export const userEmailVerified = atom({
  key: "userEmailVerified",
  default: false,
});

export const userEmail = atom({
  key: "userEmail",
  default: "unknown@gmail.com",
});

// Consent Banner

export const consentBannerToggle = atom({
  key: "consentBannerToggle",
  default: false,
});

// Footer

export const footerDialogTitle = atom({
  key: "footerDialogTitle",
  default: "Imprint",
});

export const footerTOSToggle = atom({
  key: "footerTOSToggle",
  default: false,
});

// Registration page

export const registrationTOSToggle = atom({
  key: "registrationTOSToggle",
  default: false,
});

export const registrationErrorToggle = atom({
  key: "registrationErrorToggle",
  default: false,
});

export const registrationDynamicError = atom({
  key: "registrationDynamicError",
  default: "",
});

export const registrationErrorMessage = atom({
  key: "registrationErrorMessage",
  default: "passwordMismatch" as ErrorTypes,
});

// License page

export const licenseDialogToggle = atom({
  key: "licenseDialogToggle",
  default: false,
});

// Video Tutorial page

export const videoPlayingState = atom({
  key: "videoPlayingState",
  default: false,
});

export const videoIDState = atom({
  key: "videoIDState",
  default: "",
});

export const videoIndexState = atom({
  key: "videoIndexState",
  default: 0,
});

//flag indicating on course page that we came from login to load current video
export const isFromLoginState = atom({
  key: "isFromLoginState",
  default: true,
});

//flag indicating on course page that we came from login to load current video
export const isFromTopicSelectorState = atom({
  key: "isFromTopicSelectorState",
  default: false,
});

export const offlineDownloadPopup = atom({
  key: "offlineDownloadPopup",
  default: false,
});

// Topic Page

export const topicIndexState = atom({
  key: "topicIndexState",
  default: 0,
});

// Test Page

export const testState = atom({
  key: "testState",
  default: CertificationStatus.NOT_IN_PROGRESS as TestState,
});

export const testPercentage = atom({
  key: "testPercentage",
  default: 0,
});

export const correctAnswerAmount = atom({
  key: "correctAnswerAmount",
  default: 0,
});

export const testPassed = atom({
  key: "testPassed",
  default: false,
});

export const checkedValuesState = atom({
  key: "checkedValuesState",
  default: [] as any,
});

export const questionIndexState = atom({
  key: "questionIndexState",
  default: 0,
});

export const questionDialogToggle = atom({
  key: "questionDialogToggle",
  default: false,
});

export const questionLogInDialogToggle = atom({
  key: "questionLogInDialogToggle",
  default: false,
});

export const TestCourseID = atom({
  key: "TestCourseID",
  default: "15479",
});

export const TestCourseNumberOfQuestions = atom({
  key: "TestCourseNumberOfQuestions",
  default: 1,
});

export const trainingCourseName = atom({
  key: "courseName",
  default: "" as string,
});
export const trainingCourseQuestions = atom({
  key: "trainingCourseQuestions",
  default: {} as ExamQuestions[],
});

// Login page

export const loginErrorType = atom({
  key: "loginErrorType",
  default: "general" as ErrorTypes,
});

export const loginErrorDisplay = atom({
  key: "loginErrorDisplay",
  default: false,
});

export const loginPassword = atom({
  key: "loginPassword",
  default: "",
});

// Certificate Input

export const certificateInputName = atom({
  key: "certificateInputName",
  default: "",
});

export const certificateInputSurname = atom({
  key: "certificateInputSurname",
  default: "",
});

// handle Reset Password

export const handleResetPasswordState = atom({
  key: "handleResetPasswordState",
  default: "enterPassword",
});

// Password Reset

export const passwordResetNewPassword = atom({
  key: "passwordResetNewPassword",
  default: "",
});

export const passwordResetNewPasswordConfirm = atom({
  key: "passwordResetNewPasswordConfirm",
  default: "",
});

// certification validation

export const certificationIDRecoil = atom({
  key: "certificationIDRecoil",
  default: "",
});
