import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import format from "string-template";
import { LicenseLanguageData } from "../../constants/Language/Language";

const useStyles = makeStyles(() =>
  createStyles({
    confirm: {
      color: "green",
    },
    close: {
      color: "red",
    },
  })
);

interface Props {
  open: boolean;
  totalDays: number;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const NewLicenseDialog = ({
  open,
  totalDays,
  handleClose,
  handleConfirm,
}: Props): JSX.Element => {
  const text = LicenseLanguageData();
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <DialogTitle>
        {format(text.newLicenseText, {
          validityDays: totalDays,
        })}
      </DialogTitle>
      <DialogContent>
        <DialogActions>
          <Button className={classes.confirm} onClick={handleConfirm}>
            {text.newLicenseAccept}
          </Button>
          <Button className={classes.close} onClick={handleClose}>
            <Typography variant="button">{text.newLicenseDecline}</Typography>
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
