import React, { CSSProperties } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { BasicCardLanguageData } from "../../constants/Language/Language";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    minWidth: "250px",
    maxHeight: "45.5em",

    [theme.breakpoints.up("sm")]: {
      maxHeight: "65.5em",
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: "45.5em",
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: "48.5em",
    },
    [theme.breakpoints.up("xl")]: {
      maxHeight: "55.5em",
    },
  },
  title: {},
  fullCard: {
    display: "flex",
    flexDirection: "column",
  },
  box: {},
}));

interface OnClickRoute {
  pathname: string;
  state?: { action?: string };
  url?: { action?: string };
}
export interface BasicCardProps {
  title?: string;
  subText?: string;
  center?: boolean;
  children?: React.ReactNode;
  onClickRoute?: OnClickRoute;
  link?: string;
  style?: CSSProperties | undefined;
  onClick?: () => boolean | Promise<boolean>;
}

export const BasicCard = ({
  title,
  subText,
  center,
  children,
  onClickRoute,
  link,
  onClick,
}: BasicCardProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const text = BasicCardLanguageData();
  return (
    <div
      style={center ? { alignContent: "center", textAlign: "center" } : {}}
      onClick={() => {
        if (onClick) {
          onClick();
        }
        if (onClickRoute) {
          history.push(onClickRoute);
        }
        if (link) {
          window.open(link);
        }
      }}
    >
      <div className={classes.fullCard}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Card className={classes.root}>
              <CardContent>
                {!title ? null : (
                  <Typography className={classes.title} variant="h5">
                    {title}
                  </Typography>
                )}
                {!subText ? null : (
                  <Typography
                    style={center ? {} : { maxWidth: "50em" }}
                    variant="subtitle1"
                  >
                    {subText}
                  </Typography>
                )}
                {children ? (
                  <Box className={classes.box}>{children}</Box>
                ) : (
                  <Typography variant="body1">
                    {text.info} {title}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
