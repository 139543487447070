import React from "react";
import { PageNameWrapper } from "../../components/Cards/CenteredPage/PageNameWrapper";
import { CertificateInputCore } from "../../components/CertificateInput/CertificateInputCore";
import { certificatePageTitle } from "../commonViewConstants";

export const CertificateInputView = (): JSX.Element => {
  return (
    <PageNameWrapper title={certificatePageTitle}>
      <CertificateInputCore />
    </PageNameWrapper>
  );
};
