import { useState, useEffect } from "react";
import { auth, firebase } from "../firebase";

export const useAuth = (): {
  isSignedIn: boolean;
  pending: boolean;
  user: firebase.User | null;
  auth: typeof auth;
} => {
  const [authState, setAuthState] = useState({
    isSignedIn: false,
    pending: true,
    user: {} as firebase.User,
  });

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      if (user !== null) {
        setAuthState({ user, pending: false, isSignedIn: !!user });
      } else {
        setAuthState({
          user: {} as firebase.User,
          pending: false,
          isSignedIn: false,
        });
      }
    });
    return () => unregisterAuthObserver();
  }, []);

  return { auth, ...authState };
};

export const isUserEmailVerified = (): boolean => {
  const user = auth.currentUser;
  if (!user) {
    return false;
  }
  auth.currentUser?.reload();
  return user.emailVerified;
};
