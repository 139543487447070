import React, { useState, KeyboardEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { BasicCard } from "../Cards/BasicCard";
import Box from "@material-ui/core/Box";
import FormControl from "@mui/material/FormControl";
import { useHistory } from "react-router";
import { routeCodes } from "../../constants/routes";
import { auth } from "../../firebase/firebase";
import { ErrorTypes } from "../ErrorText/constants/constants";
import { ErrorText } from "../ErrorText/ErrorTextCore";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { ForgotPasswordLanguageData } from "../../constants/Language/Language";
import { buildError } from "../../utils/error";

const useStyles = makeStyles((theme) => ({
  card: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "300",
    },
    [theme.breakpoints.up("md")]: {
      width: "30em",
    },
    [theme.breakpoints.up("lg")]: {
      width: "30em",
    },
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
    paddingTop: theme.spacing(1),
  },
  button: {
    textTransform: "none",
    marginRight: theme.spacing(0.5),
  },
  error: {
    textAlign: "center",
    color: "red",
  },
  textField: {
    margin: "10px 0",
    "& .MuiFormLabel-root.Mui-Focused": { color: "black" },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
  },
}));

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const ForgotPasswordCore = (): JSX.Element => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [errorType, setErrorType] = useState<ErrorTypes>("passwordReset");
  const [dynamicError, setDynamicError] = useState("");
  const history = useHistory();
  const handleEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const text = ForgotPasswordLanguageData();

  const handleSendResetEmail = (email: string) => {
    setErrorDisplay(false);
    if (typeof email !== "undefined") {
      const pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

      if (!pattern.test(email)) {
        setErrorType("nonValidEmail");
        setErrorDisplay(true);
        return;
      }
    }
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setOpen(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setDynamicError(buildError({ code: errorCode, message: errorMessage }));
        setErrorType("dynamicError");
        setErrorDisplay(true);
      });
  };

  const handleKeyboardEvent = (e: KeyboardEvent<HTMLImageElement>) => {
    if (e.key === "Enter") {
      handleSendResetEmail(email);
    }
  };

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {text.emailSent}
        </Alert>
      </Snackbar>
      <BasicCard title={text.title}>
        <FormControl onKeyPress={handleKeyboardEvent} className={classes.form}>
          <TextField
            className={classes.textField}
            id="email-address"
            label={text.emailLabel}
            placeholder="user@example.com"
            value={email}
            onChange={handleEmailInput}
            variant="outlined"
          />
          <Box className={classes.buttonRow}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(routeCodes.LOGIN.route);
              }}
            >
              {text.cancel}
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => {
                handleSendResetEmail(email);
              }}
            >
              {text.resetLink}
            </Button>
          </Box>
        </FormControl>

        <ErrorText
          display={errorDisplay}
          errorMessage={errorType}
          dynamicError={dynamicError}
        />
      </BasicCard>
    </React.Fragment>
  );
};
