import React, { useEffect, useState } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Loading } from "../../components/Loading/Loading";
import { CertificationStatus } from "../../constants/appConstants";
import { routeCodes } from "../../constants/routes";
import { firebase } from "../../firebase/firebase";
import { isUserEmailVerified, useAuth } from "../../firebase/User/useAuth";
import { userAuthenticated } from "../../recoil/appState";
import {
  firebaseUsers,
  firebaseUserTrainingData,
} from "../../recoil/dataHooks";
import {
  fetchUserInformation,
  fetchUserTrainingInformation,
} from "../../utils/fetchData";
import { isEmptyObject } from "../../utils/general";

export const ProtectedRoute = ({
  children,
  ...rest
}: RouteProps): JSX.Element => {
  const setUserAuthenticated = useSetRecoilState(userAuthenticated);
  const { pending, isSignedIn, auth } = useAuth();
  const userIn = auth.currentUser;
  const [user, setUser] = useState<firebase.User>();
  const [userData, setUserData] = useRecoilState(firebaseUsers);
  const [initializing, setInitializing] = useState(true);
  const [userDataTrainingData, setUserDataTrainingData] = useRecoilState(
    firebaseUserTrainingData
  );

  const fetchUserTrainingData = async (courseID: string, userID: string) => {
    const userLicensedTraining = await fetchUserTrainingInformation(
      courseID,
      userID
    );

    if (isEmptyObject(userLicensedTraining)) {
      console.warn("Could not load licensedTraining");
      console.warn(`With the following course ID : ${courseID}`);
    } else {
      setUserDataTrainingData(userLicensedTraining);
    }
  };

  useEffect(() => {
    if (user && !isEmptyObject(userData)) {
      fetchUserTrainingData(
        userData.currentLicense?.code ?? userData.currentCourse,
        user.uid
      );
    }
  }, [userData]);

  const fetchUser = async (userID: string) => {
    const users = await fetchUserInformation(userID);

    if (isEmptyObject(users)) {
      console.warn("Could not load users");
    } else {
      setUserData(users);
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        fetchUser(user.uid);
        if (initializing) setInitializing(false);
      }
    });
    return () => unsubscribe();
  }, [userIn]);

  if (auth.currentUser !== null) {
    auth.currentUser.reload().then(() => {
      setUserAuthenticated(true);
    });
  } else {
    setUserAuthenticated(false);
  }

  if (pending) {
    return <Loading />;
  }

  return (
    <Route
      {...rest}
      render={({ location }: any) => {
        if (isSignedIn) {
          return isUserEmailVerified() ? (
            rest.path === "/videotutorial" ? (
              userDataTrainingData.examState ===
                CertificationStatus.NOT_IN_PROGRESS ||
              typeof userDataTrainingData.examState === "undefined" ? (
                children
              ) : (
                <Redirect
                  to={{
                    pathname: routeCodes.TESTPREFACE.route,
                    state: { from: location },
                  }}
                />
              )
            ) : (
              children
            )
          ) : (
            <Redirect
              to={{
                pathname: routeCodes.REGISTRATIONPENDING.route,
                state: { from: location },
              }}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: routeCodes.LOGINREGISTRATION.route,
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};
