import React from "react";
import { PageNameWrapper } from "../../components/Cards/CenteredPage/PageNameWrapper";
import { MyAccountCore } from "../../components/MyAccount/MyAccountCore";
import { myAccountViewTitle } from "../commonViewConstants";

export const MyAccountView = (): JSX.Element => {
  return (
    <PageNameWrapper title={myAccountViewTitle}>
      <MyAccountCore />
    </PageNameWrapper>
  );
};
