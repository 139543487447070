import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { BasicCard } from "../Cards/BasicCard";
import { PasswordResetCoreLanguageData } from "../../constants/Language/Language";
//import { routeCodes } from "../../constants/routes";
//import { useHistory } from "react-router";
import "firebase/auth";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import {
  handleResetPasswordState,
  passwordResetNewPassword,
  passwordResetNewPasswordConfirm,
} from "../../recoil/appState";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ErrorText } from "../ErrorText/ErrorTextCore";
import { ErrorTypes } from "../ErrorText/constants/constants";

const useStyles = makeStyles((theme) => ({
  card: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "300",
    },
    [theme.breakpoints.up("md")]: {
      width: "500px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "500px",
    },
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(0.5),
  },
  textField: {
    margin: "10px 0",
    "& .MuiFormLabel-root.Mui-Focused": { color: "black" },
  },
  box: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const PasswordResetCard = (): JSX.Element => {
  const classes = useStyles();
  //const history = useHistory();
  const text = PasswordResetCoreLanguageData();
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [errorType, setErrorType] = useState<ErrorTypes>("empty");
  const setResetPasswordState = useSetRecoilState(handleResetPasswordState);
  const [newPassword, setNewPassword] = useRecoilState(
    passwordResetNewPassword
  );
  const [newPasswordConfirmed, setNewPasswordConfirmed] = useRecoilState(
    passwordResetNewPasswordConfirm
  );
  const handleNewPasswordInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPassword(event.target.value);
  };
  const handleNewPasswordConfirmedInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPasswordConfirmed(event.target.value);
  };

  const handleChangePasswordButtonInput = (
    newPassword: string,
    newPasswordConfirmed: string
  ) => {
    setErrorDisplay(false);
    if (newPassword !== newPasswordConfirmed) {
      setErrorDisplay(true);
      setErrorType("passwordMismatch");
      return;
    }
    if (newPassword.length < 8) {
      setErrorType("passwordTooShort");
      setErrorDisplay(true);
      return;
    }

    if (!newPassword.match(/[a-z]/)) {
      setErrorType("passwordNoLower");
      setErrorDisplay(true);
      return;
    }

    if (!newPassword.match(/[A-Z]/)) {
      setErrorType("passwordNoUpper");
      setErrorDisplay(true);
      return;
    }

    if (!/\d/.test(newPassword)) {
      setErrorType("passwordNoNumber");
      setErrorDisplay(true);
      return;
    }

    const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if (!format.test(newPassword)) {
      setErrorType("passwordNoSpecial");
      setErrorDisplay(true);
      return;
    }

    if (newPassword !== "") {
      setResetPasswordState("startCheck");
    } else {
      setErrorType("empty");
      setErrorDisplay(true);
    }
  };

  return (
    <BasicCard title={text.title} subText={text.subtitle}>
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        className={classes.card}
      >
        <Grid item xs={12}>
          <Box className={classes.box}>
            <TextField
              className={classes.textField}
              id="new-password"
              label={text.newPassword}
              value={newPassword}
              onChange={handleNewPasswordInput}
              type="password"
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              id="new-password-confirmed"
              label={text.repeatNewPassword}
              value={newPasswordConfirmed}
              onChange={handleNewPasswordConfirmedInput}
              type="password"
              variant="outlined"
            />
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => {
                handleChangePasswordButtonInput(
                  newPassword,
                  newPasswordConfirmed
                );
              }}
            >
              {text.buttonReset}
            </Button>
            <ErrorText display={errorDisplay} errorMessage={errorType} />
          </Box>
        </Grid>
      </Grid>
    </BasicCard>
  );
};
