// Low priority | WernerWildenboer
import React, { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { auth } from "../../firebase/firebase";
import {
  handleResetPasswordState,
  passwordResetNewPassword,
  userEmail,
} from "../../recoil/appState";
import { getParameterByName } from "../../utils/general";
import { EmailVerificationCore } from "../EmailVerification/EmailVerificationCore";
import { Loading } from "../Loading/Loading";
import { PasswordResetCard } from "../PasswordReset/PasswordResetCard";
import { PasswordResetSuccess } from "../PasswordReset/PasswordResetSuccess";

interface handleResetPasswordProps {
  actionCode: string;
  lang: string;
}

const handleResetPassword = ({
  actionCode,
  lang,
}: handleResetPasswordProps): JSX.Element => {
  const [resetPasswordState, setResetPasswordState] = useRecoilState(
    handleResetPasswordState
  );
  const setUserEmail = useSetRecoilState(userEmail);
  const newPassword = useRecoilValue(passwordResetNewPassword);
  // eslint-disable-next-line no-console
  console.log(lang+", resetPasswordState: "+resetPasswordState);

  // Verify the password reset code is valid.
  if (resetPasswordState === "startCheck") {
    auth
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        const accountEmail = email;
        setUserEmail(accountEmail);
        // Save the new password.
        auth
          .confirmPasswordReset(actionCode, newPassword)
          .then((resp: any) => {
            // eslint-disable-next-line no-console
            console.log(resp);
            auth.signInWithEmailAndPassword(accountEmail, newPassword);
            setResetPasswordState("passwordResetSuccessful");
          })
          .catch((error: any) => {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            console.error(error);
            setResetPasswordState("error");
          });
      })
      .catch((error: any) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        console.error(error);
        setResetPasswordState("error");
      });
  }
  switch (resetPasswordState) {
    case "enterPassword":
      return <PasswordResetCard />;
    case "passwordResetSuccessful":
      return <PasswordResetSuccess />;
    case "startCheck":
      return <Loading />;
    case "error":
      //TODO : Improve error handling | WernerWildenboer 29/09/2021
      return <p>Could not change Password</p>;
    default:
      return <p>Mode selection error, please contact website admin</p>;
  }
};

interface handleVerifyEmailProps {
  actionCode: string;
  lang: string;
}

const handleVerifyEmail = ({
  actionCode,
  lang,
}: handleVerifyEmailProps): JSX.Element => {
  const [emailVerified, setEmailVerified] = useState(false);
  const setEmail = useSetRecoilState(userEmail);
  // eslint-disable-next-line no-console
  console.log(lang);
  // Localize the UI to the selected language as determined by the lang
  // parameter.
  // Try to apply the email verification code.
  auth
    .applyActionCode(actionCode)
    .then((resp: any) => {
      //TODO : Test | WernerWildenboer 7/10/2021
      // eslint-disable-next-line no-console
      console.log(resp);

      setEmail("temp@gmail.com");
      setEmailVerified(true);
    })
    .catch((error: any) => {
      // Code is invalid or expired. Ask the user to verify their email address
      // again.
      console.error(error);
    });
  if (emailVerified) {
    return <EmailVerificationCore />;
  } else {
    //TODO : Improve error handling | WernerWildenboer 28/09/2021
    //return <p>Email Could not be verified, or verification is loading.</p>;
    return <Loading />;
  }
};

export const CustomEmailHandler = (): JSX.Element => {
  // Get the action to complete.
  const mode = getParameterByName("mode");
  // Get the one-time code from the query parameter.
  const actionCode = getParameterByName("oobCode");
  // (Optional) Get the language code if available.
  const lang = getParameterByName("lang") || "en";

  // Handle the user management action.
  if (actionCode === null || lang === null) {
    return <p>ActionCode or language error, please contact website admin</p>;
  }
  switch (mode) {
    case "resetPassword":
      return handleResetPassword({ actionCode, lang });
    /* case "recoverEmail":
        // Display email recovery handler and UI.
        handleRecoverEmail(auth, actionCode, lang);
        break;*/
    case "verifyEmail":
      return handleVerifyEmail({ actionCode, lang });
    default:
      return <p>Mode selection error, please contact website admin</p>;
  }
};
