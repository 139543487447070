import React, { useEffect } from "react";
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import {
  testState,
  testPercentage,
  correctAnswerAmount,
  checkedValuesState,
  questionIndexState,
  TestCourseNumberOfQuestions,
  trainingCourseQuestions,
  questionDialogToggle,
  questionLogInDialogToggle,
} from "../../../recoil/appState";
import {
  calculatePercentage,
  compareArrays,
  isEmptyObject,
} from "../../../utils/general";
import { setFireStoreCollectionInCollection } from "../../../firebase/Data/dataReadWrite";
import { QuestionCardLanguageData } from "../../../constants/Language/Language";
import { CertificationStatus } from "../../../constants/appConstants";
import { QuestionCardSelector } from "./QuestionCardSelector";
import { QuestionCardDialog } from "./QuestionCardDialog";
import { firebaseUsers } from "../../../recoil/dataHooks";
import { routeCodes } from "../../../constants/routes";
import { auth } from "../../../firebase/firebase";
import { Loading } from "../../Loading/Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "32em",
    },
    [theme.breakpoints.up("lg")]: {
      width: "33em",
    },
  },
  border: {
    border: "3px solid #000000",
    margin: "10px 20px 10px 20px",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  control: {
    margin: "20px",
  },
  buttonRow: {
    width: "92.5%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },

    margin: "10px 20px 20px 20px",
  },
  buttonDialog: {
    textTransform: "none",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },

  button: {
    textTransform: "none",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

export const QuestionCardCore = (): JSX.Element | null => {
  const classes = useStyles();
  const history = useHistory();
  const [dialogToggle, setDialogToggle] = useRecoilState(questionDialogToggle);
  const [logInDialogToggle, setLogInDialogToggle] = useRecoilState(
    questionLogInDialogToggle
  );
  const setTestState = useSetRecoilState(testState);
  const questionsCurrentTest = useRecoilValue(trainingCourseQuestions);
  const [checkedValues, setCheckedValues] = useRecoilState(checkedValuesState);
  const [questionIndex, setQuestionIndex] = useRecoilState(questionIndexState);
  const numberOfQuestions = useRecoilValue(TestCourseNumberOfQuestions);
  const [correctAnswers, setCorrectAnswerAmount] =
    useRecoilState(correctAnswerAmount);
  const [testPercentageState, setTestPercentage] =
    useRecoilState(testPercentage);
  const text = QuestionCardLanguageData();
  const userData = useRecoilValue(firebaseUsers);
  const userIn = auth.currentUser;
  // SD0-258
  const licenseCode = userData.currentLicense?.code ?? userData.currentCourse;

  const handleNextQuestion = async () => {
    if (
      compareArrays({
        array1: questionsCurrentTest[questionIndex].correctAnswers,
        array2: checkedValues,
      })
    ) {
      await setCorrectAnswerAmount(correctAnswers + 1);
    }

    setCheckedValues([]);
    setDialogToggle(false);
    if (questionIndex !== numberOfQuestions - 1) {
      setQuestionIndex(questionIndex + 1);
      updateCurrentQuestionAndNumCorrect(questionIndex + 1, correctAnswers);
    } else {
      updateScore(testPercentageState);
      resetExamState();
      setTimeout(() => setTestState(CertificationStatus.COMPLETE), 550);
      setCorrectAnswerAmount(0);
      setQuestionIndex(0);
    }
  };
  const handleTestAbandonButton = async () => {
    setDialogToggle(true);
  };

  const handleTestAbandon = async () => {
    setCheckedValues([]);
    setTestState(CertificationStatus.NOT_IN_PROGRESS);
    setExamState(CertificationStatus.NOT_IN_PROGRESS);
    setTimeout(() => history.push(routeCodes.VIDEOTUTORIAL.route), 650);
    setDialogToggle(false);
  };

  useEffect(() => {
    if (typeof questionsCurrentTest !== "undefined") {
      setTestPercentage(
        calculatePercentage({
          value: correctAnswers,
          totalValue: numberOfQuestions,
        })
      );
    }
  }, [correctAnswers]);

  const updateCurrentQuestionAndNumCorrect = (
    lastAnsweredQuestionIndex: number,
    numCorrectAnswers: number
  ) => {
    if (userIn !== null) {
      setFireStoreCollectionInCollection({
        collection1: "users",
        collection2: "licensedTraining",
        documentID1: userIn.uid,
        documentID2: licenseCode.toString(),
        keyPairObjectToUpdate: {
          examInProgress: {
            lastAnsweredQuestionIndex: lastAnsweredQuestionIndex,
            numCorrectAnswers: numCorrectAnswers,
          },
        },
        mergeFlag: true,
      });
    }
  };

  const updateScore = (finalGrade: number) => {
    if (userIn !== null) {
      setFireStoreCollectionInCollection({
        collection1: "users",
        collection2: "licensedTraining",
        documentID1: userIn.uid,
        documentID2: licenseCode.toString(),
        keyPairObjectToUpdate: {
          score: finalGrade.toString(),
        },
        mergeFlag: true,
      });
    }
  };

  const resetExamState = () => {
    if (userIn !== null) {
      setFireStoreCollectionInCollection({
        collection1: "users",
        collection2: "licensedTraining",
        documentID1: userIn.uid,
        documentID2: licenseCode.toString(),
        keyPairObjectToUpdate: {
          examInProgress: {
            currentExamQuestions: [],
            lastAnsweredQuestionIndex: 0,
            numCorrectAnswers: 0,
          },
          examState: CertificationStatus.COMPLETE,
        },
        mergeFlag: true,
      });
    }
  };
  const handleClose = () => {
    setDialogToggle(false);
  };
  const handleCloseLoginDialog = () => {
    setLogInDialogToggle(false);
  };

  const setExamState = (examState: string) => {
    if (userIn !== null) {
      setFireStoreCollectionInCollection({
        collection1: "users",
        collection2: "licensedTraining",
        documentID1: userIn.uid,
        documentID2: licenseCode.toString(),
        keyPairObjectToUpdate: {
          examState: examState,
        },
        mergeFlag: true,
      });
    }
  };

  if (typeof questionsCurrentTest === "undefined" && !questionsCurrentTest) {
    return null;
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      {isEmptyObject(questionsCurrentTest) ? (
        <Loading />
      ) : (
        <Grid item>
          <QuestionCardDialog
            open={logInDialogToggle}
            setOpen={handleCloseLoginDialog}
          />
          <Dialog open={dialogToggle} onClose={handleClose}>
            <DialogTitle>{text.cancelExamTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText>{text.dialogTestAbandon}</DialogContentText>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonDialog}
                  onClick={() => {
                    handleTestAbandon();
                  }}
                >
                  {text.cancelExam}
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonDialog}
                  onClick={async () => {
                    setDialogToggle(false);
                  }}
                >
                  {text.backToExam}
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>

          <Card className={classes.root}>
            <CardContent className={classes.border}>
              <QuestionCardSelector questionData={questionsCurrentTest} />
            </CardContent>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.buttonRow}
            >
              <Grid item xs={7} sm={7} md={7} lg={7}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    handleTestAbandonButton();
                  }}
                >
                  {text.testAbandon}
                </Button>
              </Grid>
              <Grid item xs={7} sm={4} md={4} lg={4}>
                <Button
                  disabled={checkedValues.length === 0}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={async () => {
                    handleNextQuestion();
                  }}
                >
                  {text.nextQuestion}
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
