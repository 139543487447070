import React from "react";
import { PageNameWrapper } from "../../components/Cards/CenteredPage/PageNameWrapper";
import { CertificateIDValidation } from "../../components/CertificateIDValidation/CertificateIDValidation";
import { certificateIDValidationPageTitle } from "../commonViewConstants";

export const CertificateIDValidationView = (): JSX.Element => {
  return (
    <PageNameWrapper title={certificateIDValidationPageTitle}>
      <CertificateIDValidation />
    </PageNameWrapper>
  );
};
