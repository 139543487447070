import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { MyAccountDelete } from "./MyAccountDelete";
import { MyAccountLicenses } from "./MyAccountLicenses";
import { MyAccountPasswordReset } from "./MyAccountPasswordReset";
import { MyAccountCertifications } from "./MyAccountCertifications";
import { MyAccountLanguageData } from "../../constants/Language/Language";
import { auth, firebase } from "../../firebase/firebase";
import { useRecoilState } from "recoil";
import { Loading } from "../Loading/Loading";
import {
  firebaseUsers,
  firebaseUserTrainingData,
} from "../../recoil/dataHooks";
import format from "string-template";
import {
  fetchUserInformation,
  fetchUserTrainingInformation,
} from "../../utils/fetchData";
import { isEmptyObject } from "../../utils/general";

const useStyles = makeStyles((theme) => ({
  cardMain: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "65em",
    },
    [theme.breakpoints.up("lg")]: {
      width: "85em",
    },
  },
  card: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(0),
  },
}));

export const MyAccountCore = (): JSX.Element => {
  const classes = useStyles();
  const text = MyAccountLanguageData();
  const userIn = auth.currentUser;
  const [user, setUser] = useState<firebase.User>();
  const [initializing, setInitializing] = useState(true);
  const [userData, setUserData] = useRecoilState(firebaseUsers);
  const [userDataTrainingData, setUserDataTrainingData] = useRecoilState(
    firebaseUserTrainingData
  );

  const fetchUserTrainingData = async (courseID: string, userID: string) => {
    const userLicensedTraining = await fetchUserTrainingInformation(
      courseID,
      userID
    );

    if (isEmptyObject(userLicensedTraining)) {
      console.warn("Could not load licensedTraining");
      console.warn(`With the following course ID : ${courseID}`);
    } else {
      setUserDataTrainingData(userLicensedTraining);
    }
  };

  const fetchUser = async (userID: string) => {
    const users = await fetchUserInformation(userID);

    if (isEmptyObject(users)) {
      console.warn("Could not load users");
    } else {
      setUserData(users);
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        fetchUser(user.uid);
        if (initializing) setInitializing(false);
      }
    });
    return () => unsubscribe();
  }, [userIn]);

  useEffect(() => {
    if (user && !isEmptyObject(userData)) {
      fetchUserTrainingData(
        userData.currentLicense?.code ?? userData.currentCourse,
        user.uid
      );
    }
  }, [userData]);

  if (initializing) {
    return <Loading />;
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Card className={classes.cardMain}>
        {isEmptyObject(userData) ? (
          <Loading />
        ) : (
          <Fragment>
            <CardContent>
              <Typography gutterBottom variant="h5">
                {text.myAccount}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {format(text.welcome, {
                  name: isEmptyObject(userDataTrainingData)
                    ? userIn?.email
                    : userDataTrainingData.firstname !== ""
                    ? userDataTrainingData.firstname
                    : userIn?.email,
                })}
              </Typography>
              <Grid
                container
                spacing={2}
                alignItems="flex-start"
                className={classes.card}
              >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <MyAccountPasswordReset />
                  <MyAccountDelete />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <MyAccountLicenses />
                  <MyAccountCertifications />
                </Grid>
              </Grid>
            </CardContent>
          </Fragment>
        )}
      </Card>
    </Grid>
  );
};
