import React, { KeyboardEvent, useState } from "react";
import { BasicCard } from "../Cards/BasicCard";
import { CertificateIDValidationLanguageData } from "../../constants/Language/Language";
import Button from "@material-ui/core/Button";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import FormControl from "@mui/material/FormControl";
import ReCAPTCHA from "react-google-recaptcha";
import { ErrorText } from "../ErrorText/ErrorTextCore";
import { ErrorTypes } from "../ErrorText/constants/constants";
import { db, firebase } from "../../firebase/firebase";
import { CertificateIDDisplay } from "./CertificateIDDisplay";
import Box from "@mui/material/Box";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { firebaseCertificationIDs } from "../../recoil/dataHooks";
import { certificationIDs } from "../../firebase/types";
import {
  certificationIDRecoil,
  selectedLanguagePack,
} from "../../recoil/appState";

const useStyles = makeStyles(() => ({
  button: {
    textTransform: "none",
  },
  textLine: {
    display: "flex",
  },
  buttonCss: {
    paddingTop: 20,
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
  text: {
    margin: "0 5px 0 5px",
  },
  textField: { "& .MuiFormLabel-root.Mui-Focused": { color: "black" } },
}));

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const CertificateIDValidation = (): JSX.Element => {
  const classes = useStyles();
  const text = CertificateIDValidationLanguageData();
  const certificationIDLength = 10;
  const language = useRecoilValue(selectedLanguagePack);
  const [open, setOpen] = useState(false);
  const [certificationID, setCertificationID] = useState("");
  const setCertificationIDRecoil = useSetRecoilState(certificationIDRecoil);
  const setCertificationIDsData = useSetRecoilState(firebaseCertificationIDs);
  const [captchaPassed, setCaptchaPassed] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [certificateDisplayToggle, setCertificateDisplayToggle] =
    useState(false);
  const [errorType, setErrorType] = useState<ErrorTypes>("empty");

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleReCAPTCHA = () => {
    setCaptchaPassed(true);
  };

  const checkCertificationIDs = async (certificationID: string) => {
    const certificationIDsRef = db.collection("certificationIDs");

    const certificationIDfromFB = await certificationIDsRef
      .where(firebase.firestore.FieldPath.documentId(), "==", certificationID)
      .get();

    if (certificationIDfromFB.empty) {
      setErrorType("certificationIDNotFound");
      setErrorDisplay(true);
    } else {
      setOpen(true);
      setCertificateDisplayToggle(true);
      const certificationIDsRef = db
        .collection("certificationIDs")
        .doc(certificationID);
      const certificationIDSingle = await certificationIDsRef.get();

      if (!certificationIDSingle.exists) {
        console.warn("Could not load certificationID");
      } else {
        if (typeof certificationIDSingle.data() !== "undefined") {
          setCertificationIDRecoil(certificationID);
          setCertificationIDsData(
            certificationIDSingle.data() as certificationIDs
          );
        }
      }
    }
  };

  const validateCertificationID = () => {
    setErrorDisplay(false);
    setOpen(false);
    const removeSpacesFromCertificationID = certificationID.replace(/ /g, "");
    setCertificateDisplayToggle(false);
    if (removeSpacesFromCertificationID.length <= 0) {
      setErrorDisplay(true);
      setErrorType("empty");
      return;
    }
    if (removeSpacesFromCertificationID.length !== certificationIDLength) {
      setErrorDisplay(true);
      setErrorType("certificationIDNotValid");
      return;
    }

    if (!captchaPassed) {
      setErrorDisplay(true);
      setErrorType("empty");
    } else {
      checkCertificationIDs(removeSpacesFromCertificationID);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCertificationID(event.target.value);
  };

  const handleKeyboardEvent = (event: KeyboardEvent<HTMLImageElement>) => {
    if (event.key === "Enter") {
      validateCertificationID();
    }
  };

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {text.certificateIsValid}
        </Alert>
      </Snackbar>

      <BasicCard title={text.title} center={true}>
        <FormControl onKeyPress={handleKeyboardEvent}>
          <Box mt={1}>
            <TextField
              className={classes.textField}
              id="outlined-basic"
              label={text.enterCertificateCode}
              placeholder="xxxxxxxx"
              value={certificationID}
              onChange={handleChange}
              variant="outlined"
            />
          </Box>
          <Box mt={1}>
            <ReCAPTCHA
              sitekey="6LcFr-EcAAAAAMNRR3mknNoqZp82ySK00WeRMd-N"
              onChange={handleReCAPTCHA}
              hl={language}
            />
          </Box>
          <Box mt={1}>
            <Button
              className={classes.button}
              onClick={() => validateCertificationID()}
              variant="contained"
              color="primary"
            >
              {text.checkCertificate}
            </Button>
          </Box>
        </FormControl>
        <ErrorText display={errorDisplay} errorMessage={errorType} />
        <CertificateIDDisplay display={certificateDisplayToggle} />
      </BasicCard>
    </React.Fragment>
  );
};
