import React from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TDocumentDefinitions } from "pdfmake/interfaces";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { sdLogo } from "../../assets/base64SdLogo";
import { sdBackground } from "../../assets/base64Background";
import { CreateCertificateLanguageData } from "../../constants/Language/Language";
import { testState } from "../../recoil/appState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  firebaseUsers,
  firebaseUserTrainingData,
} from "../../recoil/dataHooks";
import { auth } from "../../firebase/firebase";
import { setFireStoreCollectionInCollection } from "../../firebase/Data/dataReadWrite";
import format from "string-template";
import { fetchUserTrainingInformation } from "../../utils/fetchData";
import { CertificationStatus } from "../../constants/appConstants";
import { isEmptyObject } from "../../utils/general";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    marginRight: theme.spacing(0.5),
  },
}));

interface CreatePDFCoreProps {
  firstName: string;
  lastName: string;
  score: string;
  dateAwarded: string;
  dateExpire: string;
  certificateId: string;
  courseName: string;
  certificateText: string;
  navigate?: boolean;
}

export const CreatePDFCore = ({
  firstName,
  lastName,
  score,
  dateAwarded,
  dateExpire,
  certificateId,
  courseName,
  navigate,
}: CreatePDFCoreProps): JSX.Element => {
  const classes = useStyles();
  const text = CreateCertificateLanguageData();
  const user = auth.currentUser;
  const setTestState = useSetRecoilState(testState);
  const setUserDataTrainingData = useSetRecoilState(firebaseUserTrainingData);
  const userData = useRecoilValue(firebaseUsers);

  if (user === null) {
    return <p>No User</p>;
  }

  const docDefinition: TDocumentDefinitions = {
    pageOrientation: "landscape",
    pageSize: "LETTER",
    background: [
      {
        image: sdBackground,
        width: 792,
        margin: [0, 275, 0, 0],
      },
    ],
    content: [
      {
        stack: [
          {
            image: sdLogo,
            width: 301,
            margin: [0, 25, 0, 0],
          },
        ],
        alignment: "center",
      },
      {
        stack: [
          {
            stack: [
              {
                text: text.title,
                fontSize: 22,
                bold: true,
                alignment: "center",
                margin: [0, 30, 0, 0],
              },
            ],
          },
          {
            text: text.awardedTo,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            margin: [0, 15, 0, 12],
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 715,
                y2: 0,
                lineWidth: 0.5,
                lineColor: "#000000",
              },
            ],
          },
          {
            stack: [
              {
                text: format(text.firstAndLastName, {
                  firstName: firstName.trim(),
                  lastName: lastName.trim(),
                }),
                fontSize: 20,
                bold: true,
                alignment: "center",
              },
            ],
          },
          {
            margin: [0, 12, 0, 12],
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 715,
                y2: 0,
                lineWidth: 0.5,
                lineColor: "#000000",
              },
            ],
          },
          {
            text: [
              {
                text: format(text.completedText, {
                  courseTitle: courseName,
                  score: score,
                }),
                alignment: "center",
              },
            ],
          },
          {
            text: "\n",
          },
          {
            alignment: "justify",
            columns: [
              { text: "\n" },
              {
                stack: [
                  {
                    text: dateAwarded,
                    decoration: "underline",
                    fontSize: 16,
                    alignment: "center",
                    bold: true,
                  },
                  {
                    text: text.awardedOn,
                    alignment: "center",
                  },
                ],
              },

              {
                stack: [
                  {
                    text: dateExpire,
                    decoration: "underline",
                    fontSize: 16,
                    alignment: "center",
                    bold: true,
                  },
                  {
                    text: text.expiresOn,
                    alignment: "center",
                  },
                ],
              },

              { text: "\n" },
            ],
          },
          {
            stack: [
              {
                text: certificateId.replace(/\s/g, ""),
                decoration: "underline",
                fontSize: 16,
                margin: [0, 10, 0, 0],
                alignment: "center",
              },
              {
                text: text.courseUniqueID,
                alignment: "center",
              },
            ],
          },
        ],
      },
    ],
  };

  const setExamState = (state: string) => {
    if (user !== null) {
      setFireStoreCollectionInCollection({
        collection1: "users",
        collection2: "licensedTraining",
        documentID1: user.uid,
        documentID2: (
          userData.currentLicense?.code ?? userData.currentCourse
        ).toString(),
        keyPairObjectToUpdate: {
          examInProgress: {
            currentExamQuestions: [],
            lastAnsweredQuestionIndex: 0,
            numCorrectAnswers: 0,
          },
          examState: state,
        },
        mergeFlag: true,
      });
    }
  };

  const fetchUserTrainingData = async (courseID: string, userID: string) => {
    const userLicensedTraining = await fetchUserTrainingInformation(
      courseID,
      userID
    );

    if (isEmptyObject(userLicensedTraining)) {
      console.warn("Could not load licensedTraining");
      console.warn(`With the following course ID : ${courseID}`);
    } else {
      setUserDataTrainingData(userLicensedTraining);
    }
  };

  const handlePDFDownload = () => {
    const docDefinitionConst = JSON.parse(JSON.stringify(docDefinition));
    pdfMake.createPdf(docDefinitionConst).download(`certificate.pdf`);
    if (typeof navigate !== "undefined" && navigate) {
      setTestState(CertificationStatus.NOT_IN_PROGRESS);
      setExamState(CertificationStatus.NOT_IN_PROGRESS);
      fetchUserTrainingData(
        userData.currentLicense?.code ?? userData.currentCourse,
        user.uid
      );
    }
  };

  return (
    <React.Fragment>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => {
          handlePDFDownload();
        }}
      >
        {text.downloadCertificate}
      </Button>
    </React.Fragment>
  );
};
