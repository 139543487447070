import { db } from "../firebase";

type collectionType =
  | "users"
  | "trainingLicenses"
  | "products"
  | "customers"
  | "certificationIDs";

// Update

interface updateFireStoreValueProps {
  collection: collectionType;
  documentID: string;
  keyPairObjectToUpdate: Record<string, unknown>;
  mergeFlag: boolean;
}

interface setFireStoreCollectionInCollectionProps {
  collection1: collectionType;
  collection2: string;
  documentID1: string;
  documentID2: string;
  keyPairObjectToUpdate: Record<string, unknown>;
  mergeFlag: boolean;
}

interface getFireStoreCollectionInCollectionProps {
  collection1: collectionType;
  collection2: string;
  documentID1: string;
  documentID2: string;
}

interface getFireStoreDocumentProps {
  collection: collectionType;
  documentID: string;
}

export const updateFireStoreValue = ({
  collection,
  documentID,
  keyPairObjectToUpdate,
}: updateFireStoreValueProps): void => {
  db.collection(collection).doc(documentID).update(keyPairObjectToUpdate);
};

export const setFireStoreValue = ({
  collection,
  documentID,
  keyPairObjectToUpdate,
  mergeFlag,
}: updateFireStoreValueProps): void => {
  db.collection(collection)
    .doc(documentID)
    .set(keyPairObjectToUpdate, { merge: mergeFlag });
};

export const setFireStoreCollectionInCollection = async ({
  collection1,
  collection2,
  documentID1,
  documentID2,
  keyPairObjectToUpdate,
  mergeFlag,
}: setFireStoreCollectionInCollectionProps): Promise<void> => {
  await db
    .collection(collection1)
    .doc(documentID1)
    .collection(collection2)
    .doc(documentID2)
    .set(keyPairObjectToUpdate, { merge: mergeFlag });
};

// Get

export const getFireStoreDocument = ({
  collection,
  documentID,
}: getFireStoreDocumentProps): any => {
  db.collection(collection)
    .doc(documentID)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        console.error("No such document!");
      }
    })
    .catch((error) => {
      console.error("Error getting document:", error);
    });
};

export const getFireStoreCollectionInCollection = ({
  collection1,
  collection2,
  documentID1,
  documentID2,
}: getFireStoreCollectionInCollectionProps): any => {
  db.collection(collection1)
    .doc(documentID1)
    .collection(collection2)
    .doc(documentID2)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        console.error("No such document!");
      }
    })
    .catch((error) => {
      console.error("Error getting document:", error);
    });
};
