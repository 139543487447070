import React, { Fragment, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { BasicCard } from "../Cards/BasicCard";
import { VideoTutorialVideo } from "./VideoTutorialVideo";
import { auth, firebase } from "../../firebase/firebase";
import { Loading } from "../Loading/Loading";
import { LessonListCore } from "../LessonList/LessonListCore";
import { VideoTutorialTextArea } from "./VideoTutorialTextArea";
import { OfflineSettingsDialog } from "./OfflineSettings/OfflineSettingsDialog";
import {
  firebaseProducts,
  firebaseUsers,
  firebaseUserTrainingData,
} from "../../recoil/dataHooks";
import {
  offlineDownloadPopup,
  topicIndexState,
  videoIndexState,
} from "../../recoil/appState";
import {
  fetchProductInformation,
  fetchUserInformation,
  fetchUserTrainingInformation,
} from "../../utils/fetchData";
import { isEmptyObject } from "../../utils/general";
import { Lessons, Topics } from "../../firebase/types";

const useStyles = makeStyles((theme) => ({
  courseContainer: {
    "& > *": {
      fontWeight: 600,
    },
  },
  card: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100em",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100em",
    },
  },
  cardDownload: {
    marginLeft: "52rem",

    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  cardLeft: {
    color: theme.palette.text.secondary,
    order: 3,

    [theme.breakpoints.up("lg")]: {
      order: 2,
    },
    [(theme.breakpoints.up("sm"), theme.breakpoints.up("md"))]: {
      order: 1,
    },
  },
  cardRight: {
    color: theme.palette.text.secondary,
    order: 1,

    [theme.breakpoints.up("lg")]: {
      order: 1,
    },
    [(theme.breakpoints.up("sm"), theme.breakpoints.up("md"))]: {
      order: 2,
    },
  },
  cardLinkList: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
    order: 2,

    [theme.breakpoints.up("sm")]: {
      order: 3,
    },
  },
}));

export const VideoTutorialCore = (): JSX.Element => {
  const classes = useStyles();
  const userIn = auth.currentUser;
  const [user, setUser] = useState<firebase.User>();
  const [initializing, setInitializing] = useState(true);
  const [userData, setUserData] = useRecoilState(firebaseUsers);
  const [product, setProduct] = useRecoilState(firebaseProducts);
  const setUserDataTrainingData = useSetRecoilState(firebaseUserTrainingData);
  const [dialogState, setDialogState] = useRecoilState(offlineDownloadPopup);
  const topicIndex = useRecoilValue(topicIndexState);
  const videoIndex = useRecoilValue(videoIndexState);

  const fetchUserTrainingData = async (courseID: string, userID: string) => {
    const userLicensedTraining = await fetchUserTrainingInformation(
      courseID,
      userID
    );

    if (isEmptyObject(userLicensedTraining)) {
      console.warn("Could not load licensedTraining");
      console.warn(`With the following course ID : ${courseID}`);
    } else {
      setUserDataTrainingData(userLicensedTraining);
    }
  };

  const fetchProduct = async (productId: string) => {
    const product = await fetchProductInformation(productId);

    if (isEmptyObject(product)) {
      console.warn("Could not load product");
    } else {
      setProduct(product);
    }
  };

  const fetchUser = async (userID: string) => {
    const user = await fetchUserInformation(userID);

    if (isEmptyObject(user)) {
      console.warn("Could not load user");
    } else {
      setUserData(user);
    }
  };

  useEffect(() => {
    if (user && userData) {
      fetchUserTrainingData(
        userData.currentLicense?.code ?? userData.currentCourse,
        user.uid
      );
      fetchProduct(
        userData.currentLicense?.productId ?? userData.currentCourse
      );
    }
  }, [userData]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        fetchUser(user.uid);
        if (initializing) setInitializing(false);
      }
    });
    return () => unsubscribe();
  }, [userIn]);

  const handleDialogClose = () => {
    setDialogState(false);
  };

  if (initializing) {
    return <Loading />;
  }

  let lesson = {} as Lessons;
  const currentTopic = product?.topics?.[topicIndex] ?? ({} as Topics);

  if (!isEmptyObject(currentTopic)) {
    const { firstLessonIndex } = currentTopic;
    let index = videoIndex;

    if (videoIndex < +firstLessonIndex) {
      index = parseInt(firstLessonIndex);
    }

    lesson = product["lessons"][index];
  }

  return (
    <Fragment>
      {isEmptyObject(product) && isEmptyObject(currentTopic) ? (
        <Loading />
      ) : (
        <BasicCard center>
          <OfflineSettingsDialog
            open={dialogState}
            handleClose={handleDialogClose}
          />
          <Box className={classes.courseContainer}>
            <Typography variant="h5" gutterBottom>
              {product.title}
            </Typography>
            <Typography variant="subtitle1">
              {currentTopic.title
                ? `${currentTopic.title} - ${lesson.title}: ${lesson.description}`
                : product.description}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {lesson.inANutshell}
            </Typography>
          </Box>

          <Grid container spacing={2} className={classes.card}>
            <Grid item xs={12} className={classes.cardDownload}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                endIcon={<GetAppRoundedIcon />}
                onClick={() => setDialogState(true)}
              >
                Download
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2} className={classes.cardLeft}>
              <LessonListCore />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={8}
              className={classes.cardRight}
            >
              <VideoTutorialVideo />
            </Grid>
            <Grid item xs={12} className={classes.cardLinkList}>
              <VideoTutorialTextArea />
            </Grid>
          </Grid>
        </BasicCard>
      )}
    </Fragment>
  );
};
