import React from "react";
import { PageNameWrapper } from "../../components/Cards/CenteredPage/PageNameWrapper";
import { TopicSelectionCore } from "../../components/TopicSelection/TopicSelectionCore";
import { topicSelectionTitle } from "../commonViewConstants";

export const TopicSelectionView = (): JSX.Element => {
  return (
    <PageNameWrapper title={topicSelectionTitle}>
      <TopicSelectionCore />
    </PageNameWrapper>
  );
};
