import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { BasicCard } from "../../Cards/BasicCard";
import Box from "@material-ui/core/Box";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { testState } from "../../../recoil/appState";
import {
  firebaseProducts,
  firebaseUsers,
  firebaseUserTrainingData,
} from "../../../recoil/dataHooks";
import { setFireStoreCollectionInCollection } from "../../../firebase/Data/dataReadWrite";
import { Loading } from "../../Loading/Loading";
import { TestPrefaceLanguageData } from "../../../constants/Language/Language";
import { auth } from "../../../firebase/firebase";
import {
  generateArrayOfRandomNumberNoRepeat,
  isEmptyObject,
} from "../../../utils/general";
import { useHistory } from "react-router";
import { CertificationStatus } from "../../../constants/appConstants";
import { routeCodes } from "../../../constants/routes";
import format from "string-template";

const useStyles = makeStyles((theme) => ({
  card: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "300",
    },
    [theme.breakpoints.up("md")]: {
      width: "30em",
    },
    [theme.breakpoints.up("lg")]: {
      width: "30em",
    },
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
    paddingTop: theme.spacing(1),
  },
  button: {
    marginLeft: "auto",
    marginRight: "auto",
    textTransform: "none",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
  },
  border: {
    padding: 20,
  },
}));

export const TestPrefaceCore = (): JSX.Element => {
  const classes = useStyles();
  const user = auth.currentUser;
  const history = useHistory();
  const setTestState = useSetRecoilState(testState);
  const userData = useRecoilValue(firebaseUsers);
  const product = useRecoilValue(firebaseProducts);
  const userDataTrainingData = useRecoilValue(firebaseUserTrainingData);
  const text = TestPrefaceLanguageData();

  const handleTestStart = () => {
    updateRemainingTries(userDataTrainingData.examAttemptsRemaining - 1);

    setTestState(CertificationStatus.IN_PROGRESS);
  };

  const updateRemainingTries = (remainingTries: number) => {
    if (user !== null && remainingTries >= 0) {
      setFireStoreCollectionInCollection({
        collection1: "users",
        collection2: "licensedTraining",
        documentID1: user.uid,
        documentID2: (
          userData.currentLicense?.code ?? userData.currentCourse
        ).toString(),
        keyPairObjectToUpdate: {
          examInProgress: {
            currentExamQuestions: generateArrayOfRandomNumberNoRepeat(
              product.numExamQuestions,
              product.examQuestions.length - 1
            ),
            lastAnsweredQuestionIndex: 0,
            numCorrectAnswers: 0,
          },
          examAttemptsRemaining: remainingTries,
          examState: CertificationStatus.IN_PROGRESS,
        },
        mergeFlag: true,
      });
    }
  };

  if (isEmptyObject(product) || isEmptyObject(userDataTrainingData)) {
    return <Loading />;
  }

  return (
    <BasicCard title={text.title}>
      <form noValidate autoComplete="off" className={classes.form}>
        <div className={classes.card}>
          {userDataTrainingData.certificationID ? (
            <Box border={1} className={classes.border}>
              <Typography variant="body1">{text.alreadyCompleted}</Typography>
            </Box>
          ) : (
            <Box border={1} className={classes.border}>
              <Typography variant="body1">{text.welcome}</Typography>

              <Typography variant="body1">
                {format(text.testPrefaceParagraph, {
                  passRequirement: product.passRequirement,
                })}
              </Typography>

              <Typography variant="body1">{text.certificateText}</Typography>
            </Box>
          )}
          <Box className={classes.buttonRow}>
            {userDataTrainingData.examAttemptsRemaining <= 0 ||
            userDataTrainingData.certificationID ? (
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push(routeCodes.VIDEOTUTORIAL.route);
                }}
              >
                {text.backToVideoTutorial}
              </Button>
            ) : (
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleTestStart();
                }}
              >
                {text.startTestButton}
              </Button>
            )}
          </Box>
        </div>
      </form>
    </BasicCard>
  );
};
