import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { v4 as uuid } from "uuid";
import { Loading } from "../Loading/Loading";
import { MyAccountLicensesLanguageData } from "../../constants/Language/Language";
import { auth } from "../../firebase/firebase";
import { useRecoilState } from "recoil";
import { firebaseUsers } from "../../recoil/dataHooks";
import { LicensedTraining, TrainingLicenses } from "../../firebase/types";
import { dateConverter, isEmptyObject } from "../../utils/general";
import {
  fetchUserInformation,
  fetchUserLicensedTraining,
  fetchUserLicenses,
} from "../../utils/fetchData";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 40,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  table: { display: "block", overflowX: "auto" },
}));

export const MyAccountLicenses = (): JSX.Element => {
  const classes = useStyles();
  const text = MyAccountLicensesLanguageData();
  const user = auth.currentUser;

  if (user === null) {
    return <p>No User</p>;
  }

  const [userData, setUserData] = useRecoilState(firebaseUsers);
  const [licenses, setLicenses] = useState<TrainingLicenses[]>([]);
  const [userTrainingData, setUserTrainingData] = useState<LicensedTraining[]>(
    []
  );

  useEffect(() => {
    if (isEmptyObject(userData)) {
      fetchUser();
    } else {
      fetchUserLicenseData();
      fetchUserTrainingData();
    }
  }, []);

  const fetchUser = async () => {
    const users = await fetchUserInformation(user.uid);

    if (isEmptyObject(users)) {
      console.warn("Could not load users");
    } else {
      setUserData(users);
    }
  };

  const fetchUserLicenseData = async () => {
    const userLicense = await fetchUserLicenses(user.uid);

    if (isEmptyObject(userLicense)) {
      console.warn("Could not load license");
    } else {
      setLicenses(userLicense as TrainingLicenses[]);
    }
  };

  const fetchUserTrainingData = async () => {
    const userLicensedTraining = await fetchUserLicensedTraining(user.uid);

    const licenseTraining: LicensedTraining[] = [];
    userLicensedTraining.forEach((doc: any) => {
      licenseTraining.push({ ...doc.data() });
    });

    if (isEmptyObject(licenseTraining)) {
      console.warn("Could not load licensedTraining");
    } else {
      setUserTrainingData(licenseTraining as LicensedTraining[]);
    }
  };

  const noLicenses = () => (
    <TableRow key={uuid()}>
      <TableCell key={uuid()}>
        <Typography key={uuid().toString()} variant={"body2"}>
          {text.noLicense}
        </Typography>
      </TableCell>
    </TableRow>
  );

  const licenseTable = (
    <TableContainer>
      {isEmptyObject(userTrainingData) ? (
        <Loading />
      ) : (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{text.licenseID}</TableCell>
              <TableCell>{text.purchaseDate}</TableCell>
              <TableCell>{text.redeemedDate}</TableCell>
              <TableCell>{text.expiryDate}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isEmptyObject(licenses)
              ? noLicenses()
              : licenses.map((license, index) => (
                  <TableRow key={uuid()}>
                    <TableCell key={uuid()}>{license.licenseId}</TableCell>
                    <TableCell key={uuid()}>
                      {license.validityStart
                        ? dateConverter(license.validityStart)
                        : ""}
                    </TableCell>
                    <TableCell key={uuid()}>
                      {userTrainingData[index].redeemedDate
                        ? dateConverter(userTrainingData[index].redeemedDate)
                        : ""}
                    </TableCell>
                    <TableCell key={uuid()}>
                      {license.validityEnd
                        ? dateConverter(license.validityEnd)
                        : ""}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );

  return (
    <Box className={classes.root}>
      <Typography variant={"h6"}>{text.myLicense}</Typography>
      {/*TODO : Fix this ugly ugly 101 check | WernerWildenboer 08/10/2021 */}
      {isEmptyObject(userTrainingData) ? (
        <Typography variant={"body2"}>{text.noLicense}</Typography>
      ) : isEmptyObject(userData) ? (
        <Loading />
      ) : (
        licenseTable
      )}
    </Box>
  );
};
