import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { CenteredPage } from "./CenteredPage";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { appPageName } from "../../../recoil/appState";
//import { ComingSoonView } from "../../Views/ComingSoonView/ComingSoonView";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: "relative",
      overflow: "hidden",
      height: "100%",
    },
  })
);

export interface PageNameWrapperProps {
  children?: React.ReactNode;
  title: string;
  isBeta?: boolean;
  isDraft?: boolean;
  isComingSoon?: boolean;
}

export const PageNameWrapper = ({
  children,
  title,
  isComingSoon = false,
}: PageNameWrapperProps): JSX.Element => {
  const classes = useStyles();
  const [pageName, setPageName] = useRecoilState(appPageName);

  useEffect(() => {
    if (pageName !== title) {
      setPageName(title);
    }
  });

  return (
    <div className={classes.container}>
      <CenteredPage>
        {isComingSoon ? <Typography>Coming Soon!</Typography> : null}
        {children}
      </CenteredPage>
    </div>
  );
};
