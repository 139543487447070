import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { footerTOSToggle } from "../../../recoil/appState";
import { useSetRecoilState } from "recoil";
import { Markup } from "interweave";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    actionButtonContent: {
      marginBottom: theme.spacing(1),
    },
    button: {
      textTransform: "none",
      margin: theme.spacing(0.5),
      float: "right",
    },
    consentBannerText: {
      margin: 10,
      marginTop: "1em",
      fontFamily: `${theme.typography.fontFamily} !important`,
    },
  })
);

interface Props {
  title: string;
  bodyText: string;
  open: boolean;
  setOpen: (val: boolean) => void;
}

export const DialogFooter = ({
  title,
  bodyText,
  open,
  setOpen,
}: Props): JSX.Element => {
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  const setTOSToggle = useSetRecoilState(footerTOSToggle);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {title}
        <IconButton
          color="primary"
          aria-label="close"
          component="span"
          className={classes.button}
          onClick={() => {
            setTOSToggle(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography className={classes.consentBannerText} variant="body1">
            <Markup content={bodyText} />
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
