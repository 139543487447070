import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { v4 as uuid } from "uuid";
import { Loading } from "../Loading/Loading";
import { MyAccountCertificationsLanguageData } from "../../constants/Language/Language";
import { auth } from "../../firebase/firebase";
import { CreatePDFCore } from "../CreatePDF/CreatePDFCore";
import { useRecoilState } from "recoil";
import { firebaseUsers } from "../../recoil/dataHooks";
import { certificationIDs, Products } from "../../firebase/types";
import { dateConverter, isEmptyObject } from "../../utils/general";
import {
  fetchProductsRef,
  fetchUserCertificate,
  fetchUserInformation,
} from "../../utils/fetchData";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  certificate: {},
  table: { display: "block", overflowX: "auto" },
  button: {
    textTransform: "none",
  },
}));

export const MyAccountCertifications = (): JSX.Element => {
  const classes = useStyles();
  const text = MyAccountCertificationsLanguageData();
  const user = auth.currentUser;

  if (user === null) {
    return <p>No User</p>;
  }

  const [userData, setUserData] = useRecoilState(firebaseUsers);
  const [product, setProduct] = useState<Products[]>([]);
  const [userCertificates, setUserCertificates] = useState<certificationIDs[]>(
    []
  );

  useEffect(() => {
    if (isEmptyObject(userData)) {
      fetchUser();
    } else {
      fetchUserCertificationData();
    }

    if (isEmptyObject(product)) {
      fetchProducts();
    }
  }, []);

  useEffect(() => {
    fetchProducts();
    fetchUserCertificationData();
  }, [userData]);

  const fetchUser = async () => {
    const users = await fetchUserInformation(user.uid);

    if (isEmptyObject(users)) {
      console.warn("Could not load users");
    } else {
      setUserData(users);
    }
  };

  const fetchUserCertificationData = async () => {
    const userCertifications = await fetchUserCertificate(user.uid);

    if (isEmptyObject(userCertifications)) {
      console.warn("Could not load Certificates");
    } else {
      setUserCertificates(userCertifications as certificationIDs[]);
    }
  };

  const fetchProducts = async () => {
    const products = await fetchProductsRef();

    const documents: any[] = [];
    products.forEach((doc: any) => {
      documents[doc.id] = doc.data();
    });

    if (isEmptyObject(documents)) {
      console.warn("Could not load Certificates");
    } else {
      setProduct(documents as Products[]);
    }
  };

  const noCertifications = (productTitle: string) => (
    <TableRow key={uuid()}>
      <TableCell key={uuid()}>
        <Typography key={uuid()} variant={"body2"}>
          {text.noCertificateFor}
          {productTitle}
        </Typography>
      </TableCell>
    </TableRow>
  );

  const certificationTable = (
    <TableContainer>
      {isEmptyObject(product) ? (
        <Loading />
      ) : (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{text.certificateID}</TableCell>
              <TableCell>{text.courseTitle}</TableCell>
              <TableCell>{text.earnedOn}</TableCell>
              <TableCell>{text.validUntil}</TableCell>
              <TableCell>{text.score}</TableCell>
              <TableCell>{text.action}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isEmptyObject(userCertificates)
              ? // SD0-258 - once everything has been migrated, use only `productId`.
                noCertifications(
                  userData.currentLicense?.productId ?? userData.currentCourse
                    ? product[
                        parseInt(
                          userData.currentLicense?.productId ??
                            userData.currentCourse,
                          10
                        )
                      ].title
                    : ""
                )
              : userCertificates.map((userCertificate) => (
                  <TableRow key={uuid()}>
                    <TableCell key={uuid()}>
                      {userCertificate.certificationID}
                    </TableCell>
                    <TableCell key={uuid()}>
                      {userCertificate.courseName}
                    </TableCell>

                    <TableCell key={uuid()}>
                      {userCertificate.certificationDate
                        ? dateConverter(userCertificate.certificationDate)
                        : null}
                    </TableCell>

                    {userCertificate.certificationExpiryDate ? (
                      <TableCell key={uuid()}>
                        {dateConverter(userCertificate.certificationExpiryDate)}
                      </TableCell>
                    ) : null}
                    <TableCell key={uuid()}>{userCertificate.score}%</TableCell>

                    <TableCell key={uuid()}>
                      <CreatePDFCore
                        key={uuid()}
                        firstName={userCertificate.firstname}
                        lastName={userCertificate.lastname}
                        score={userCertificate.score}
                        dateAwarded={
                          userCertificate.certificationDate
                            ? dateConverter(userCertificate.certificationDate)
                            : ""
                        }
                        dateExpire={
                          userCertificate.certificationDate
                            ? dateConverter(
                                userCertificate.certificationExpiryDate
                              )
                            : ""
                        }
                        certificateId={
                          userCertificate.certificationID
                            ? userCertificate.certificationID
                            : ""
                        }
                        courseName={userCertificate.courseName}
                        certificateText={userCertificate.courseName}
                      />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );

  return (
    <Box className={classes.root}>
      <Typography variant={"h6"}>{text.myCertificate}</Typography>
      {isEmptyObject(userData) ? <Loading /> : certificationTable}
    </Box>
  );
};
