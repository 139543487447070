import React, { useEffect, useState } from "react";
import { TestPrefaceCore } from "../TestPreface/TestPrefaceCore";
import {
  testState,
  testPercentage,
  trainingCourseName,
  TestCourseNumberOfQuestions,
  trainingCourseQuestions,
  questionIndexState,
} from "../../../recoil/appState";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { QuestionCardCore } from "../QuestionCard/QuestionCardCore";
import { TestCompletion } from "../TestCompletion/TestCompletion";
import { auth, firebase } from "../../../firebase/firebase";
import { Loading } from "../../Loading/Loading";
import {
  firebaseProducts,
  firebaseUsers,
  firebaseUserTrainingData,
} from "../../../recoil/dataHooks";
import { CertificationStatus } from "../../../constants/appConstants";
import {
  generateArraySubsetBasedOffOfIndexArray,
  isEmptyObject,
} from "../../../utils/general";
import {
  fetchProductInformation,
  fetchUserInformation,
  fetchUserTrainingInformation,
} from "../../../utils/fetchData";

export const CourseTest = (): JSX.Element | null => {
  const [state, setState] = useRecoilState(testState);
  const setTrainingCourseName = useSetRecoilState(trainingCourseName);
  const setNumberOfQuestions = useSetRecoilState(TestCourseNumberOfQuestions);
  const userIn = auth.currentUser;
  const [user, setUser] = useState<firebase.User>();
  const [initializing, setInitializing] = useState(true);
  const [userData, setUserData] = useRecoilState(firebaseUsers);
  const [product, setProduct] = useRecoilState(firebaseProducts);
  const setQuestionIndex = useSetRecoilState(questionIndexState);
  const [userDataTrainingData, setUserDataTrainingData] = useRecoilState(
    firebaseUserTrainingData
  );
  const [questionsCurrentTest, setQuestionsCurrentTest] = useRecoilState(
    trainingCourseQuestions
  );
  const finalGrade = useRecoilValue(testPercentage);

  const fetchUserTrainingData = async (courseID: string, userID: string) => {
    const userLicensedTraining = await fetchUserTrainingInformation(
      courseID,
      userID
    );

    if (isEmptyObject(userLicensedTraining)) {
      console.warn("Could not load licensedTraining");
      console.warn(`With the following course ID : ${courseID}`);
    } else {
      setUserDataTrainingData(userLicensedTraining);
    }
  };

  const fetchProducts = async (courseId: string) => {
    const products = await fetchProductInformation(courseId);

    if (isEmptyObject(products)) {
      console.warn("Could not load product");
    } else {
      setProduct(products);
    }
  };

  const fetchUser = async (userID: string) => {
    const users = await fetchUserInformation(userID);

    if (isEmptyObject(users)) {
      console.warn("Could not load users");
    } else {
      setUserData(users);
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        fetchUser(user.uid);
        if (initializing) setInitializing(false);
      }
    });
    return () => unsubscribe();
  }, [userIn]);

  useEffect(() => {
    if (user && !isEmptyObject(userData)) {
      const productId = userData.currentLicense
        ? userData.currentLicense?.productId
        : userData.currentCourse;

      if (productId) {
        fetchUserTrainingData(
          userData.currentLicense?.code ?? productId,
          user.uid
        );
        fetchProducts(productId);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (user && !isEmptyObject(userData)) {
      fetchUserTrainingData(
        userData.currentLicense?.code ?? userData.currentCourse,
        user.uid
      );
    }
  }, [state]);

  useEffect(() => {
    if (!isEmptyObject(product)) {
      setTrainingCourseName(product.title);
      setNumberOfQuestions(product.numExamQuestions);
      if (!isEmptyObject(userDataTrainingData)) {
        if (
          typeof userDataTrainingData.examInProgress.currentExamQuestions !==
          "undefined"
        ) {
          const questionsFromIndex = generateArraySubsetBasedOffOfIndexArray(
            product.examQuestions,
            userDataTrainingData.examInProgress.currentExamQuestions
          );
          setQuestionsCurrentTest(questionsFromIndex);
        }
      }
    }
  }, [product, userDataTrainingData, state]);

  useEffect(() => {
    if (typeof userDataTrainingData.examInProgress !== "undefined") {
      setState(userDataTrainingData.examState);
      setQuestionIndex(
        userDataTrainingData.examInProgress.lastAnsweredQuestionIndex
      );
    }
  }, [userDataTrainingData]);

  if (initializing) {
    return <Loading />;
  }

  const completedTest = (): JSX.Element | null => {
    if (
      (typeof product === "undefined" && !product) ||
      typeof finalGrade === "undefined"
    ) {
      return null;
    }
    return <TestCompletion />;
  };

  if (typeof userDataTrainingData.examAttemptsRemaining === "undefined") {
    return <Loading />;
  }

  if (userDataTrainingData.examAttemptsRemaining < 0) {
    return completedTest();
  }

  switch (state) {
    case CertificationStatus.NOT_IN_PROGRESS:
      return <TestPrefaceCore />;
    case CertificationStatus.IN_PROGRESS:
      return questionsCurrentTest.length === 0 ? (
        <Loading />
      ) : (
        <QuestionCardCore />
      );
    default:
      return completedTest();
  }
};
