import { firebase } from "./firebase";

export const { FieldValue } = firebase.firestore;

// users

export interface ExamInProgress {
  currentExamQuestions: number[];
  lastAnsweredQuestionIndex: number;
  numCorrectAnswers: number;
}

export interface ProductLicense {
  brand: string;
  code: string;
  productId: string;
}

export type TestState = "notInProgress" | "inProgress" | "complete";

export interface LicensedTraining {
  productId: string;
  certificationID: string;
  validityEnd: firebase.firestore.Timestamp;
  currentVideoIndex: number;
  clickedVideosList: string; //will be converted to array when first value is added
  examAttemptsRemaining: number;
  certificationDate: firebase.firestore.Timestamp | string;
  certificationExpiryDate: firebase.firestore.Timestamp | string;
  examInProgress: ExamInProgress;
  examState: TestState;
  firstname: string;
  lastname: string;
  score: string;
  // SD0-258 - once everything has been migrated, expiryDate and redeemedDate are not optional.
  redeemedDate?: firebase.firestore.Timestamp | string;
}

export interface Users {
  licensedTraining: {
    [key: string]: LicensedTraining;
  };
  tocAccepted: number;
  privacyAccepted: number;
  testInProgress: TestInProgress;
  currentLicense: ProductLicense;
  // SD0-258: Ensure Firestore Metadata consistency after migration to new product model (once all migrated remove currentCourse)
  currentCourse?: string;
}

export interface TestInProgress {
  examAttemptsRemaining: number;
  productId: string;
}

// trainingLicenses

export interface TrainingLicenses {
  brand: string;
  assignedUser: string;
  assignedUsers?: string[];
  companyName: string;
  customerId: string;
  numberOfLicenses: number;
  orderId: string;
  productId: string;
  productName: string;
  licenseId?: string;
  usedLicenses?: number;
  // SD0-258 - once everything has been migrated, validityEnd and validityStart are not optional.
  validityEnd?: firebase.firestore.Timestamp;
  validityStart?: firebase.firestore.Timestamp;
}

// certificationIDs;

export interface certificationIDs {
  certificationDate: firebase.firestore.Timestamp | string;
  certificationExpiryDate: firebase.firestore.Timestamp | string;
  certificationID?: string;
  courseName: string;
  firstname: string;
  lastname: string;
  score: string;
  userID: string;
  validityEnd: firebase.firestore.Timestamp;
}

// products
export interface ExamQuestions {
  answers: string[];
  correctAnswers: number[];
  question: string;
}

export interface Lessons {
  additionalInfo: string;
  audioOfflineDLUrl: string;
  description: string;
  inANutshell: string;
  title: string;
  videoEmbedCode: string;
  videoOfflineDLUrl: string;
  videoUrl: string;
}

export interface Topics {
  audioTopicFullDownload: string;
  firstLessonIndex: string;
  lastLessonIndex: string;
  title: string;
  topicDurationSec: string;
  videoTopicFullDownload: string;
}

export interface Products {
  audioFullDownload: string;
  certificateText: string;
  description: string;
  examAttempts: number;
  examQuestions: ExamQuestions[];
  lessons: Lessons[];
  numExamQuestions: number;
  passRequirement: number;
  productID: number;
  title: string;
  topics?: Topics[];
  validityEnd: firebase.firestore.Timestamp;
  validityStart: firebase.firestore.Timestamp;
  vidFullDownload: string;
}

// certificationIDs

export interface CertificationIDs {
  certificationDate: string;
  courseName: string;
  firstname: string;
  lastname: string;
  score: string;
  userID: string;
  validityEnd: string;
}
