import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { BasicCard } from "../Cards/BasicCard";
import { CreatePDFCore } from "../CreatePDF/CreatePDFCore";
import { CertificateDisplayLanguageData } from "../../constants/Language/Language";
import { useRecoilState, useRecoilValue } from "recoil";
import { auth, firebase } from "../../firebase/firebase";
import {
  firebaseProducts,
  firebaseUsers,
  firebaseUserTrainingData,
} from "../../recoil/dataHooks";
import { Loading } from "../Loading/Loading";
import { dateConverter, isEmptyObject } from "../../utils/general";
import { testPercentage } from "../../recoil/appState";
import format from "string-template";
import {
  fetchProductInformation,
  fetchUserInformation,
  fetchUserTrainingInformation,
} from "../../utils/fetchData";

const useStyles = makeStyles((theme) => ({
  column: {
    [theme.breakpoints.down("sm")]: {
      width: "100",
    },
    [theme.breakpoints.up("md")]: {
      width: "50em",
    },
    [theme.breakpoints.up("lg")]: {
      width: "50em",
    },
    textAlign: "center",
    alignContent: "center",
  },
  card: {
    margin: 10,
    padding: 10,
  },
  button: {
    margin: "5%",
    textTransform: "none",
  },
  generalText: {
    padding: 10,
  },
  greenText: {
    padding: 10,
    color: "green",
  },
}));

export const CertificateDisplayCore = (): JSX.Element => {
  const classes = useStyles();
  const text = CertificateDisplayLanguageData();
  const userIn = auth.currentUser;
  const testPercentageState = useRecoilValue(testPercentage);
  const [user, setUser] = useState<firebase.User>();
  const [initializing, setInitializing] = useState(true);
  const [product, setProduct] = useRecoilState(firebaseProducts);
  const [userData, setUserData] = useRecoilState(firebaseUsers);
  const [userDataTrainingData, setUserDataTrainingData] = useRecoilState(
    firebaseUserTrainingData
  );

  const fetchUserTrainingData = async (courseID: string, userID: string) => {
    const userLicensedTraining = await fetchUserTrainingInformation(
      courseID,
      userID
    );

    if (isEmptyObject(userLicensedTraining)) {
      console.warn("Could not load licensedTraining");
      console.warn(`With the following course ID : ${courseID}`);
    } else {
      setUserDataTrainingData(userLicensedTraining);
    }
  };

  const fetchProducts = async (courseId: string) => {
    const products = await fetchProductInformation(courseId);

    if (isEmptyObject(products)) {
      console.warn("Could not load product");
    } else {
      setProduct(products);
    }
  };

  const fetchUser = async (userID: string) => {
    const users = await fetchUserInformation(userID);

    if (isEmptyObject(users)) {
      console.warn("Could not load users");
    } else {
      setUserData(users);
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        fetchUser(user.uid);
        if (initializing) setInitializing(false);
      }
    });
    return () => unsubscribe();
  }, [userIn]);

  useEffect(() => {
    if (
      user &&
      (!isEmptyObject(userData.currentLicense) || userData.currentCourse)
    ) {
      const productId = userData.currentLicense
        ? userData.currentLicense?.productId
        : userData.currentCourse;

      if (productId) {
        fetchUserTrainingData(
          userData.currentLicense?.code ?? productId,
          user.uid
        );
        fetchProducts(productId);
      }
    }
  }, [userData]);

  if (initializing) {
    return <Loading />;
  }

  return (
    <BasicCard title={text.title} center={true}>
      {isEmptyObject(userData) ||
      isEmptyObject(userDataTrainingData) ||
      typeof userDataTrainingData.certificationDate === "undefined" ||
      userDataTrainingData.certificationDate === "" ||
      userDataTrainingData.score === "" ||
      userDataTrainingData.score === "102" ? (
        <Loading />
      ) : (
        <div className={classes.column}>
          <Typography className={classes.generalText} variant="body1">
            {text.warning}
          </Typography>

          <Typography className={classes.generalText} variant="body1">
            {format(text.certificationParagraph, {
              firstName: userDataTrainingData.firstname,
              lastName: userDataTrainingData.lastname,
              courseTitle: product.title,
              certificationDate:
                userDataTrainingData.certificationDate === ""
                  ? ""
                  : dateConverter(userDataTrainingData.certificationDate),
              score:
                userDataTrainingData.score === ""
                  ? testPercentageState.toString()
                  : userDataTrainingData.score,
            })}
          </Typography>

          <CreatePDFCore
            firstName={userDataTrainingData.firstname}
            lastName={userDataTrainingData.lastname}
            score={
              userDataTrainingData.score === ""
                ? testPercentageState.toString()
                : userDataTrainingData.score
            }
            dateAwarded={
              userDataTrainingData.certificationDate === ""
                ? ""
                : dateConverter(userDataTrainingData.certificationDate)
            }
            /*Robert Wienecke, 2022.02.02
             * for dateExpire we use now certificationDate + 1 year, see https://schutzdigital.atlassian.net/browse/SD0-210
             */
            dateExpire={dateConverter(
              userDataTrainingData.certificationExpiryDate
            )}
            certificateId={userDataTrainingData.certificationID}
            courseName={product.title}
            certificateText={product.certificateText}
            navigate={true}
          />
          <Typography variant="body1" className={classes.greenText}>
            {text.emailCertificate}
          </Typography>
        </div>
      )}
    </BasicCard>
  );
};
