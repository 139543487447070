import React from "react";
import { PageNameWrapper } from "../../components/Cards/CenteredPage/PageNameWrapper";
import { CustomEmailHandler } from "../../components/CustomEmailHandler/CustomEmailHandler";
import { customEmailHandler } from "../commonViewConstants";

export const CustomEmailHandlerView = (): JSX.Element => {
  return (
    <PageNameWrapper title={customEmailHandler}>
      <CustomEmailHandler />
    </PageNameWrapper>
  );
};
