import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/performance";
import "firebase/analytics";
import "firebase/functions";
import "firebase/app-check";
import "firebase/storage";
import { config } from "./config";

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app();
}

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const auth = firebase.auth();
const db = firebase.firestore();
const perf = firebase.performance();
const analytics = firebase.analytics();
const functions = firebase.functions();
const storage = firebase.storage();

if (location.hostname === "localhost") {
  // eslint-disable-next-line no-console
  console.log("Running Locally");
  //db.useEmulator("localhost", 8080);
  firebase.functions().useEmulator("localhost", 5001);
}

export {
  firebase,
  storage,
  functions,
  auth,
  db,
  googleAuthProvider,
  perf,
  analytics,
};
