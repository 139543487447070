import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Markup } from "interweave";
import { useSetRecoilState } from "recoil";
import { consentBannerToggle } from "../../recoil/appState";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    actionButtonContent: {
      marginBottom: theme.spacing(1),
    },
    text: {
      fontFamily: `${theme.typography.fontFamily} !important`,
    },
    button: {
      textTransform: "none",
      margin: theme.spacing(0.5),
      float: "right",
    },
  })
);

interface Props {
  title: string;
  bodyText: string;
  open: boolean;
}

export const DialogBanner = ({ title, bodyText, open }: Props): JSX.Element => {
  const handleClose = () => {
    setTOSToggle(false);
  };
  //const text = TermsOfServiceDialogLanguageData();
  const classes = useStyles();
  const setTOSToggle = useSetRecoilState(consentBannerToggle);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {title}
        <IconButton
          color="primary"
          aria-label="close"
          component="span"
          className={classes.button}
          onClick={() => {
            setTOSToggle(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography className={classes.text} variant="body1">
            <Markup content={bodyText} />
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
