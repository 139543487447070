import React, { Fragment } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { v4 as uuid } from "uuid";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Link,
  Typography,
} from "@material-ui/core";
import { ExamQuestions } from "../../../firebase/types";
import {
  checkedValuesState,
  questionIndexState,
} from "../../../recoil/appState";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 850,
    maxWidth: 1000,
    maxHeight: 550,
    alignItems: "center",
  },
  control: {
    margin: "20px",
  },
  row: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    padding: theme.spacing(1),
    justifyContent: "space-between",

    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  left: {
    display: "flex",
    align: "left",
    alignItems: "left",
  },
  label: {
    margin: "10px",
    color: "rgba(0, 0, 0, 0.54) !important",

    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  focused: { color: theme.palette.primary.main },
  button: {
    color: theme.palette.secondary.main,
    textAlign: "left",
    justifyContent: "flex-start",
    textTransform: "none",

    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.2),
    },

    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      minWidth: 0,
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 250,
      maxWidth: 650,
      marginLeft: 5,
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: 250,
      maxWidth: 650,
      marginLeft: 5,
    },
  },
}));

export interface QuestionCardProps {
  questionData: ExamQuestions[];
}

export const QuestionCardSelector = ({
  questionData,
}: QuestionCardProps): JSX.Element | null => {
  const classes = useStyles();

  const [checkedValues, setCheckedValues] = useRecoilState(checkedValuesState);
  const questionIndex = useRecoilValue(questionIndexState);

  const handleSelect = (checkedAnswers: any) => {
    const newAnswers = checkedValues?.includes(checkedAnswers)
      ? checkedValues?.filter((answer: any) => answer !== checkedAnswers)
      : [...(checkedValues ?? []), checkedAnswers];
    setCheckedValues(newAnswers);
    return newAnswers;
  };

  if (
    typeof questionData === "undefined" ||
    !questionData ||
    typeof questionData[questionIndex] === "undefined"
  ) {
    return null;
  }

  return (
    <Fragment>
      <FormControl className={classes.control} component="fieldset">
        <FormLabel className={classes.label} component="label">
          {questionIndex + 1}. {questionData[questionIndex].question}
        </FormLabel>
        <FormGroup>
          {questionData[questionIndex].answers.map(
            (answer: string, index: number) => (
              <Typography
                className={classes.left}
                variant={"h6"}
                align={"left"}
                key={uuid()}
              >
                <FormControlLabel
                  value={index}
                  label={index + 1}
                  labelPlacement="start"
                  control={
                    <Checkbox
                      checked={checkedValues.includes(index)}
                      onChange={() => handleSelect(index)}
                      name={answer}
                    />
                  }
                />
                <Link
                  component="button"
                  variant="subtitle1"
                  underline="none"
                  className={classes.button}
                  onClick={() => handleSelect(index)}
                >
                  {answer}
                </Link>
              </Typography>
            )
          )}
        </FormGroup>
      </FormControl>
    </Fragment>
  );
};
