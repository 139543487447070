import { firebase } from "../firebase/firebase";
import { ExamQuestions } from "../firebase/types";
import { fetchProductInformation, fetchTrainingLicenses } from "./fetchData";

export const isEmptyObject = <T>(object: T): boolean => {
  for (const i in object) return false;
  return true;
};

export const sortArray = (arrayToSort: number[]): number[] => {
  const sortedArray = arrayToSort.sort(function (a, b) {
    return a - b;
  });
  return sortedArray;
};

interface compareArraysProps {
  array1: number[];
  array2: number[];
}

export const compareArrays = ({
  array1,
  array2,
}: compareArraysProps): boolean => {
  const array1ForSort = [...array1];
  const array2ForSort = [...array2];
  const array1Sorted = sortArray(array1ForSort);
  const array2Sorted = sortArray(array2ForSort);
  return JSON.stringify(array1Sorted) === JSON.stringify(array2Sorted);
};

interface calculatePercentageProps {
  value: number;
  totalValue: number;
}

export const calculatePercentage = ({
  value,
  totalValue,
}: calculatePercentageProps): number => {
  return Math.round((value / totalValue) * 100);
};

export const getDate = (): string => {
  return new Date().toLocaleString();
};

export const convertDurationToMinutes = (duration: string): string => {
  return new Date(+duration * 1000).toISOString().substring(14, 19);
};

// SD0-258 - once everything has been migrated, remove `| undefined ` check
export const dateConverter = (
  date: firebase.firestore.Timestamp | string | undefined
): string => {
  if (date && typeof date !== "string") {
    return date.toDate().toLocaleDateString("fr-CH", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  } else {
    return "";
  }
};

export const getDatePlusMonths = (numberOfMonths: number): string[] => {
  const dateNow = new Date();
  const dateMonthsAdded = new Date(
    dateNow.setMonth(dateNow.getMonth() + numberOfMonths)
  ).toLocaleString();
  return dateMonthsAdded.split(",");
};

export const getParameterByName = (
  name: string,
  url = window.location.href
): string | null => {
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const getRandomSubSetOfArray = (
  questionArray: ExamQuestions[],
  numberOfQuestions: number
): ExamQuestions[] => {
  const shuffled = questionArray.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, numberOfQuestions);
};

export const generateArrayOfRandomNumber = (
  min: number,
  max: number,
  length: number
): number[] => {
  return Array.from(Array(length)).map(
    () => Math.floor(Math.random() * (max - min + 1)) + min
  );
};

export const generateArrayOfRandomNumberNoRepeat = (
  numberArraylength: number,
  max: number
): unknown[] => {
  const randomNumbers: number[] = [];

  do {
    const randomNumber = Math.floor(Math.random() * max) + 1;

    if (!randomNumbers.includes(randomNumber)) {
      randomNumbers.push(randomNumber);
    }
  } while (randomNumbers.length < numberArraylength);

  return randomNumbers;
};

export const generateArraySubsetBasedOffOfIndexArray = (
  arrayToSubSet: any[],
  arrayIndex: number[]
): any[] => {
  const arrayFromIndex = arrayIndex.map((i: number) => arrayToSubSet[i]);
  return arrayFromIndex;
};

// SD0-258 - once everything has been migrated, only one validityEnd check is needed.
export const isExpiredValidityEnd = async (
  productId: string
): Promise<boolean> => {
  const productCollection = await fetchProductInformation(productId);

  const { validityEnd } = productCollection;

  return validityEnd ? new Date() > validityEnd.toDate() : true;
};

export const isExpiredTrainingLicenseValidityEnd = async (
  validityEnd: firebase.firestore.Timestamp
): Promise<boolean> => {
  return validityEnd ? new Date() > validityEnd.toDate() : true;
};

// SD0-258 - once everything has been migrated, use `licensedTraining` data
export const hasExpiredLicense = async (
  licenseCode: string,
  productId: string
): Promise<boolean> => {
  if (licenseCode) {
    const trainingLicensesData = await fetchTrainingLicenses(licenseCode);

    return trainingLicensesData.validityEnd
      ? await isExpiredTrainingLicenseValidityEnd(
          trainingLicensesData.validityEnd
        )
      : await isExpiredValidityEnd(trainingLicensesData.productId);
  }

  return await isExpiredValidityEnd(productId);
};

export const getRemainingDays = (validityEnd: Date): number => {
  const oneDay = 24 * 1000 * 3600;
  const currentDate = new Date();

  return Math.round(
    Math.abs((currentDate.valueOf() - validityEnd.valueOf()) / oneDay)
  );
};
