import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { BasicCard } from "../Cards/BasicCard";
import { RegistrationTextInputs } from "./Inputs/RegistrationTextInputs";
import { RegistrationButtonInputs } from "./Inputs/RegistrationButtonInputs";
import { RegistrationCheckboxInputs } from "./Inputs/RegistrationCheckboxInputs";
import { ShopLink } from "../ShopLink/ShopLink";
import Box from "@material-ui/core/Box";
import { ErrorText } from "../ErrorText/ErrorTextCore";
import { useRecoilValue } from "recoil";
import {
  registrationDynamicError,
  registrationErrorMessage,
  registrationErrorToggle,
} from "../../recoil/appState";
import { RegistrationLanguageData } from "../../constants/Language/Language";

const useStyles = makeStyles((theme) => ({
  card: {
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1),
    paddingLeft: 0,
    [theme.breakpoints.down("sm")]: {
      width: "300",
    },
    [theme.breakpoints.up("md")]: {
      width: "900px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "1100px",
    },
  },
  textField: {
    margin: "10px 0",
    "& .MuiFormLabel-root.Mui-Focused": { color: "black" },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
  },
  cardLeft: {
    color: theme.palette.text.secondary,
  },
  cardRight: {
    color: theme.palette.text.secondary,
  },
  box: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const RegistrationCore = (): JSX.Element => {
  const classes = useStyles();
  const errorToggle = useRecoilValue(registrationErrorToggle);
  const errorMessage = useRecoilValue(registrationErrorMessage);
  const dynamicErrorMessage = useRecoilValue(registrationDynamicError);
  const text = RegistrationLanguageData();

  return (
    <BasicCard title={text.registrationTitle} subText={text.subtitle}>
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        className={classes.card}
      >
        <Grid item xs={12} sm={12} lg={6} className={classes.cardLeft}>
          <form noValidate autoComplete="off" className={classes.form}>
            <RegistrationTextInputs />
          </form>
        </Grid>

        <Grid item xs={12} sm={12} lg={6} className={classes.cardRight}>
          <ShopLink />
        </Grid>
        <Box className={classes.box}>
          <RegistrationCheckboxInputs />
          <RegistrationButtonInputs />
        </Box>
        <Grid item xs={12}>
          <ErrorText
            display={errorToggle}
            errorMessage={errorMessage}
            dynamicError={dynamicErrorMessage}
          />
        </Grid>
      </Grid>
    </BasicCard>
  );
};
