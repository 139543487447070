export const publicPath = "/";

export interface RouteDefinition {
  key: string;
  route: string;
  entrypoint?: string;
  label: string;
  path?: (pathParams: any) => string;
}

const routeDefinitions: RouteDefinition[] = [
  // login related
  {
    key: "LOGINREGISTRATION",
    route: "loginregistration",
    label: "Login Registration",
  },
  {
    key: "REGISTRATION",
    route: "registration",
    label: "Registration",
  },
  {
    key: "REGISTRATIONPENDING",
    route: "registrationpending",
    label: "RegistrationPending",
  },
  { key: "LOGIN", route: "login", label: "Login" },

  {
    key: "MYACCOUNT",
    route: "myaccount",
    label: "My Account",
  },
  {
    key: "FORGOTPASSWORD",
    route: "forgotpassword",
    label: "Forgot Password",
  },
  { key: "LICENSE", route: "license", label: "License" },
  {
    key: "CERTIFICATEIDVALIDATION",
    route: "certificateidvalidation",
    label: "CertificateIDValidation",
  },
  {
    key: "TOPICSELECTION",
    route: "topicselection",
    label: "Topic Selection",
  },
  {
    key: "VIDEOTUTORIAL",
    route: "videotutorial",
    label: "Video Tutorial",
  },
  {
    key: "TESTPREFACE",
    route: "testpreface",
    label: "Test Preface",
  },

  {
    key: "CERTIFICATEINPUT",
    route: "certificateinput",
    label: "Certificate Input",
  },
  {
    key: "CERTIFICATEDISPLAY",
    route: "certificatedisplay",
    label: "Certificate Display",
  },
  {
    key: "EMAILVERIFICATION",
    route: "emailverification",
    label: "Email Verification",
  },
  {
    key: "PASSWORDRESET",
    route: "passwordreset",
    label: "Password Reset",
  },
  {
    key: "CUSTOMEMAILHANDLER",
    route: "customemailhandler",
    label: "Custom Email Handler",
  },
  { key: "NOT_FOUND", route: "not-found", label: "404" },
];

const routeDef = routeDefinitions.map<[string, string]>((rt: any) => [
  rt.route,
  rt.label,
]);
const labelMap: Map<string, string> = new Map(routeDef);

export const getLabel = (route: string): string =>
  labelMap.get(route) || "Unknown Route";

export interface IRouteCodes {
  [key: string]: {
    route: string;
    label: string;
    path: (pathParams: any) => string;
  };
}

export const routeCodes: IRouteCodes = {};
routeDefinitions.forEach(
  (route) =>
    (routeCodes[route.key] = {
      route: `${publicPath}${route.route}`,
      label: route.label,
      path: route.path ? route.path : () => "",
    })
);
