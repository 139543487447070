import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { QuestionCardLanguageData } from "../../../constants/Language/Language";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    backgroundColor: `${theme.palette.primary.main}!important`,
  },
}));

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
}

export const QuestionCardDialog = ({ open, setOpen }: Props): JSX.Element => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };
  const text = QuestionCardLanguageData();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>{text.dialogTextLogIn}</DialogContentText>

        <DialogActions>
          <Button
            style={{ textTransform: "none" }}
            color="primary"
            className={classes.button}
            variant="contained"
            onClick={handleClose}
          >
            {text.dialogTextLogInButton}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
